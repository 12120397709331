import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ExportStateService } from 'src/app/services/export-state.service';

@Component({
  selector: 'app-action-btn',
  templateUrl: './action-btn.component.html',
  styleUrls: ['./action-btn.component.scss']
})
export class ActionBtnComponent  {
  params: any;
  blue = true;
  disabled = false;
  orange = false;
  green = true;
  orangePpf = false;
  disabledPpf = false;
  userInfo: any;
  disableCalc = false;
  isUsedBackup = false;
  authArrClass : any[] = [];
  tcdisabled = false;
  authdisabled = false
  ppfdisabled = false
  deletedisabled =  false;
  calcdisabled = false
  temp: any;
  ppf:any;
  isUsed = false;
  hideBUCL=false
  backupUserDetails: any;
  appStatus: any;
  colorChange = false;
  isOrange = false;
  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    public readonly dialog: MatDialog,
    private readonly state: ExportStateService,) { }


  agInit(params: any): void {
    this.state.userInfoState.subscribe({
      next: (res: any) => {
        if (res) {
          this.userInfo = res;
          this.backupUserDetails = res?.role?.backupDetails;
        }
      },
      error: (_err: any) => {
      },
    });
    this.params = params;
    this.hideBUCL=params.hideBUCL
    if (params.data.broadcastflag !== '1') {
      this.disableCalc = true;
    }

    //backup
    this.temp = this.nullInt(this.params.data["temp"]); 
    this.appStatus = this.nullInt(this.params.data["appstatus"]); 
    if(this.temp !== 0) {
      this.isUsed = true;
      if(this.appStatus === 0){ // 0: not approved; 1: approved; 2: rejected
        this.colorChange = true; //data not yet approved; color is orange
      }
    }
    if(!this.isUsed) {
      this.isUsedBackup = true;
    }

    //TC
    this.tcdisabled = false;
    if(!this.params.data.userInfo.role.p_trackingcorrect || 
      this.params.data.step10status  === "4"  || this.params.data.step10status  === "2" 
       || this.params.data.step10status  === "3"  || this.params.data.follower  !== null && this.params.data.plantcode !=="21TMC") {
      this.tcdisabled = true;
    }

    //Auth
    this.authdisabled = false;
    if(!this.params.data.userInfo.role.p_qtyauth || this.params.data.step09status === "4" || 
    this.params.data.step09status === "5" ) {
      this.authdisabled = false;
    } else {
      this.authdisabled = true;
    }

    // Update PPF Button
    this.ppf=this.nullInt(this.params.data["isupdateppf"]);
    if(this.ppf === 1 && this.params.data?.step09status === "4"){
      this.isOrange = true;
    }
    this.ppfdisabled = false;
    if(this.userInfo.role.p_trackingsave  && this.params.data.specialistcode  === this.userInfo.info.p_specialistcode) {
      this.ppfdisabled = false;
    } else{
      this.ppfdisabled = true;
    }

    for (const value of this.backupUserDetails) {
      if (this.params.data.specialistcode === value?.specialistcode) {
        this.ppfdisabled = false;
      }
    }

    // col - Delete Button
    this.deletedisabled = false;
    if(!this.params.data.userInfo.role.p_trackingdelete) {
      this.deletedisabled = true;
    }

    //// col - Broadcast Calc Button
    this.calcdisabled = false;
    if (this.nullInt(this.params.data["BroadCastFlag"]) !== 1) {
      this.calcdisabled = true;
  }
  }

  navigate(action: any) {
    // backup
    if (action === 'BU') {
      const backupParams = {
        "plantCode": this.params.data.plantcode,
        "partNumber": this.params.data.partnumber,
        "supplierCode": this.params.data.suppliercode,
        "supplier": this.params.data.supplier,
        "pkgStartDate": this.params.data.pkgstartdate,
        "modelCode": this.params.data.modelcode,
        "familyCode": this.params.data.familycode,
        "userPlantCode": this.params.data.plantcode,
        "userUnit": this.userInfo?.info.unit,
        "plantName": this.params.data.plantname,
        "supplierName": this.params.data.suppliername
      }
      sessionStorage.setItem('backUp', JSON.stringify(backupParams))
      this.router.navigate(['/tracking-list/backup-temp-container']);
    }
    if (action === 'H') {
      sessionStorage.setItem('trackingParams', JSON.stringify(this.params.data))
      this.router.navigate(['/tracking-list/tracking-history']);
    }
    if (action === 'D') {
      this.params.clicked(this.params.column.colId, this.params.data);
    }
    if (action === 'TC') {
      sessionStorage.setItem('trackingParams', JSON.stringify(this.params.data))
      this.router.navigate(['/tracking-list/tracking-correction']);
    }

    if (action === 'AU') { 
      const obj = {
        authNumber: this.params.data?.authnumber,
        specialistCode: this.params.data?.specialistcode,
        plantCode: this.params.data?.plantcode,
        flag: '',
        pkgstartdate: this.params.data?.pkgstartdate,
        partNumber: this.params.data?.partnumber,
        modelcode: this.params.data?.modelcode,
        familycode: this.params.data?.familycode,
        suppliercode: this.params.data?.suppliercode,
        screen: 'tracking-AU',
        mode: 1
      }
      sessionStorage.setItem('createRCAF', JSON.stringify(obj))
      this.router.navigateByUrl('/tracking-list/create-rcaf');
    }
    if (action === 'PPF') {
      const PPFParams = {
        plantCode: this.params.data.plantcode,
        partNumber: this.params.data.partnumber,
        supplierCode: this.params.data.suppliercode,
        pkgStartDate: this.params.data.pkgstartdate,
        modelCode: this.params.data.modelcode,
        PPFCode: this.params.data.ppfcode
      }
      sessionStorage.setItem('updatePPF', JSON.stringify(PPFParams))
      const HzParams = {
        hazmat: this.params.data.hazmat,
        hzapprovestatus: this.params.data.hzapprovestatus,
        isupdateppf: this.params.data.isupdateppf,
        step09status: this.params.data.step09status
      }
      sessionStorage.setItem('hzParams', JSON.stringify(HzParams))
      this.router.navigate(['/tracking-list/update-ppf-reason', this.params.data.ppfcode,
        this.params.data.ppfver,(this.params.data.authnumber?'1':'0')]);
    }
    if (action === 'CL') {
      sessionStorage.setItem('trackingParams', JSON.stringify(this.params.data));
      this.router.navigate(['/tracking-list/broadcast-calc']);
    }
  }


  private nullInt(obj: any) {
    if (obj === null) {
      return 0;
    }
   else {
      return parseInt(obj)
    }
  }
}
