import { Component, OnInit, ViewChild, ElementRef, SecurityContext } from '@angular/core';
import { DatePipe, Location, formatDate } from '@angular/common';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbComponent } from 'src/app/core/breadcrumb/breadcrumb.component';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ExportStateService } from 'src/app/services/export-state.service';
import { PpfServiceInternal } from 'src/app/internal-vpacs/services-internal/ppf.service';
import * as FileSaver from 'file-saver-es';
import { MatDialog } from '@angular/material/dialog';
import { SelectPartComponent } from 'src/app/internal-vpacs/internal-vpacs-dialogs/ppf/select-part/select-part.component';
import { convertAndCheck, toNumber, calculateValue, validate, getCI, convertCubicMillimeterToCubicMeter, sumProduct } from './servicepartsCalculations';
import { DialogDivisionComponent } from 'src/app/internal-vpacs/internal-vpacs-dialogs/master-maintenance/division-master/dialog-division/dialog-division.component';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { getPdf } from './service-parts-pdf';
import { ConstantsService } from 'src/app/services/constant.service';
(<any>pdfMake).vfs = pdfFonts?.pdfMake?.vfs;

@Component({
  selector: 'app-new-ppf',
  templateUrl: './service-parts.component.html',
  styleUrls: ['./service-parts.component.scss'],
  providers: [BreadcrumbComponent]
})
export class ServicePartsComponent implements OnInit {
  @ViewChild('PPF') pdfContent: ElementRef | any;

  constPartNumber: any;
  isAccessible = false;
  public domLayout: any = ''
  supplierCode: any;
  defineData: any;
  siteItems: any;
  sortValue: any;
  filteredData: any[] = [];
  params: any;
  searchValue: any;
  originalData: any;
  basePartImage: any;
  packagingImage: any;
  intermediateImage: any;
  unitIntermediateImage: any;
  vendorDate:any;
  inter = false;
  masterRowValidations = false;
  palletRowValidations = false;
  unitPackageData  = false;
  url: any;
  routeParams: any;
  mode:any;
  keyWeight:any;
  keyInterPack:any;
  keyMasterpack:any;
  keyPallet:any;
  keyPackageFill:any;
  keyCaseWeight:any;
  totalUnitPackagingCost : any;
  totalComplianceTM:any;
  totalPKGEngineer:any;
  totalMasterpackEst:any;
  totalMaterial:any;
  totalLabour:any;
  finalView:any;
  unitlaborcost:any;
  unitlabortime:any;
  unitmaterialcost:any;
  unitpkgcost:any;
  unitfloormat1:any;
  unitfloormat2:any;
  unitfloormat3:any;
  interlaborcost: any;
  interlabortime: any;
  intermaterialcost: any;
  interpkgcost: any;
  masterlaborcost: any;
  masterlabortime: any;
  mastermaterialcost: any;
  masterpkgcost: any;
  palletlaborcost:any;
  palletlabortime:any;
  palletmaterialcost: any;
  palletpkgcost: any;
  response: any;
  payloadArray:any=[];
  removedArray:any[] = [];
  removedHazArray=[] as any;
  arrayFlag = false;
  body:any;
  masterhide = true;
  pallethide = true;
  hazmatPKG = false;
  tenDigitValue:any;
  indexValue:any;
  dateInd=false;
  deleteArray :boolean[] =[];
  screenMode:any;
  date:any;
  unitPackageMat = false;
  partLengthValidator :boolean[] =[];
  keyUnitPkgInd = false;
  keyPalletInd = false;
  form0 : any;
  trackingUrl = 'tracking-list';
  readonly SERVICE_PART='SERVICE PART'
  readonly CAMPAIGN_PART = 'CAMPAIGN PART'
  readonly NEW_PART_NUMBER_ECI = 'New Part Number(ECI)'
  readonly PACKAGING_KAIZEN = 'Packaging Kaizen'
  readonly ACCY_PPO = 'ACCY PPO'
  readonly ACCY_DIO = 'ACCY DIO'
  readonly PPO_DIO = 'ACCY PPO & DIO'
  readonly  UPDATED_COST = 'Updated Cost'
  readonly  PPR_COUNTERMEASURE = 'PPR Countermeasure'
  readonly  PART_RESOURCING = 'Part Re-Sourcing'
  readonly PACKAGING_TYPE = 'Packaging Type'
  readonly MATERIAL_DESCRIPTION = 'Material Description'
  readonly MATERIAL_SPECIFICATION = 'MATERIAL SPECIFICATION'
  readonly MATERIAL_UNIT_COST = 'Material Unit Cost ($)'
  readonly LABOR_TIME = 'Labor Time(sec)'
  readonly TOTAL_COST = 'TOTAL COST'
  readonly WHITLAM_LABEL = 'Whitlam Label'
  readonly BUBBLE_BAG = 'Bubble Bag'
  readonly BOTTLE_BAG = 'Bottle Bag'
  readonly SMALL_PARCEL_SHIPPING_BOX = 'Small Parcel Shipping Box'
  readonly BOX_LENGTH  = 'Box(Length ≤48")'
  readonly LARGE_BOX = 'Large Box(Length >48")'
  readonly UN_CERTIFIED_BOX = 'UN Certified Box(Length ≤48")'
  readonly U_RETURNABLE = 'U Returnable'
  readonly MU_RETURNABLE = 'MU Returnable'
  readonly HU_RETURNABLE = 'HU Returnable'
  readonly _2U_RETURNABLE = '2U Returnable'
  readonly  MINI_C3_RETURNABLE =  'Mini C3 Returnable'
  readonly C3_RETURNABLE = 'C3 Returnable'
  readonly C4_RETURNABLE = 'C4 Returnable'
  readonly WUL_RETURNABLE = 'WUL Returnable' 
  readonly BCR_RETURNABLE = 'BCR Returnable'
  readonly U_MSTERPACK = 'U Masterpack'
  readonly MU_MSTERPACK = 'MU Masterpack'
  readonly HU_MSTERPACK = 'HU Masterpack'
  readonly TWOU_MASTERPACK = '2U Masterpack'
  readonly MINIC3_MASTERPACK = 'Mini C3 Masterpack'
  readonly C3_MASTERPACK = 'C3 Masterpack'
  readonly C4_MASTERPACK = 'C4 Masterpack'
  readonly WUL_MASTERPACK = 'WUL Masterpack'  
  readonly BCR_MASTERPACK = 'BCR Masterpack'
  readonly CR90_MODULE = 'CR90 Module'
  readonly smallParcel = 'Small Parcel'
  readonly barePart = 'Bare Part'  
  readonly polyBag = 'Poly Bag'
  readonly otherBag = 'Other Bag'
  readonly tubeBag = 'Tube Bag'
  readonly blockStyle3048 = '30 " x 48" Block Style'
  readonly blockStyle4860 = '48" x 60" Block Style'
  readonly blockStyle4890 = '48" x 90" Block Style'
  readonly blockStyle48120 = '48" x 120" Block Style'
  readonly _blockstylewood = 'Block Style Wood Pallet'
  readonly trackingList = this.trackingUrl
  partType:any = [];
  hazmatdropdown = [
    {hazmatValue:'Y',name:'YES'},
    {hazmatValue:'N',name:'NO'}
  ];
  statusDropdown = [
    {statusValue:'1',name:'FINAL'}];
  homePositinDrop = [
    {statusValue:'A',name:'A'},{statusValue:'B',name:'B'},{statusValue:'C',name:'C'}, 
    {statusValue:'D',name:'D'},{statusValue:'E',name:'E'},{statusValue:'F',name:'F'}, 
    {statusValue:'G',name:'G'},{statusValue:'H',name:'H'}, {statusValue:'J',name:'J'},
    {statusValue:'K',name:'K'},{statusValue:'L',name:'L'},{statusValue:'M',name:'M'},
    {statusValue:'T',name:'T'},{statusValue:'Z',name:'Z'},
  ]
  requestDropdown = [
    {requestValue:this.NEW_PART_NUMBER_ECI,name:this.NEW_PART_NUMBER_ECI},
    {requestValue:this.PACKAGING_KAIZEN,name:this.PACKAGING_KAIZEN},
    {requestValue:this.UPDATED_COST,name:this.UPDATED_COST},
    {requestValue:this.PPR_COUNTERMEASURE,name:this.PPR_COUNTERMEASURE},
    {requestValue:this.PART_RESOURCING,name:this.PART_RESOURCING},
  ]
  filteredSerPPFDetail: any[]=[];
  filteredSerPPFDetail1: any[]=[];
  filteredSerPPFDetail2: any[]=[];
  filteredSerPPFDetail3: any[]=[];
  serPPFPartTable: any[]=[];
  serPpfDes: any[]=[];
  userInfo: any;
  haveAccess: any;
  errorList: string[] = [];
  //common grid
  subHeader = [
    {label: 'Weight(g)',width:70},
    {label: 'L(mm)', width:70},
    {label: 'W(mm)', width:70},
    {label: 'H(mm)',width:70},
  ];
  // grid1
  headers1 = [
    {label: 'TMS PART NUMBER', colspan:1, width:90},
    {label: '11th/12 DIGIT LIST ALL IN SAME CELL', colspan:1, width:90},
    {label: 'TMS PART DESCRIPTION', colspan:1, width:150},       
    {label: 'HOME POSITION(HP)', colspan:1, width:90},
    {label: 'WEIGHT(g)',colspan:1, width:90},
    {label: 'L(mm)', colspan:1, width:90},
    {label: 'W(mm)', colspan:1, width:90},
    {label: 'H(mm)', colspan:1, width:90},
    {label: 'MONTHLY PART VOLUME', colspan:1, width:90},
    {label: 'PART M3', colspan:1, width:90},
    {label: "DELETE", colspan:1, width:90}
  ];
  columns1: number[] = [...Array(11).keys()];
  rows1: number[] =  []; 
  data:any[] = [];
  form1:any;
  // grid2
  headers2 = [
    {label: 'UNIT PACKAGING', rowspan:2,width:210},
    {label: this.PACKAGING_TYPE, rowspan:2, width:110},
    {label: this.MATERIAL_DESCRIPTION, rowspan:2, width:100},
    {label: this.MATERIAL_SPECIFICATION , colspan:4, width:200},
    {label: this.MATERIAL_UNIT_COST ,rowspan:2, width:80},
    {label: this.LABOR_TIME , rowspan:2, width:80},
    {label: 'Qty of PKG Material per Unit Package', rowspan:2, width:80},
    {label: this.TOTAL_COST , rowspan:2, width:80},   
  ];
  form2:any;
  columns2: number[] = [...Array(11).keys()];
  columns2a: number[] = [...Array(6).keys()];
  columns2ab: number[] = [...Array(5).keys()];
  rows2: number[] = [...Array(11).keys()];
  unitTable: any[] = [
    { col0: 'Unit Packaging', col1: '', col2: '', col3: '', col4: '', col5: '', col6: '', col7: '', col8: '', col9: '', col10: '', },
    { col0: 'Interior Packaging "A"', col1: '', col2: '', col3: '', col4: '', col5: '', col6: '', col7: '', col8: '', col9: '', col10: '' },
    { col0: 'Interior Packaging "B"', col1: '', col2: '', col3: '', col4: '', col5: '', col6: '', col7: '', col8: '', col9: '', col10: '' },
    { col0: 'Interior Packaging "C"', col1: '', col2: '', col3: '', col4: '', col5: '', col6: '', col7: '', col8: '', col9: '', col10: '' },
    { col0: 'Interior Packaging "D"', col1: '', col2: '', col3: '', col4: '', col5: '', col6: '', col7: '', col8: '', col9: '', col10: '' },
    { col0: 'Misc.', col1: '', col2: '', col3: '', col4: '', col5: '', col6: '', col7: '', col8: '', col9: '', col10: '' },
    { col0: 'Unit Label',  col1: this.WHITLAM_LABEL , col2: '', col3: '', col4: '', col5: ''},
    { col0: 'Heavy Part Label(Part+PKG>22.7KG)', col1: this.WHITLAM_LABEL , col2: '', col3: '', col4: '', col5: '' },
    { col0: 'Haz-Mat Label(where applicable)',  col1: '', col2: '', col3: '', col4: '' },
    { col0: 'Installation Instruction(where applicable)',  col1: '', col2: '', col3: '', col4: ''  },
    { col0: 'FLOOR MAT SAFETY CHECK Insert',  col1: '', col2: '', col3: '', col4: ''  },
  ];
  unitPackageType = [
    {name: this.barePart, value: this.barePart},
    { name: this.polyBag, value: this.polyBag},
    { name: this.BUBBLE_BAG , value: this.BUBBLE_BAG },
    { name: this.otherBag, value: this.otherBag},
    { name: this.BOTTLE_BAG, value: this.BOTTLE_BAG},
    { name: this.tubeBag, value: this.tubeBag},
    { name: this.SMALL_PARCEL_SHIPPING_BOX , value: this.SMALL_PARCEL_SHIPPING_BOX },
    { name: this.BOX_LENGTH , value: this.BOX_LENGTH },
    { name: this.LARGE_BOX , value: this.LARGE_BOX },
   { name: this.UN_CERTIFIED_BOX , value: this.UN_CERTIFIED_BOX },
  ]
  //grid3
  headers3 = [
    {label: 'INTERMEDIATE PACKS', rowspan:2,width:180},
    {label: this.PACKAGING_TYPE, rowspan:2, width:120}, 
    {label: this.MATERIAL_DESCRIPTION, rowspan:2, width:80},
    {label: this.MATERIAL_SPECIFICATION , colspan:4, width:200},
    {label: this.MATERIAL_UNIT_COST ,rowspan:2, width:80},
    {label: this.LABOR_TIME , rowspan:2, width:80},
    {label: 'Qty of PKG Material per Inter Pack', rowspan:2, width:80},
    {label: this.TOTAL_COST , rowspan:2, width:80},   
  ];
  form3:any;
  columns3: number[] = [...Array(11).keys()];
  columns3a: number[] = [...Array(6).keys()];
  rows3: number[] = [...Array(5).keys()];
  intermediateTable: any[] = [
    { col0: 'Intermediate Packs', col1: '', col2: '', col3: '', col4: '', col5: '', col6: '', col7: '', col8: '', col9: '' , col10: ''},
    { col0: 'Intermediate Material "A"', col1: '', col2: '', col3: '', col4: '', col5: '', col6: '', col7: '', col8: '', col9: '' , col10: ''},
    { col0: 'Intermediate Material "B"' , col1: '', col2: '', col3: '', col4: '', col5: '', col6: '', col7: '', col8: '', col9: '' , col10: ''},
    { col0: 'Misc.', col1: '', col2: '', col3: '', col4: '', col5: '', col6: '', col7: '', col8: '', col9: '' , col10: ''},
    { col0: 'Intermediate Label', col1: this.WHITLAM_LABEL , col2: '', col3: '', col4: '', col5: ''},
  ];
  interPackageType = [   
    { name: this.polyBag, value: this.polyBag},
    { name: this.BUBBLE_BAG , value: this.BUBBLE_BAG },
    { name: 'Wrap', value: 'Wrap'},    
    { name: this.SMALL_PARCEL_SHIPPING_BOX , value: this.SMALL_PARCEL_SHIPPING_BOX},
    { name: this.BOX_LENGTH , value: this.BOX_LENGTH },
    { name: this.LARGE_BOX , value: this.LARGE_BOX },   
  ];
  //grid4
  headers4 = [
    {label: 'MASTERPACKS', rowspan:2,width:180},
    {label: this.PACKAGING_TYPE , rowspan:2, width:120},
    {label: this.MATERIAL_DESCRIPTION , rowspan:2, width:80},
    {label: this.MATERIAL_SPECIFICATION, colspan:4, width:200},
    {label: this.MATERIAL_UNIT_COST ,rowspan:2, width:80},
    {label: this.LABOR_TIME , rowspan:2, width:80},
    {label: 'Qty of PKG Material per Masterpack', rowspan:2, width:80},
    {label: this.TOTAL_COST , rowspan:2, width:80},   
  ];
  form4:any;
  columns4: number[] = [...Array(11).keys()];
  columns4a: number[] = [...Array(6).keys()];
  rows4: number[] = [...Array(7).keys()];
  masterTable: any[] = [
    { col0: 'Returnable Pack', col1: '', col2: '', col3: '', col4: '', col5: '', col6: '', col7: '', col8: '', col9: '' ,col10: '' ,},
    { col0: 'Expendable Pack', col1: '', col2: '', col3: '', col4: '', col5: '', col6: '', col7: '', col8: '', col9: '' ,col10: '' ,  },
    { col0: 'Masterpack Material "A"', col1: '', col2: '', col3: '', col4: '', col5: '', col6: '', col7: '', col8: '', col9: '' ,col10: '' , },
    { col0: 'Masterpack Material "B"' , col1: '', col2: '', col3: '', col4: '', col5: '', col6: '', col7: '', col8: '', col9: '' ,col10: '' , },
    { col0: 'Misc.', col1: '', col2: '', col3: '', col4: '', col5: '', col6: '', col7: '', col8: '', col9: '' ,col10: '' , },
    { col0: 'CASE Label', col1: 'See Packaging Manual for guidelines on CASE label', col2: '', col3: '', col4: '',  col5: '' },
    { col0: 'Heavy CASE Label', col1: 'When full case (Parts/PKG) exceeds 227 KG', col2: '', col3: '', col4: '', col5: ''},
  ];
  masterPackageType1 = [
    {name: '', value: ''},
    {name: this.U_RETURNABLE , value:  this.U_RETURNABLE },
    {name: this.MU_RETURNABLE , value: this.MU_RETURNABLE },
    {name: this.HU_RETURNABLE , value: this.HU_RETURNABLE },
    {name: this._2U_RETURNABLE , value: this._2U_RETURNABLE },
    {name: this.MINI_C3_RETURNABLE , value: this.MINI_C3_RETURNABLE },
    {name: this.C3_RETURNABLE, value: this.C3_RETURNABLE },
    {name: this.C4_RETURNABLE, value: this.C4_RETURNABLE },
    {name: this.WUL_RETURNABLE , value: this.WUL_RETURNABLE },
    {name: this.BCR_RETURNABLE , value: this.BCR_RETURNABLE },  
  ];
  masterPackageType2 = [
    {name: '', value: ''},
    {name: this.U_MSTERPACK, value: this.U_MSTERPACK},
    {name: this.MU_MSTERPACK, value: this.MU_MSTERPACK},
    {name: this.HU_MSTERPACK, value: this.HU_MSTERPACK},
    {name: this.TWOU_MASTERPACK, value: this.TWOU_MASTERPACK},
    {name: this.MINIC3_MASTERPACK, value: this.MINIC3_MASTERPACK,},
    {name: this.C3_MASTERPACK, value: this.C3_MASTERPACK},
    {name: this.C4_MASTERPACK, value: this.C4_MASTERPACK},
    {name: this.WUL_MASTERPACK, value: this.WUL_MASTERPACK},
    {name: this.BCR_MASTERPACK, value: this.BCR_MASTERPACK}, 
    {name: this.BOX_LENGTH, value: this.BOX_LENGTH},
    {name: this.LARGE_BOX, value: this.LARGE_BOX},   
    {name: this.CR90_MODULE, value: this.CR90_MODULE}, 
    {name: this.smallParcel, value: this.smallParcel},   
  ];
  
  //grid5
  headers5 = [
    {label: 'PALLETS', rowspan:2,width:180},
    {label: this.PACKAGING_TYPE , rowspan:2, width:120},
    {label: this.MATERIAL_DESCRIPTION , rowspan:2, width:80},
    {label: this.MATERIAL_SPECIFICATION , colspan:4, width:200},
    {label: this.MATERIAL_UNIT_COST ,rowspan:2, width:80},
    {label: this.LABOR_TIME , rowspan:2, width:80},
    {label: 'Qty of PKG Material per Pallet', rowspan:2, width:100},
    {label: this.TOTAL_COST , rowspan:2, width:80},   
  ];
  form5:any;
  columns5: number[] = [...Array(11).keys()];
  columns5a: number[] = [...Array(6).keys()];
  rows5: number[] = [...Array(8).keys()];
  palletTable: any[] = [
    { col0: 'Pallet', col1: '', col2: '', col3: '', col4: '', col5: '', col6: '', col7: '', col8: '', col9: '' , col10: '',},
    { col0: 'Palletized Material"A"', col1: '', col2: '', col3: '', col4: '', col5: '', col6: '', col7: '', col8: '', col9: '' , col10: '', col12: ''},
    { col0: 'Palletized Material"B"', col1: '', col2: '', col3: '', col4: '', col5: '', col6: '', col7: '', col8: '', col9: '' , col10: ''},
    { col0: 'Palletized Material"C"' , col1: '', col2: '', col3: '', col4: '', col5: '', col6: '', col7: '', col8: '', col9: '' , col10: ''},
    { col0: 'Palletized Material"D"' , col1: '', col2: '', col3: '', col4: '', col5: '', col6: '', col7: '', col8: '', col9: '' , col10: ''},
    { col0: 'Misc.', col1: '', col2: '', col3: '', col4: '', col5: '', col6: '', col7: '', col8: '', col9: '' , col10: ''},
    { col0: 'CASE Label', col1: 'See Packaging Manual for guidelines on CASE label', col2: '', col3: '', col4: '', col5: ''},
    { col0: 'Heavy CASE Label', col1: 'When full case (Parts/PKG) exceeds 227 KG', col2: '', col3: '', col4: '', col5: ''},
  ];
  palletPackageType = [
    {name:'', value:''},
    {name: '30 " x 48" Block Style Wood Pallet w/ Flush Bottom', value: this.blockStyle3048},
    {name: '48" x 60" Block Style Wood Pallet w/ Flush Bottom', value: this.blockStyle4860},
    {name: '48" x 90" Block Style Wood Pallet w/ Flush Bottom', value: this.blockStyle4890},
    {name: '48" x 120" Block Style Wood w/ Flush Bottom', value: this.blockStyle48120},
    {name: ' Block Style Wood Pallet Other list here ---->', value: this._blockstylewood}, 
  ]
  //dynamic images
  formatError: any[] = [];
  imageNames: any[] = [];
  public image: (SafeResourceUrl| null)[] = [];
  zoomImage:any=[];  
  asyncLoading = true;
  laborTime: any;
  qty: any;
  partNum: any;
  keyLabRateInd= false;
  keyMasterInd=false;

  constructor(public sanitizer: DomSanitizer,
    public formbuilder: FormBuilder,private readonly fb: FormBuilder, public router: ActivatedRoute, public breadcrumb: BreadcrumbComponent,
    public _location: Location,public internalPpfService: PpfServiceInternal,private readonly route: Router,
    private readonly dialog: MatDialog,
    private readonly spinner: NgxUiLoaderService,public state: ExportStateService,
    private readonly datePipe: DatePipe,
    private readonly constants: ConstantsService) {
      this.form0 = this.fb.group({
        status:[''],
        requestType:['',Validators.required],
        partType:['',Validators.required],
        venDate: [{ value: '', disabled: true }],
        venName: [{ value: '', disabled: true }],
        venFOB: [{ value: '', disabled: true }],
        venCode: [{ value: '', disabled: true }],
        venProject: [{ value: '', disabled: true }],
        venSubName: [{ value: '', disabled: true }],
        venSubEmail: [{ value: '', disabled: true }],
        venSubPhone: [{ value: '', disabled: true }],
        keyLaborRate: ['', Validators.required],
        keyWeight: [{ value: '', disabled: true }],
        keyUnitPackage: ['',[Validators.max(20)]],
        keyInterPack: [{ value: '', disabled: true }],
        keyMasterpack: ['',Validators.max(200)],
        keyPallet: ['',Validators.max(10000)],
        keyPackageFill: [{ value: '', disabled: true }],
        keyCaseWeight: [{ value: '', disabled: true }],
        totalDangerousGoods: [ '', Validators.required ],
        totalComplianceTM: [{ value: '', disabled: true }],
        totalPKGEngineer: [{ value: '', disabled: true }],
        totalMasterpackEst: [{ value: '', disabled: true }],
        totalMaterial: [{ value: '', disabled: true }],
        totalLabour: [{ value: '', disabled: true }],
        totalUnitpkgCost:[''],
        unitfloormat1:[''],
        unitfloormat2:[''],
        unitfloormat3:[''],   
        masterheatpallet:[''],
        masterflushpallet:[''],
        palletheatpallet:[''],
        palletflushpallet:['']
      });
    //grid1
    this.form1 = this.fb.group({});
    //grid2
    this.form2 = this.fb.group({});
    for (let i = 0; i < this.rows2.length; i++) {
      for (let j = 0; j < this.columns2.length; j++) {
          this.form2.addControl(`row${i}col${j}`, this.fb.control(''));      
      }
    }
    //grid3
    this.form3 = this.fb.group({});
    for (let i = 0; i < this.rows3.length; i++) {
      for (let j = 0; j < this.columns3.length; j++) {
          this.form3.addControl(`row${i}col${j}`, this.fb.control(''));       
      }
    }
    //grid4
    this.form4 = this.fb.group({});
    for (let i = 0; i < this.rows4.length; i++) {
      for (let j = 0; j < this.columns4.length; j++) {
          this.form4.addControl(`row${i}col${j}`, this.fb.control(''));       
      }
    }
     //grid5
     this.form5 = this.fb.group({});
     for (let i = 0; i < this.rows5.length; i++) {
       for (let j = 0; j < this.columns5.length; j++) {
           this.form5.addControl(`row${i}col${j}`, this.fb.control(''));       
       }
     }
  }

  ngOnInit(): void {
    //router params    
    this.url = this.breadcrumb.breadcrumbs.length > 0 && this.breadcrumb.breadcrumbs.at(-2)?.url;   
    if(this.breadcrumb.breadcrumbs.length > 0){
      const pValue = this.breadcrumb.breadcrumbs.find((item:any) => item.label === 'Service Parts PDF' || item.label === 'Accessory Parts PDF')?.label || '';	
      this.partType = this.constants.partTypeDropdown(pValue);
    }
    this.router.params.subscribe((data: any) => {
      this.routeParams = data;
      this.mode = Number(this.routeParams.mode);
      if(this.routeParams.external === '1'){
        this.partNum  = this.routeParams.partNumber
      }else{
        if(this.routeParams.partnumber !== '' && this.routeParams.partnumber !==null && this.routeParams.partnumber !== undefined){
          this.partNum = this.routeParams.partnumber;        
        }
        else{        
          this.partNum = sessionStorage.getItem("partnumber")??'';
        }
      }
    })  
      
    if (this.routeParams.external === "1") {
      this.haveAccess = true;
      this.externalSPPFOnload();
    }else{    
      this.asyncLoading = true;  
      this.state.userInfoState.subscribe({
        next: (res: any) => {
          if (res) {
            this.userInfo = res;            
            if (!res.role?.p_trackingview && !res.role?.p_costview && !res.role?.p_qtyview) {             
              this.haveAccess = false;
              this.errorList = ["You're not authorized to access this page"];
              this.asyncLoading = false;                          
              this.spinner.stop();
            } else {
              this.haveAccess = true;
              this.servicePdfData();
            }
          }
        },
        error: (_err: any) => {
          this.haveAccess = false;
          this.errorList.push(_err.message)
          this.spinner.stop();
          this.spinner.stop();
          this.asyncLoading = false;
        }
      });
    }  
    
  }

  externalSPPFOnload(){
    this.spinner.start();
    this.internalPpfService.externalSPPFOnload(this.routeParams).subscribe({
      next : (res:any) =>{
        this.performDataOperations(res);
      },
      error : (_err:any) =>{
        this.spinner.stop();
      },
      complete : () =>{
        this.spinner.stop();
      }
    })
  }
  dynamicPart(data:any){
    for (let i = 0; i < data.length + 1; i++) {
      for (let j = 0; j < this.columns1.length; j++) {
        this.form1.addControl(`row${i}col${j}`, this.fb.control(''));
      }      
    }
    this.tmsValue();
  }
  dynamicpart2(row:any){
    for (let j = 0; j < this.columns1.length; j++) {
      this.form1.addControl(`row${row}col${j}`, this.fb.control(''));
    }
    this.tmsValue2(row);
  }
  partloop(data:any,flag:number){
    if (flag === 1) {
      this.rows1 = [...Array(data.length + 1).keys()];
      this.dynamicPart(data)
      for (let i = 0; i < data.length + 1; i++) {
        this.data.push({ col0: '', col1: '', col2: '', col3: '', col4: '', col6: '', col7: '', col8: '', col5: '', col9: '', col10: '' });
      }
    }
    if(this.partNum.length > 10){
      this.tenDigitValue = this.partNum.substring(0, 10);
    }
    else {
      this.tenDigitValue = this.partNum;
    }  
    this.indexValue = data.findIndex((item:any) => item.partnumber === this.tenDigitValue);
    if(this.mode === 1){
      this.setGridOne(data);
    }
    else{
      this.setNonEditableGrid1(data);
    }
  }
  addPart(event:any){
    event.stopPropagation();    
    this.rows1 = [...Array(this.rows1.length + 1).keys()];    
    this.data.push({col0:'', col1: '', col2: '', col3: '', col4: '', col6: '', col7: '', col8: '', col5: '', col9: '', col10:''});    
    this.dynamicpart2(this.rows1.length - 1);
    this.partLengthValidate();
    this.disableCheck1();
    this.addDelete();
  }
  addDelete(){
    this.deleteArray = [];
    for (let i = 0; i < this.rows1.length; i++) {
      this.deleteFun(i)
    }
  }

  //delete part
  onPartDelete(index:any){
    this.openDialog(index);
  }

  deleteIndex(index: any) {
    for (let i = index; i < this.rows1.length; i++) {
      for (let j = 0; j < this.columns1.length; j++) {
        if(i < this.rows1.length - 1){
          this.form1.patchValue({
            [`row${i}col${j}`] : this.form1.controls[`row${i + 1}col${j}`].value
          })
        }else{
          this.form1.removeControl(`row${i}col${j}`);
          this.data = this.data.filter((_, i) => i !== index);
          this.rows1 = [...Array(i).keys()];
        }
      }
    }
  }  

  openDialog(index: any) {
    const dialogRef = this.dialog.open(DialogDivisionComponent, {
      data: {
        headerName: 'SPDF',
      },
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      const onloadLength = this.serPPFPartTable.length - 1;
      if (result === 'ok') {
        if (index > onloadLength) {
          this.deleteIndex(index);
        }
        else {
          const deletedRecord = [];
          deletedRecord.push(this.serPPFPartTable.at(index));
          this.internalPpfService.internalDelete({'part':deletedRecord}).subscribe({
            next: (_res: any) => {
              this.serPPFPartTable.splice(index, 1);
              const rowLength = this.rows1.length - 1;
              this.rows1 = [...Array(rowLength).keys()];
              this.partloop(this.serPPFPartTable, 0);
              this.disableCheck1();
              this.spinner.stop();
            },
            error: (_err: any) => {
              this.spinner.stop();
            }
          })
        }
      }
    });
  }
  addPartOnload() {
    this.payloadArray = [];
    for (let i = 0; i < this.data.length; i++) {
      const column0 = this.form1.controls[`row${i}col0`]?.value;
      if (column0 !== '' && column0 !== undefined) {
        const twoDigitValue = this.form1.controls[`row${i}col1`]?.value;
        const partDesc =  this.form1.controls[`row${i}col2`]?.value;
        const hPosition = this.form1.controls[`row${i}col3`]?.value;
        const partW = this.form1.controls[`row${i}col4`]?.value;
        const pLength = this.form1.controls[`row${i}col5`]?.value;
        const pWidth = this.form1.controls[`row${i}col6`]?.value;
        const pHeight = this.form1.controls[`row${i}col7`]?.value;
        const pVolume = this.form1.controls[`row${i}col8`]?.value;
        const pM3 = this.form1.controls[`row${i}col9`]?.value;
        this.payloadArray.push({
          "partnumber": this.form1.controls[`row${i}col0`]?.value,
          "twodigits": twoDigitValue || '',
          "partdescription": partDesc || null,
          "homeposition": hPosition || null,
          "partweight": partW || null,
          "partlength": pLength || null,
          "partwidth": pWidth || null,
          "partheight": pHeight || null,
          "partvolume": pVolume || null,
          "partm3": pM3 || null,
          "partrow": i + 1
        })
      }
    }
  } 
  //Error field Validation
  markFormGroupTouched(formGroup: FormGroup): void {
    Object.values(formGroup.controls).forEach(control => {
      control.markAsTouched();
 
      if (control instanceof FormGroup) {
        this.markFormGroupTouched(control);
      }
    });
  }

  servicePdfData() {    
    if(this.url.includes('tracking-list/update-ppf-reason') && this.mode===2){
      this.body = {
        "PPFCode": this.routeParams?.ppfcode,
        "PPFVer": this.routeParams?.ppfver,
        "History": this.routeParams?.history,
        "SpecialistCode": this.userInfo?.info?.specialistcode,
        "PlantCode": this.userInfo?.info?.plantcode,
        "PlantList": this.userInfo?.role?.p_plantList,
        "partnumber":this.partNum
      }
    }else if ((this.url.includes('tracking-list/update-ppf-reason') && this.mode===1) || (this.url.includes(this.trackingList)) || this.url.includes('tracking-list/send-dept-approval') || this.url.includes('part-list') || this.url.includes('tracking-list/sI-final-dept-approval')  || this.url.includes('/tracking-list/hazmat-approval')) {
      this.body = {
        "PPFCode": this.routeParams?.ppfcode,
        "PPFVer": this.routeParams?.ppfver,
        "SpecialistCode": this.userInfo?.info?.specialistcode,
        "PlantCode": this.userInfo?.info?.plantcode,
        "PlantList": this.userInfo?.role?.p_plantList,
        "partnumber":this.partNum
      }
    } else if (this.url.includes('tracking-list/send-ppf') || this.url.includes('dept-approve') || this.url.includes('discripency-list')) {
      this.body = {
        "PPFCode": this.routeParams?.ppfcode,
        "SpecialistCode": this.userInfo?.info?.specialistcode,
        "PlantCode": this.userInfo?.info?.plantcode,
        "PlantList": this.userInfo?.role?.p_plantList,
        "partnumber":this.partNum
      }
    } else if ((this.url.includes(this.trackingList) && this.mode === 2) || this.url.includes('pur-decision-list') || this.url.includes('decision-list')) {
      this.body = {
        "PPFCode": this.routeParams?.ppfcode,
        "SpecialistCode": this.userInfo?.info?.specialistcode,
        "PlantCode": this.userInfo?.info?.plantcode,
        "PlantList": this.userInfo?.role?.p_plantList,
        "partnumber":this.partNum
      }
    }
    else {
      this.body = {
        "PPFCode": this.routeParams?.ppfcode,
        "PPFVer": this.routeParams?.ppfver,
        "History": this.routeParams?.history,
        "SpecialistCode": this.userInfo?.info?.specialistcode,
        "PlantCode": this.userInfo?.info?.plantcode,
        "PlantList": this.userInfo?.role?.p_plantList,
         "partnumber":this.partNum
      }
    }

    if (this.url.includes('tracking-list/tracking-history')) {
      this.body = {
        "PPFCode": this.routeParams?.ppfcode,
        "PPFVer": this.routeParams?.ppfver,
        "History": this.routeParams?.history,
        "SpecialistCode": this.userInfo?.info?.specialistcode,
        "PlantCode": this.userInfo?.info?.plantcode,
        "PlantList":this.userInfo?.role?.p_plantList,
        "partnumber":this.partNum
      }
    } 
    this.spinner.start();  

    this.internalPpfService?.servicePdfOnload(this.body)?.subscribe({
      next : (res:any) =>{
        this.performDataOperations(res);
      },
      error: (_err: any) => {
        this.spinner.stop();
      },
      complete : ()=>{
        this.spinner.stop();
      },
    })
  }

  performDataOperations(res:any){
    this.response = res;
    this.serPpfDes = res.DisplayData?.serPPF
    this.screenMode = res.Mode;    
    this.serPPFPartTable =res.DisplayData?.serPPFPart
    let sortedSerPPFDetai1l: any[]=[];
    sortedSerPPFDetai1l = res.DisplayData?.serPPFDetail
    sortedSerPPFDetai1l = sortedSerPPFDetai1l?.slice().sort((a, b) => +a.rownumber - +b.rownumber);       
    this.filteredSerPPFDetail = sortedSerPPFDetai1l?.filter((details: { rownumber: string; }) => parseInt(details.rownumber) >= 1 && parseInt(details.rownumber) <= 11);
    this.filteredSerPPFDetail1 = sortedSerPPFDetai1l?.filter((details: { rownumber: string; }) => parseInt(details.rownumber) >= 12 && parseInt(details.rownumber) <= 16);
    this.filteredSerPPFDetail2 = sortedSerPPFDetai1l?.filter((details: { rownumber: string; }) => parseInt(details.rownumber) >= 17 && parseInt(details.rownumber) <= 23);
    this.filteredSerPPFDetail3 =sortedSerPPFDetai1l?.filter((details: { rownumber: string; }) => parseInt(details.rownumber) >= 24 && parseInt(details.rownumber) <= 31);        
    this.setGridDes(this.serPpfDes);   
    this.partloop(this.serPPFPartTable,1);
    this.setGridtwo(this.filteredSerPPFDetail)
    this.setGridthree(this.filteredSerPPFDetail1)
    this.setGridfour(this.filteredSerPPFDetail2)
    this.setGridfive(this.filteredSerPPFDetail3)
    this.disableCheck1();
    this.disableCheck2();
    this.disableCheck3();
    this.disableCheck4();
    this.disableCheck5();
    //grid2 func
    this.getkeyInterPack();
    this.getTotalCaseWeight();
    this.getTotalPkgCost();                  
    //unit grid
    this.unitlaborcost = this.serPpfDes[0]?.unitlaborcost;
    this.unitlabortime = this.serPpfDes[0]?.unitlabortime;
    this.unitmaterialcost = this.serPpfDes[0]?.unitmaterialcost;
    this.unitpkgcost = this.serPpfDes[0]?.unitpkgcost;
    //inter grid
    this.interlaborcost = this.serPpfDes[0]?.interlaborcost;
    this.interlabortime = this.serPpfDes[0]?.interlabortime;
    this.intermaterialcost = this.serPpfDes[0]?.intermaterialcost;
    this.interpkgcost = this.serPpfDes[0]?.interpkgcost;
    //master grid
    this.masterlaborcost = this.serPpfDes[0]?.masterlaborcost;
    this.masterlabortime = this.serPpfDes[0]?.masterlabortime;
    this.mastermaterialcost = this.serPpfDes[0]?.mastermaterialcost;
    this.masterpkgcost = this.serPpfDes[0]?.masterpkgcost;
    //pallet grid
    this.palletlaborcost = this.serPpfDes[0]?.palletlaborcost;
    this.palletlabortime = this.serPpfDes[0]?.palletlabortime;
    this.palletmaterialcost = this.serPpfDes[0]?.palletmaterialcost;
    this.palletpkgcost = this.serPpfDes[0]?.palletpkgcost;  
    //unitGrid disable
    this.hazmatDangHide();
    this.onRequestChange();
    //tms part calc
    this.onInputChange1();
    this.onInputChange5();
    this.onInputChange3();
    this.onInputChange4();
    this.onInputChange2();
    //dynamic images
    this.imageNames = [
      res.DisplayData?.serPPF[0]?.imgbarepart,
      res.DisplayData?.serPPF[0]?.imgpkgdunaroundpart,
      res.DisplayData?.serPPF[0]?.imgintermediatepkgpart,
      res.DisplayData?.serPPF[0]?.imgpalletorcaseloadimg,
      res.DisplayData?.serPPF[0]?.imgunitandintermediatelevel,
      res.DisplayData?.serPPF[0]?.imgotherphoto,
      res.DisplayData?.serPPF[0]?.imgadditionalphoto,
    ]
    for (let index = 0; index < 7; index++) {          
      this.imageNames[index] && this.getOnLoadImages(index);
    }       
  }


  setGridDes(data:any){ 
    if(this.routeParams.external === '1'){
      this.date = formatDate(new Date(), 'MM/dd/yyyy', 'en-US', 'UTC');
    }
    else{
      this.date = this.serPpfDes[0]?.regdate;
    } 
    this.form0.controls['status'].patchValue('1');
    this.form0.controls['requestType'].patchValue(data[0]?.requesttype);  
    this.form0.controls['partType'].patchValue(data[0]?.parttype);         
    //vender       
    this.form0.controls['venDate'].patchValue(this.date);
    this.form0.controls['venName'].patchValue(data[0]?.vendorname);
    this.form0.controls['venFOB'].patchValue(data[0]?.fobcity);
    this.form0.controls['venCode'].patchValue(data[0]?.vendorcode);
    this.form0.controls['venProject'].patchValue(data[0]?.projectcode);
    this.form0.controls['venSubName'].patchValue(data[0]?.submittername);
    this.form0.controls['venSubEmail'].patchValue(data[0]?.submitteremail);
    this.form0.controls['venSubPhone'].patchValue(data[0]?.submitterphone);
    //key inputs
    this.form0.controls['keyLaborRate'].patchValue(data[0]?.laborrate);
    this.form0.controls['keyWeight'].patchValue(data[0]?.weightofunit);
    this.form0.controls['keyUnitPackage'].patchValue(data[0]?.piecesperunit);
    this.form0.controls['keyInterPack'].patchValue(data[0]?.unitspereachintermediatepack);
    this.form0.controls['keyMasterpack'].patchValue(data[0]?.packspermasterpack);
    this.form0.controls['keyPallet'].patchValue(data[0]?.totalunitspermasterpack);
    this.form0.controls['keyPackageFill'].patchValue(data[0]?.totalunitpackagefill);
    this.form0.controls['keyCaseWeight'].patchValue(data[0]?.totalcaseweight);
    //total unit packaging
    this.form0.controls['totalDangerousGoods'].patchValue(data[0]?.hazmat);
    this.form0.controls['totalComplianceTM'].patchValue(data[0]?.hazmatpackageapproval);
    this.form0.controls['totalPKGEngineer'].patchValue(data[0]?.finalpkgapproval);
    this.form0.controls['totalMasterpackEst'].patchValue(data[0]?.unitspermasterpackest);
    this.form0.controls['totalMaterial'].patchValue(data[0]?.totalmaterialcostperunit);
    this.form0.controls['totalLabour'].patchValue(data[0]?.totallaborcostperunit);
    
    //unit grid
    this.form0.controls['unitfloormat1'].patchValue(data[0]?.unitfloormat1);
    this.form0.controls['unitfloormat2'].patchValue(data[0]?.unitfloormat3);
    this.form0.controls['unitfloormat3'].patchValue(data[0]?.unitfloormat3);
    //master grid
    this.form0.controls['masterheatpallet'].patchValue(validate(data[0]?.masterheatpallet));
    this.form0.controls['masterflushpallet'].patchValue(validate(data[0]?.masterflushpallet));
    //pallet grid
    this.form0.controls['palletflushpallet'].patchValue(validate(data[0]?.palletflushpallet));
    this.form0.controls['palletheatpallet'].patchValue(validate(data[0]?.palletheatpallet));
    
    this.setNonEditableGridDes(data);
  }
  setNonEditableGridDes(data: any){   
    if(this.statusDropdown[0]?.statusValue === '1'){
      this.finalView = 'FINAL' //status value 1 as Final
    }
    this.keyWeight = data[0]?.weightofunit;
    this.keyInterPack = data[0]?.unitspereachintermediatepack;
    this.keyMasterpack = data[0]?.packspermasterpack;
    this.keyPallet = data[0]?.totalunitspermasterpack;
    this.keyPackageFill = data[0]?.totalunitpackagefill;
    this.keyCaseWeight = data[0]?.totalcaseweight;
    this.totalUnitPackagingCost = '$' + data[0]?.totalunitpackagingcost;
    this.totalComplianceTM = data[0]?.hazmatpackageapproval;
    this.totalPKGEngineer = data[0]?.finalpkgapproval;
    this.totalMasterpackEst = data[0]?.unitspermasterpackest;
    this.totalMaterial = data[0]?.totalmaterialcostperunit;
    this.totalLabour = data[0]?.totallaborcostperunit;
    //unit grid    
    if(data[0]?.unitfloormat1 === 'untick'){
      this.unitfloormat1 = ''
    }
    else{
      this.unitfloormat1 = data[0]?.unitfloormat1;
    }
    if(data[0]?.unitfloormat2 === 'untick'){
      this.unitfloormat2 = ''
    }
    else{
      this.unitfloormat2 = data[0]?.unitfloormat2;
    }
    if(data[0]?.unitfloormat3 === 'untick'){
      this.unitfloormat3 = ''
    }
    else{
      this.unitfloormat3 = data[0]?.unitfloormat3;
    }   
  }
  keyMasterPackChange() {
    this.unitHazMatLabel();
    this.masterHeavyCaseLabel();
    this.palletHeavyCaseLabel();
    this.getkeyInterPack(); 
    this.getTotalCaseWeight(); 
    this.getTotalLabourCost();
    this.getTotalMaterialCost(); 
    this.getTotalPkgCost(); 
    this.getTotalLabourCost();   
  } 
  keyLaborRateChange(){
    this.getTotalLabourCost(); 
    this.getTotalPkgCost();
    this.unitLabourCost();
    this.intermediateLabourCost();
    this.masterLabourCost();
    this.palletLabourCost();
    this.onInputChange2();
    this.onInputChange3();
    this.onInputChange4();
    this.onInputChange5();
  }
  keyUnitPackageChange(){
    this.keyUnitPkg();
    this.getTotalLabourCost();
    this.getPackageFill();
    this.getTotalPkgCost();
    this.getTotalLabourCost();
    this.getTotalMaterialCost()
  }
navigatePrevious() {
  this._location.back();  
  sessionStorage.removeItem('partnumber');
}
//hazmat dangerous good
hazmatDangHide(){  
  const totalDangGood = validate(this.form0.controls['totalDangerousGoods'].value);
  if(totalDangGood === 'N'){
    this.hazmatPKG = true;
  }
  else if(totalDangGood === 'Y'){
      this.hazmatPKG = false; 
  } 
}
// grid1
onInputChange1() {
  this.close();
  for (let i = 0; i < this.data.length; i++){
    const partLength = this.form1.controls[`row${i}col5`].value; // dynamic part length
    const partWidth = this.form1.controls[`row${i}col6`].value; // dynamic part width
    const partHeight = this.form1.controls[`row${i}col7`].value; // dynamic part height
    const result = convertAndCheck(partLength, partWidth, partHeight);
    this.form1.controls[`row${i}col9`].patchValue(toNumber(result)); 
  }
  this.partLengthValidate();
  this.form0.controls['keyWeight']?.patchValue(this.getKeyWeight());
  this.getPackageFill();
  this.getUnitPerMasterPack();
}
partLengthValidate(){
  this.partLengthValidator = []
  for (let i = 0; i < this.data.length; i++){
    const part = validate(this.form1.controls[`row${i}col0`]?.value);        
    if ((!part || part?.length !== 10) && part?.length !== 0) {
      this.partLengthValidator.push(true)
      this.errorList.push('Please only enter the 10 digit part number');
      this.markFormGroupTouched(this.form1);
    }
    else{
      this.partLengthValidator.push(false)
    }
  }
}
value1Change(){ 
  this.onInputChange2();
  this.onInputChange4();
  this.onInputChange5();
  this.unitHazMatLabel();
}
tmsValue(){  
  const formKeys = Object.keys(this.form1.controls);
  for (const i of formKeys) {
    if (i.includes("col0")) { 
      const part = this.form1.controls[i]?.value;           
      if(part?.length < 10 || part?.length > 10){          
        this.form1.controls[i].setValidators([Validators.minLength(10), Validators.maxLength(10)]);
        this.form1.controls[i].updateValueAndValidity();       
      }
      else{
        this.form1.controls[i]?.clearValidators(); 
        this.form1.controls[i].updateValueAndValidity();            
      }       
    }
  }
}
tmsValue2(i:any){ 
  const part = this.form1.controls[`row${i}col0`]?.value;
  if(part?.length < 10 || part?.length > 10){          
    this.form1.controls[`row${i}col0`].setValidators([Validators.minLength(10), Validators.maxLength(10)]);
    this.form1.controls[`row${i}col0`].updateValueAndValidity();       
  }
  else{
    this.form1.controls[`row${i}col0`]?.clearValidators(); 
    this.form1.controls[`row${i}col0`].updateValueAndValidity();            
  }   
}
setGridOne(data:any) {
  //dynamic rows
  this.deleteArray = [];
  for (let i = 0; i < this.data.length; i++) {
    if(this.indexValue !== -1){
      this.form1.controls[`row${i}col0`].patchValue(data[i]?.partnumber);
      this.form1.controls[`row${i}col1`].patchValue(data[i]?.twodigits);
      this.form1.controls[`row${i}col2`].patchValue(data[i]?.partdescription);    
      this.form1.controls[`row${i}col3`].patchValue(data[i]?.homeposition);
      this.form1.controls[`row${i}col4`].patchValue(data[i]?.partweight);
      this.form1.controls[`row${i}col5`].patchValue(data[i]?.partlength);
      this.form1.controls[`row${i}col6`].patchValue(data[i]?.partwidth);
      this.form1.controls[`row${i}col7`].patchValue(data[i]?.partheight);
      this.form1.controls[`row${i}col8`].patchValue(data[i]?.partvolume);
      this.form1.controls[`row${i}col9`].patchValue(data[i]?.partm3);
      this.deleteFun(i)
    }
  } 
}
deleteFun(i:any){
  if(this.tenDigitValue === this.form1.controls[`row${i}col0`]?.value ){
    this.deleteArray.push(false)   
  } else{
    this.deleteArray.push(true);    
  }  
}
setNonEditableGrid1(data:any){
   //dynamic rows
  for (let i = 0; i < this.data.length; i++) {
    if(this.mode !== 1){
      if(this.indexValue !== -1){
        const removedItem = data.splice(this.indexValue, 1)[0];
        data.unshift(removedItem);
        const last2Digits = this.partNum.slice(-2);
        if (data[i]?.partnumber === this.tenDigitValue) {
          const valuesArray = data[i]?.twodigits.split(',');
          const twodigit = valuesArray.filter((value: any) => last2Digits.includes(value));
          this.assignDataValues(i, data, twodigit);
          break;
        }
      }     
    }       
  } 
}
  assignDataValues(i: number, data: any, twodigit: string){
    this.data[i].col0 = data[i]?.partnumber;
    this.data[i].col1 = twodigit; 
    this.data[i].col2 = data[i]?.partdescription;
    this.data[i].col3 = data[i]?.homeposition;
    this.data[i].col4 = data[i]?.partweight;
    this.data[i].col5 = data[i]?.partlength;
    this.data[i].col6 = data[i]?.partwidth;
    this.data[i].col7 = data[i]?.partheight;
    this.data[i].col8 = data[i]?.partvolume;
    this.data[i].col9 = data[i]?.partm3?.toFixed(5);
  }  
  disableCheck1() {
    const formKeys = Object.keys(this.form1.controls);
    for (const i of formKeys) {
      if (i.includes("col9")) {
        this.form1.get(i)?.disable();
      }
    }
  } 
//grid2
  onInputChange2() {
    this.close();
    const laborRate = this.form0.controls['keyLaborRate'].value;
    for (let i = 0; i < this.unitTable.length; i++) {
      if (i !== 6 && i !== 7 && i !== 8 && i !== 9 && i !== 10) {
        const laborRate = this.form0.controls['keyLaborRate'].value;
        const unitPkgType = this.form2.controls['row0col1'].value;
        const unitCost = this.form2.controls[`row${i}col7`].value;
        if (i === 0) {
          const qty = 1;
          if (unitPkgType === this.barePart) {
            const laborTimeValue = 0; //labour value for bare part
            const result = calculateValue(qty, unitCost, laborRate, laborTimeValue);
            this.unitpackageDropdown(laborTimeValue,qty,result,i)
          } else if (unitPkgType === this.polyBag || unitPkgType === this.BUBBLE_BAG || unitPkgType === this.otherBag || unitPkgType === this.BOTTLE_BAG || unitPkgType === this.tubeBag) {
            const laborTimeValue = 11;  //labour value for Poly Bag
            const result = calculateValue(qty, unitCost, laborRate, laborTimeValue);
            this.unitpackageDropdown(laborTimeValue,qty,result,i);
          }
          else if (unitPkgType === this.SMALL_PARCEL_SHIPPING_BOX) {
            const laborTimeValue = 5; //labour value for Small Parcel Shipping Box
            const result = calculateValue(qty, unitCost, laborRate, laborTimeValue);
            this.unitpackageDropdown(laborTimeValue,qty,result,i);
          } else if (unitPkgType === this.BOX_LENGTH || unitPkgType === this.UN_CERTIFIED_BOX ) {
            const laborTimeValue = 20; ////labour value for box length <=48
            const result = calculateValue(qty, unitCost, laborRate, laborTimeValue);
            this.unitpackageDropdown(laborTimeValue,qty,result,i);
          } else if (unitPkgType === this.LARGE_BOX ) {
            const laborTimeValue = 30; //labour value for large box
            const result = calculateValue(qty, unitCost, laborRate, laborTimeValue);
            this.unitpackageDropdown(laborTimeValue,qty,result,i);
          }
        }
        const laborTime = this.form2.controls[`row${i}col8`].value;
        const qty = this.form2.controls[`row${i}col9`].value;
        const result = calculateValue(qty, unitCost, laborRate, laborTime);
        this.form2.controls[`row${i}col10`].patchValue(result);
      }
      else if (i !== 7 && i !== 8 && i !== 9 && i !== 10) {
        const unitPkgType = this.form2.controls['row0col1'].value;
        if (unitPkgType === this.barePart || unitPkgType === this.polyBag || unitPkgType === this.BUBBLE_BAG || unitPkgType === this.otherBag || unitPkgType === this.BOTTLE_BAG ||
          unitPkgType === this.tubeBag || unitPkgType === this.SMALL_PARCEL_SHIPPING_BOX || unitPkgType === this.BOX_LENGTH || unitPkgType === this.LARGE_BOX || unitPkgType === this.UN_CERTIFIED_BOX ) {
          const qtyValue = 1;
          this.form2.controls[`row${i}col4`].patchValue(qtyValue);
          const unitCost = this.form2.controls[`row${i}col2`].value;
          const laborTime = this.form2.controls[`row${i}col3`].value;
          const result = calculateValue(qtyValue, unitCost, laborRate, laborTime);
          this.form2.controls[`row${i}col5`].patchValue(result);
        }
      }
      else if (i !== 8 && i !== 9 && i !== 10) {
        const qty = this.form2.controls[`row${i}col4`].value;
        const unitCost = this.form2.controls[`row${i}col2`].value;
        const laborTime = this.form2.controls[`row${i}col3`].value;
        const result = calculateValue(qty, unitCost, laborRate, laborTime);
        this.form2.controls[`row${i}col5`].patchValue(result);
      }
      else {
        const unitCost = this.form2.controls[`row${i}col1`].value;
        const laborTime = this.form2.controls[`row${i}col2`].value;
        const qty = this.form2.controls[`row${i}col3`].value;
        const result = calculateValue(qty, unitCost, laborRate, laborTime);
        this.form2.controls[`row${i}col4`].patchValue(result);
      }
    }    
    const rowValues = [
      this.form2.controls['row1col9'].value,
      this.form2.controls['row2col9'].value,
      this.form2.controls['row3col9'].value,
      this.form2.controls['row4col9'].value,
      this.form2.controls['row5col9'].value,
    ];
    const row9col4 = this.form2.controls['row9col4'].value;
    const row10col4 = this.form2.controls['row10col4'].value;
    const unitPkgType = this.form2.controls['row0col1'].value;
    const unitCost = this.form2.controls['row0col7']?.value;

    if (validate(unitPkgType) === '' && validate(unitCost)) {
      this.form2.controls['row0col1'].setValidators([Validators.required]);
      this.form2.controls['row0col1'].updateValueAndValidity();
      this.markFormGroupTouched(this.form2);
      this.errorList.push("Please select a packaging type from drop down list. If Bare Part, this cell should be blank");
    }
    else {
      this.form2.controls['row0col1'].clearValidators();
      this.form2.controls['row0col1'].updateValueAndValidity();
    }
    if (rowValues.some(value => validate(value) > 10) || validate(row9col4) > 10 || validate(row10col4) > 10) {
      this.unitPackageData = true;
      for (let i = 1; i <= 5; i++) {
        const control = this.form2.get(`row${i}col9`);
        control?.setValidators([Validators.max(10)]);
        control?.updateValueAndValidity();
      }
      const control9 = this.form2.get('row9col4');
      control9?.setValidators([Validators.max(10)]);
      control9?.updateValueAndValidity();
      const control10 = this.form2.get('row10col4');
      control10?.setValidators([Validators.max(10)]);
      control10?.updateValueAndValidity();
      this.markFormGroupTouched(this.form2);
      this.errorList.push("The value doesn't match the data validation restrictions for this cell.");
    }
    else {
      this.unitPackageData = false;
      for (let i = 1; i <= 5; i++) {
        const control = this.form2.get(`row${i}col9`);
        control?.clearValidators();
        control?.updateValueAndValidity();
      }
      const control9 = this.form2.get('row9col4');
      control9?.clearValidators();
      control9?.updateValueAndValidity();
      const control10 = this.form2.get('row10col4');
      control10?.clearValidators();
      control10?.updateValueAndValidity();
    }
    const rowValues1 = [
      this.form2.controls['row1col7'].value,
      this.form2.controls['row2col7'].value,
      this.form2.controls['row3col7'].value,
      this.form2.controls['row4col7'].value,
      this.form2.controls['row5col7'].value,
    ];

    if (rowValues1.some(value => validate(value) > 99)) {
      this.unitPackageMat = true;
      for (let i = 1; i <= 5; i++) {
        const control = this.form2.get(`row${i}col7`);
        control?.setValidators([Validators.max(99)]);
        control?.updateValueAndValidity();
      }
      this.errorList.push("Please engage Pkg Engineer for individual packaging components in exceess of $100.");
    }
    else {
      this.unitPackageMat = false;
      for (let i = 1; i <= 5; i++) {
        const control = this.form2.get(`row${i}col7`);
        control?.clearValidators();
        control?.updateValueAndValidity();
      }
    }      
    this.form0.controls['keyWeight']?.patchValue(this.getKeyWeight());
    this.getPackageFill();
    this.unitLabourCost();
    this.unitHazMatLabel();
    this.masterHeavyCaseLabel();
    this.palletHeavyCaseLabel();
    this.getTotalMaterialCost();
    this.getTotalLabourCost();
  }
  unitpackageDropdown(laborTimeValue:any,qty:any,result:any,i:any){
    this.form2.controls[`row${i}col8`].patchValue(laborTimeValue);
    this.form2.controls[`row${i}col9`].patchValue(qty);
    this.form2.controls[`row${i}col10`].patchValue(result);
  }
setGridtwo(data:any){ 
 // dynamic rows set from row0 to row6
  for (let i = 0; i < 6; i++) {
    this.form2.controls[`row${i}col1`].patchValue(validate(data[i]?.packagingtype))
    this.form2.controls[`row${i}col2`].patchValue(validate(data[i]?.materialdescription))
    this.form2.controls[`row${i}col3`].patchValue(validate(data[i]?.weight))
    this.form2.controls[`row${i}col4`].patchValue(validate(data[i]?.length))
    this.form2.controls[`row${i}col5`].patchValue(validate(data[i]?.width))
    this.form2.controls[`row${i}col6`].patchValue(validate(data[i]?.height))
    this.form2.controls[`row${i}col7`].patchValue(validate(data[i]?.unitcost))
    this.form2.controls[`row${i}col8`].patchValue(validate(data[i]?.labortime))
    if(i === 0){
      this.form2.controls[`row${i}col9`].patchValue(validate(1)) // default QTY value for unit package
    }
    else{
      this.form2.controls[`row${i}col9`].patchValue(validate(data[i]?.qty)) 
    }      
    this.form2.controls[`row${i}col10`].patchValue((data[i]?.totalcost))
  } 
  //row7
  this.form2.controls['row6col1'].patchValue(this.WHITLAM_LABEL);
  this.form2.controls['row6col2'].patchValue(0.0540) //Material Unit value for row 6
  this.form2.controls['row6col3'].patchValue(3) //Labor time
  this.form2.controls['row6col4'].patchValue(validate(data[6]?.qty))
  this.form2.controls['row6col5'].patchValue(validate(data[6]?.totalcost))
  //row8
  this.form2.controls['row7col1'].patchValue(this.WHITLAM_LABEL);
  this.form2.controls['row7col2'].patchValue(0.0750) //Material Unit value for row 7
  this.form2.controls['row7col3'].patchValue(3)  //Labor time
  this.form2.controls['row7col4'].patchValue(validate(data[7]?.qty))
  this.form2.controls['row7col5'].patchValue(validate(data[7]?.totalcost))
  //row9
  this.form2.controls['row8col1'].patchValue(validate(data[8]?.unitcost))
  this.form2.controls['row8col2'].patchValue(3)  //Labor time
  this.form2.controls['row8col3'].patchValue(validate(data[8]?.qty))
  this.form2.controls['row8col4'].patchValue(validate(data[8]?.totalcost))
  //row10
  this.form2.controls['row9col1'].patchValue(validate(data[9]?.unitcost))
  this.form2.controls['row9col2'].patchValue(3)  //Labor time
  this.form2.controls['row9col3'].patchValue(validate(data[9]?.qty))
  this.form2.controls['row9col4'].patchValue(validate(data[9]?.totalcost))
  //row11
  this.form2.controls['row10col1'].patchValue(validate(data[10]?.unitcost))
  this.form2.controls['row10col2'].patchValue(3)  //Labor time
  this.form2.controls['row10col3'].patchValue(validate(data[10]?.qty))
  this.form2.controls['row10col4'].patchValue(validate(data[10]?.totalcost))

  this.constants.setGrid2(this.unitTable, data);
}
disableCheck2() {
  const formKeys = Object.keys(this.form2.controls) 
  for (const i of formKeys) {
    if (['row0col8', 'row0col9', 'row6col2', 'row6col3', 'row6col4', 'row6col5', 'row7col2', 'row7col3',
      'row7col4', 'row7col5', 'row8col2', 'row9col2', 'row10col2','row8col4', 'row9col4', 'row10col4'].includes(i)) {
      this.form2.controls[i]?.disable();
    } 
    if (i.includes("col10",)) {
      this.form2.controls[i]?.disable();
    } else {
      continue;
    }
  }
}
getitertor2(row :any){
  if(row===6 || row===7){
    return this.columns2a;
  }
  else if(row===8 || row===9 || row===10){
    return this.columns2ab;
  }
  else{
    return this.columns2;
  }
}
unitDropdown(row:any){
  if(row === 0){
    return this.unitPackageType;
  }
  else{
    return null;
  }
}
//grid3
onInputChange3() {
  const laborRate = this.form0.controls['keyLaborRate'].value;
  const keyMasterpackValue = validate(this.form0.controls['keyMasterpack']?.value);
  for (let i = 0; i < this.intermediateTable.length; i++) {
    if(i!==4){
      const interPkgType = this.form3.controls['row0col1'].value;
      const unitCost = this.form3.controls[`row${i}col7`].value;          
      if(i === 0){
        if(interPkgType === this.polyBag || interPkgType === this.BUBBLE_BAG ){
          const laborTimeValue = 6 + 2*(keyMasterpackValue); //labour value for polybag and bubble bag
          this.interPackageDropdown(laborTimeValue,unitCost,laborRate,i);   
        }else if(interPkgType === 'Wrap'){
          const laborTimeValue = 2  + 2*(keyMasterpackValue); //labour value for wrap
          this.interPackageDropdown(laborTimeValue,unitCost,laborRate,i);   
        }else if(interPkgType === this.SMALL_PARCEL_SHIPPING_BOX ){
          const laborTimeValue = 0  + 2*(keyMasterpackValue); //labour value for small parcel
          this.interPackageDropdown(laborTimeValue,unitCost,laborRate,i)   
        }else if(interPkgType === this.BOX_LENGTH ){
          const laborTimeValue = 15  + 2*(keyMasterpackValue); //labour value for box length <=48
          this.interPackageDropdown(laborTimeValue,unitCost,laborRate,i)
        }
        else if(interPkgType === this.LARGE_BOX){
          const laborTimeValue = 25  + 2*(keyMasterpackValue); //labour value for large box
          this.interPackageDropdown(laborTimeValue,unitCost,laborRate,i)        
        }
      }      
      const laborTime = this.form3.controls[`row${i}col8`].value;
      const qty = this.form3.controls[`row${i}col9`].value;        
      const result = calculateValue(qty,unitCost,laborRate,laborTime);
      this.form3.controls[`row${i}col10`].patchValue(result);     
    }
    else{
      const interPkgType = this.form3.controls['row0col1'].value;
      if (interPkgType === this.polyBag || interPkgType === this.BUBBLE_BAG || interPkgType === 'Wrap' || interPkgType === this.SMALL_PARCEL_SHIPPING_BOX || interPkgType === this.BOX_LENGTH || interPkgType === this.LARGE_BOX ) {
        const qtyValue = 1; //quantity value
        this.form3.controls[`row${i}col4`].patchValue(qtyValue);
        const unitCost = this.form3.controls[`row${i}col2`].value;
        const laborTime = this.form3.controls[`row${i}col3`].value;
        const result = calculateValue(qtyValue, unitCost, laborRate, laborTime);
        this.form3.controls[`row${i}col5`].patchValue(result);
      }
    }
  } 
  const row0col7 = this.form3.controls['row0col7'].value;
  const row0col2 = this.form3.controls['row0col2'].value;
  const rowValues = [
    this.form3.controls['row1col9'].value,
    this.form3.controls['row2col9'].value,
    this.form3.controls['row3col9'].value
  ];
  if (validate(row0col2) === '' && validate(row0col7) !== '' && row0col7 !== 0) {
    this.errorList = [];
    this.form3.controls['row0col7'].patchValue(null);
    this.form3.get('row0col2')?.setValidators([Validators.required]);
    this.form3.get('row0col2')?.updateValueAndValidity();
    this.markFormGroupTouched(this.form3);
    this.errorList.push("Please enter Intermediate Packaging Material Description first.");
  } else if (rowValues.some(value => validate(value) > 6)) {
    this.errorList = [];
    this.inter = true;
    for (let i = 1; i <= 3; i++) {
      const control = this.form3.get(`row${i}col9`);
      control?.setValidators([Validators.max(6)]);
      control?.updateValueAndValidity();
    }
    this.markFormGroupTouched(this.form3);
    this.errorList.push("Any intermediate package material >6 please reach out to your Packaging Engineer.");
  }
  else if (rowValues.some(value => validate(value) <= 6)) {
    if (validate(row0col7) === '' && (validate(row0col2) !== '' || validate(row0col2) === '')){
    this.form3.get('row0col2')?.clearValidators(['']);
    this.form3.get('row0col2')?.updateValueAndValidity();
    this.close();
    }
    else{
    this.inter = false;
    for (let i = 1; i <= 3; i++) {
      const control = this.form3.get(`row${i}col9`);
      control?.clearValidators();
      control?.updateValueAndValidity();
    }
    this.close();
    } 
  } 
  else {
    this.close();
  }

  this.getTotalCaseWeight(); 
  this.intermediateLabourCost();
  this.masterHeavyCaseLabel();
  this.palletHeavyCaseLabel();
  this.getTotalMaterialCost();
  this.getTotalLabourCost();
}
interPackageDropdown(laborTimeValue:any,unitCost:any,laborRate:any,i:any){
  this.form3.controls[`row${i}col8`]?.patchValue(laborTimeValue);
  this.qty = this.form3.controls[`row${i}col9`].setValue(1);
  const result = calculateValue(this.qty,unitCost,laborRate,laborTimeValue);
  this.form3.controls[`row${i}col10`].patchValue(result); 
}
getitertor3(row:any){
  if(row===4){
    return this.columns3a
  }else{
    return this.columns3
  }
}
setGridthree(data:any){
  // dynamic rows patch from row0 to row4
  for (let i = 0; i < 4; i++) {
    this.form3.controls[`row${i}col1`].patchValue(validate(data[i]?.packagingtype))
    this.form3.controls[`row${i}col2`].patchValue(validate(data[i]?.materialdescription))
    this.form3.controls[`row${i}col3`].patchValue(validate(data[i]?.weight))
    this.form3.controls[`row${i}col4`].patchValue(validate(data[i]?.length))
    this.form3.controls[`row${i}col5`].patchValue(validate(data[i]?.width))
    this.form3.controls[`row${i}col6`].patchValue(validate(data[i]?.height))
    this.form3.controls[`row${i}col7`].patchValue(validate(data[i]?.unitcost))
    this.form3.controls[`row${i}col8`].patchValue(validate(data[i]?.labortime))
    this.form3.controls[`row${i}col9`].patchValue(validate(data[i]?.qty))
    this.form3.controls[`row${i}col10`].patchValue(validate(data[i]?.totalcost))
  }  
  // row5
  this.form3.controls['row4col1'].patchValue(this.WHITLAM_LABEL);
  this.form3.controls['row4col2'].patchValue(0.0540) //Material unit for row 4
  this.form3.controls['row4col3'].patchValue(3) //labor time for row 4
  this.form3.controls['row4col4'].patchValue(validate(data[4]?.qty))
  this.form3.controls['row4col5'].patchValue(validate(data[4]?.totalcost))

  this.constants.setNonEditableGrid3(this.intermediateTable, data)
}
disableCheck3(){
  const formKeys = Object.keys(this.form3.controls) 
  for (const i of formKeys) {
    if (['row0col8', 'row0col9', 'row4col2', 'row4col3', 'row4col4', 'row4col5'].includes(i)) {
      this.form3.controls[i]?.disable();
    } 
    if (i.includes("col10")) {
      this.form3.controls[i]?.disable();
    } else {
      continue;
    }
  }
}
interDropdown(row:any){
  if(row === 0){
    return this.interPackageType;
  }
  else{
    return null;
  }
}
//grid4
getitertor4(row :any){
  if(row===5 || row===6){
    return this.columns4a
  }else{
    return this.columns4
  }
}
onInputChange4() {
  const pkgType1 = this.form4.controls['row0col1'].value;
  const pkgType2 = this.form4.controls['row1col1'].value;   
  const returnablePackageTypes = [
    this.U_RETURNABLE,this.MU_RETURNABLE,this.HU_RETURNABLE,this._2U_RETURNABLE,this.MINI_C3_RETURNABLE,
    this.C3_RETURNABLE,this.C4_RETURNABLE,this.WUL_RETURNABLE,this.BCR_RETURNABLE
  ];
  const masterpackPackageTypes = [
    this.U_MSTERPACK,this.MU_MSTERPACK,this.HU_MSTERPACK,this.TWOU_MASTERPACK,this.MINIC3_MASTERPACK,this.C3_MASTERPACK,
    this.C4_MASTERPACK,this.WUL_MASTERPACK,this.BCR_MASTERPACK,this.BOX_LENGTH,this.LARGE_BOX,this.CR90_MODULE,this.smallParcel
  ];  
  if (returnablePackageTypes.includes(pkgType1) || masterpackPackageTypes.includes(pkgType2)) {
    this.pallethide = false;
    this.masterhide = true;
    this.masterHeavyCaseLabel();
  } else {
    this.palletHidingCond();
  }  
  let weight;
  let length;
  let width;
  let height;
  let materialDesc;
  let laborTime;
  const laborRate = this.form0.controls['keyLaborRate'].value;
  const keyMasterpackValue = validate(this.form0.controls['keyPallet']?.value);
  const keyMasterpack = validate(this.form0.controls['keyMasterpack']?.value);
  let masterOrPallet;
  if(keyMasterpack !==''){
    masterOrPallet = keyMasterpack;
  }
  else{
    masterOrPallet = keyMasterpackValue;
  }
  for (let i = 0; i < this.masterTable.length; i++) {
    if(i!==5 && i!==6){
      const masterPkgType = this.form4.controls['row0col1'].value;
      const masterPkgType1 = this.form4.controls['row1col1'].value;
      if(i === 0){
        const laborTimeBaseValue = 60;        
        const laborTimeValue = laborTimeBaseValue + 5*(masterOrPallet);
        const qty = 1 //quantity for row 0
        const materialdescription ='Toyota Provided Returnable'; // material desc for row 0 
        const unitCostValue = 0; //unit cost value for row 0
        if(masterPkgType === ''){
          this.form4.controls[`row${i}col1`].patchValue(null);
          this.form4.controls[`row${i}col2`].patchValue(null);
          this.form4.controls[`row${i}col3`].patchValue(null);
          this.form4.controls[`row${i}col4`].patchValue(null);
          this.form4.controls[`row${i}col5`].patchValue(null);
          this.form4.controls[`row${i}col6`].patchValue(null);
          this.form4.controls[`row${i}col7`].patchValue(null);
          this.form4.controls[`row${i}col8`]?.patchValue(null);
          this.form4.controls[`row${i}col9`].patchValue(null);
          const result = null;
          this.form4.controls[`row${i}col10`].patchValue(result);    
        }
        else if(masterPkgType === this.U_RETURNABLE ){
          weight = 67585; length = 1219.2; width = 762; height = 838.2; //under material specification for package type U Returnable          
          const result = calculateValue(qty,unitCostValue,laborRate,laborTimeValue);
          this.masterDropdown1(materialdescription,weight,length,width,height,unitCostValue,laborTimeValue,qty,result,i)     
        }else if(masterPkgType === this.MU_RETURNABLE ){
          weight = 62142; length = 1219.2; width = 762; height = 635; //under material specification for package type MU Returnable           
          const result = calculateValue(qty,unitCostValue,laborRate,laborTimeValue);
          this.masterDropdown1(materialdescription,weight,length,width,height,unitCostValue,laborTimeValue,qty,result,i)   
        }else if(masterPkgType === this.HU_RETURNABLE ){
          weight = 82100; length = 1219.2; width = 762; height = 1270; //under material specification for package type HU Returnable          
          const result = calculateValue(qty,unitCostValue,laborRate,laborTimeValue);
          this.masterDropdown1(materialdescription,weight,length,width,height,unitCostValue,laborTimeValue,qty,result,i)     
        }else if(masterPkgType === this._2U_RETURNABLE ){
          weight = 106141; length = 1219.2; width = 1524; height = 838.2; //under material specification for package type 2U Returnable           
          const result = calculateValue(qty,unitCostValue,laborRate,laborTimeValue);
          this.masterDropdown1(materialdescription,weight,length,width,height,unitCostValue,laborTimeValue,qty,result,i)    
        }
        else if(masterPkgType === this.MINI_C3_RETURNABLE ){
          weight = 52617; length = 1219.2; width = 762; height = 635; //under material specification for package type Mini C3 Returnable           
          const result = calculateValue(qty,unitCostValue,laborRate,laborTimeValue);
          this.masterDropdown1(materialdescription,weight,length,width,height,unitCostValue,laborTimeValue,qty,result,i)   
        }
        else if(masterPkgType === this.C3_RETURNABLE ){
          weight = 80286; length = 1524; width = 1219.2; height = 1219.2; //under material specification for package type C3 Returnable           
          const result = calculateValue(qty,unitCostValue,laborRate,laborTimeValue);
          this.masterDropdown1(materialdescription,weight,length,width,height,unitCostValue,laborTimeValue,qty,result,i)      
        }
        else if(masterPkgType === this.C4_RETURNABLE){
          weight = 141067; length = 1727.2; width = 1219.2; height = 1219.2; //under material specification for package type C4 Returnable          
          const result = calculateValue(qty,unitCostValue,laborRate,laborTimeValue);
          this.masterDropdown1(materialdescription,weight,length,width,height,unitCostValue,laborTimeValue,qty,result,i)      
        }
        else if(masterPkgType === this.WUL_RETURNABLE ){
          weight = 161479; length = 2286; width = 1219.2; height = 838.2; //under material specification for package type WUL Returnable          
          const result = calculateValue(qty,unitCostValue,laborRate,laborTimeValue);
          this.masterDropdown1(materialdescription,weight,length,width,height,unitCostValue,laborTimeValue,qty,result,i)      
        }
        else if(masterPkgType === this.BCR_RETURNABLE ){
          weight = 150139; length = 2286; width = 1219.2; height = 838.2; //under material specification for package type BCR Returnable         
          const result = calculateValue(qty,unitCostValue,laborRate,laborTimeValue);
          this.masterDropdown1(materialdescription,weight,length,width,height,unitCostValue,laborTimeValue,qty,result,i)     
        }
      } 
      else if(i === 1){
        const qty = 1
        if(masterPkgType1 === ''){
          materialDesc=''; laborTime = ''; //material desc & labor time for U Masterpack
          this.form4.controls[`row${i}col1`].patchValue(null);
          this.form4.controls[`row${i}col2`].patchValue(null);
          this.form4.controls[`row${i}col8`]?.patchValue(null);
          this.form4.controls[`row${i}col9`].patchValue(null);
          const result = null;
          this.form4.controls[`row${i}col10`].patchValue(result);           
        }
        else if(masterPkgType1 === this.U_MSTERPACK){
          materialDesc='Corrugate Dims = Toyota U returnable'; laborTime = 90 + 5*(masterOrPallet); //material desc & labor time for U Masterpack           
          const result = ''
          this.masterDropdown2(materialDesc,laborTime,qty,result,i)     
        }else if(masterPkgType1 === this.MU_MSTERPACK){
          materialDesc='Corrugate Dims = Toyota MU returnable'; laborTime = 90 + 5*(masterOrPallet); //material desc & labor time for MU Masterpack          
          const result = ''
          this.masterDropdown2(materialDesc,laborTime,qty,result,i)    
        }else if(masterPkgType1 === this.HU_MSTERPACK){
          materialDesc='Corrugate Dims = Toyota HU returnable'; laborTime = 90 + 5*(masterOrPallet); //material desc & labor time for HU Masterpack         
          const result = ''
          this.masterDropdown2(materialDesc,laborTime,qty,result,i)     
        }else if(masterPkgType1 === this.TWOU_MASTERPACK){
          materialDesc='Corrugate Dims = Toyota 2U returnable'; laborTime = 90 + 5*(masterOrPallet); //material desc & labor time for 2U Masterpack     
          const result = ''
          this.masterDropdown2(materialDesc,laborTime,qty,result,i)   
        }else if(masterPkgType1 === this.MINIC3_MASTERPACK){
          materialDesc='Corrugate Dims = Toyota mini-C3 returnable'; laborTime = 90 + 5*(masterOrPallet); //material desc & labor time for Mini C3 Masterpack         
          const result = ''
          this.masterDropdown2(materialDesc,laborTime,qty,result,i)    
        }else if(masterPkgType1 === this.C3_MASTERPACK){
          materialDesc='Corrugate Dims = Toyota C3returnable '; laborTime = 90 + 5*(masterOrPallet); //material desc & labor time for C3 Masterpack           
          const result = ''
          this.masterDropdown2(materialDesc,laborTime,qty,result,i)     
        }else if(masterPkgType1 === this.C4_MASTERPACK){
          materialDesc='Corrugate Dims = Toyota C4 returnable'; laborTime = 90 + 5*(masterOrPallet); //material desc & labor time for C4 Masterpack    
          const result = ''
          this.masterDropdown2(materialDesc,laborTime,qty,result,i)      
        }else if(masterPkgType1 === this.WUL_MASTERPACK){
          materialDesc='Corrugate Dims = Toyota WUL returnable'; laborTime = 120 + 5*(masterOrPallet); //material desc & labor time for WUL Masterpack          
          const result = ''
          this.masterDropdown2(materialDesc,laborTime,qty,result,i)     
        }else if(masterPkgType1 === this.BCR_MASTERPACK){
          materialDesc='Corrugate Dims = Toyota BCR returnable'; laborTime = 120 + 5*(masterOrPallet); //material desc & labor time for BCR Masterpack       ; 
          const result = ''
          this.masterDropdown2(materialDesc,laborTime,qty,result,i)   
        }else if(masterPkgType1 === this.BOX_LENGTH){
          materialDesc=''; laborTime = 15 + 5*(masterOrPallet); //material desc & labor time for Box length<=48         
          const result = ''
          this.masterDropdown2(materialDesc,laborTime,qty,result,i)    
        }else if(masterPkgType1 === this.LARGE_BOX){
          materialDesc=''; laborTime = 25 + 5*(masterOrPallet); //material desc & labor time for Large box         
          const result = '';
          this.masterDropdown2(materialDesc,laborTime,qty,result,i)    
        }else if(masterPkgType1 ===  this.CR90_MODULE){
          materialDesc=''; laborTime = 120 + 5*(masterOrPallet); //material desc & labor time for CR90 Module         
          const result = '';
          this.masterDropdown2(materialDesc,laborTime,qty,result,i)   
        }else if(masterPkgType1 === this.smallParcel){
          materialDesc=''; laborTime = 60; //material desc & labor time for Small Parcel
          const result = null;
          this.masterDropdown2(materialDesc,laborTime,qty,result,i)    
        }
      }
      else{
        const unitCost = this.form4.controls[`row${i}col7`].value;          
        const laborTime = this.form4.controls[`row${i}col8`].value;
        const qty = this.form4.controls[`row${i}col9`].value; 
        const result = calculateValue(qty,unitCost,laborRate, laborTime);
        this.form4.controls[`row${i}col10`].patchValue(result);
      }
    }
    else if(i === 5) {
      let qtyValue: any
      const masterPkgType = validate(this.form4.controls['row0col1'].value);
      const masterPkgType1 = validate(this.form4.controls['row1col1'].value);
      if(masterPkgType !== '' || masterPkgType1 !== ''){
        qtyValue = 1;
      }
      else{
        qtyValue = '';
      }    
      this.form4.controls[`row${i}col4`].patchValue(qtyValue);
      const result = '';
      this.form4.controls[`row${i}col5`].patchValue(result);
    } 
    
    else if(i === 6) {      
      const masterPkgType1 = validate(this.form4.controls['row0col1'].value);       
      if(masterPkgType1 !== ''){
        const qtyValue = this.form4.controls[`row${i}col4`].value;
        const unitCost = this.form4.controls[`row${i}col2`].value;
        const laborTime = this.form4.controls[`row${i}col3`].value;
        const result = calculateValue(qtyValue, unitCost, laborRate, laborTime);
        this.form4.controls[`row${i}col5`].patchValue(result);
      }
      else{
        this.form4.controls[`row${i}col5`].patchValue('');
      }     
    }
  }
  const row2col9 = this.form4.controls['row2col9'].value;
  const row3col9 = this.form4.controls['row3col9'].value;
  const row4col9 = this.form4.controls['row4col9'].value;
  const row1col7 = this.form4.controls['row1col7'].value;
  const masterHeat = this.form0.controls['masterheatpallet']?.value;
  const masterFlush = this.form0.controls['masterflushpallet']?.value;
  if((validate(row1col7) !== '' && validate(row1col7) !== 0) && (validate(masterHeat) === '' || validate(masterFlush) === '')){       
    this.masterValidation();
  } 
  else if (validate(row4col9) > 6  || validate(row2col9) > 6 || validate(row3col9) > 6) {   
    this.errorList = []; 
    this.masterRowValidations = true;
    for (let i = 2; i <= 4; i++) {
      const control = this.form4.get(`row${i}col9`);
      control?.setValidators([Validators.max(6)]);
      control?.updateValueAndValidity();
    }
    this.markFormGroupTouched(this.form4);
    this.errorList.push("The value in this cell must be a whole number. If interior packaging is > 6 please reach out to your Packaging Engineer.");
  }
  else if(validate(row4col9) <= 6  || validate(row2col9) <= 6 || validate(row3col9) <= 6){
    this.masterRowValidations = false;
    for (let i = 2; i <= 4; i++) {
      const control = this.form4.get(`row${i}col9`);
      control?.clearValidators();
      control?.updateValueAndValidity();
    } 
    this.close();
  }
  else{
    this.close();
  }
  this.change4Methods(); 
}
change4Methods(){
  this.masterLabourCost(); 
  this.getTotalCaseWeight()
  this.getUnitPerMasterPack();
  this.getTotalMaterialCost();
  this.getTotalLabourCost();    
}
masterDropdown1(materialdescription:any,weight:any,length:any,width:any,height:any,unitCostValue:any,laborTimeValue:any,qty:any,result:any,i:any){
  this.form4.controls[`row${i}col2`].patchValue(materialdescription);
  this.form4.controls[`row${i}col3`].patchValue(weight);
  this.form4.controls[`row${i}col4`].patchValue(length);
  this.form4.controls[`row${i}col5`].patchValue(width);
  this.form4.controls[`row${i}col6`].patchValue(height);
  this.form4.controls[`row${i}col7`].patchValue(unitCostValue); 
  this.form4.controls[`row${i}col8`]?.patchValue(laborTimeValue);
  this.form4.controls[`row${i}col9`].patchValue(qty);
  this.form4.controls[`row${i}col10`].patchValue(result);
}
masterDropdown2(materialDesc:any,laborTime:any,qty:any,res:any,i:any){
  this.form4.controls[`row${i}col2`].patchValue(materialDesc);
  this.form4.controls[`row${i}col8`]?.patchValue(laborTime);
  this.form4.controls[`row${i}col9`].patchValue(qty); 
  this.form4.controls[`row${i}col10`].patchValue(res);
}
palletHidingCond() {
  const keyLabor = this.form0.controls['keyLaborRate'].value;
  let palletHideFlag = true;
  for (let i = 2; i <= 4; i++) {
    const rowCol7 = this.form4.controls[`row${i}col7`]?.value;
    const rowCol8 = this.form4.controls[`row${i}col8`]?.value;
    const rowCol9 = this.form4.controls[`row${i}col9`]?.value;
    if (rowCol7 > 0 && rowCol9 > 0) {
      palletHideFlag = false;
      break;
    } else if (keyLabor > 0 && rowCol8 > 0 && rowCol9 > 0) {
      palletHideFlag = false;
    }
  }
  this.pallethide = palletHideFlag;
}
setGridfour(data:any){
  // dynamic rows patch from row0 to row4
  for (let i = 0; i < 5; i++) {
    this.form4.controls[`row${i}col1`].patchValue(validate(data[i]?.packagingtype));
    this.form4.controls[`row${i}col2`].patchValue(validate(data[i]?.materialdescription));
    this.form4.controls[`row${i}col3`].patchValue(validate(data[i]?.weight));
    this.form4.controls[`row${i}col4`].patchValue(validate(data[i]?.length));
    this.form4.controls[`row${i}col5`].patchValue(validate(data[i]?.width));
    this.form4.controls[`row${i}col6`].patchValue(validate(data[i]?.height));
    this.form4.controls[`row${i}col7`].patchValue(validate(data[i]?.unitcost));
    this.form4.controls[`row${i}col8`].patchValue(validate(data[i]?.labortime));
    this.form4.controls[`row${i}col9`].patchValue(validate(data[i]?.qty));
    this.form4.controls[`row${i}col10`].patchValue(validate(data[i]?.totalcost));
  } 
  // row6
  this.form4.controls['row5col2'].patchValue(0.09) //Material unit cost for row 5
  this.form4.controls['row5col3'].patchValue(3) //Labor Time for row 5
  this.form4.controls['row5col4'].patchValue(validate(data[5]?.qty))
  this.form4.controls['row5col5'].patchValue(validate(data[5]?.totalcost))
  // row7 
  this.form4.controls['row6col2'].patchValue(0.075) //Material unit cost for row 6
  this.form4.controls['row6col3'].patchValue(3) //Labor Time for row 6
  this.form4.controls['row6col4'].patchValue(validate(data[6]?.qty))
  this.form4.controls['row6col5'].patchValue(validate(data[6]?.totalcost))
  this.constants.setNonEditableGrid4(this.masterTable,data);
}
disableCheck4(){
  const formKeys = Object.keys(this.form4.controls) 
  for (const i of formKeys) {
    if (['row0col2', 'row0col3', 'row0col4', 'row0col5', 'row0col6', 'row0col7', 'row0col8', 'row0col9',
      'row1col2', 'row1col8', 'row1col9', 'row5col2', 'row5col3', 'row5col4', 'row5col5', 'row6col2',
      'row6col3', 'row6col4', 'row6col5'].includes(i)) {
      this.form4.controls[i]?.disable();
    } 
    if (i.includes("col10")) {
      this.form4.controls[i]?.disable();
    } else {
      continue;
    }
  }
}
masterDropdown(row:any){
  if(row === 0){
    return this.masterPackageType1;
  }
  else if(row === 1){
    return this.masterPackageType2;
  }
  else{
    return null;
  }
}
clearMasterDropdown1() { 
  const pkgType2 = this.form4.controls['row1col1'].value;
  if(pkgType2 === this.U_MSTERPACK || pkgType2 === this.MU_MSTERPACK || pkgType2 === this.HU_MSTERPACK || pkgType2 === this.TWOU_MASTERPACK || pkgType2 === this.MINIC3_MASTERPACK
  || pkgType2 === this.C3_MASTERPACK || pkgType2 === this.C4_MASTERPACK || pkgType2 === this.WUL_MASTERPACK || pkgType2 === this.BCR_MASTERPACK
  || pkgType2 === this.BOX_LENGTH || pkgType2 === this.LARGE_BOX || pkgType2 === this.CR90_MODULE || pkgType2 === this.smallParcel){
    const formKeys = Object.keys(this.form4.controls);
    for (const i of formKeys) {
      if (i.includes("row0")) {
        this.form4.controls[i].patchValue(null);
      } else {
        continue;
      }
    }
  }
}
//grid5
getitertor5(row :any){
  if(row===6 || row===7){
    return this.columns5a
  }else{
    return this.columns5
  }
}
onInputChange5() {
  const pkgType = this.form5.controls['row0col1'].value;  
  if(pkgType === this.blockStyle3048 || pkgType === this.blockStyle4860 || pkgType === this.blockStyle4890 || pkgType === this.blockStyle48120 || pkgType === this._blockstylewood){   
    this.masterhide = false; //To hide master table
    this.pallethide = true;
  }
  else{
    this.masterHidingCond();
  }
  const laborRate = this.form0.controls['keyLaborRate'].value;  
  this.palletHeavyCaseLabel();
  this.masterHeavyCaseLabel();
  for (let i = 0; i < this.palletTable.length; i++) {
    if(i!==6 && i!==7){
      const pkgType = this.form5.controls['row0col1'].value;
      if(i === 0){
        if(pkgType === ''){
          this.form5.controls[`row${i}col1`]?.patchValue(null);
          this.form5.controls[`row${i}col8`]?.patchValue(null);
          this.form5.controls[`row${i}col9`].patchValue(null);
          const result = null;
          this.form5.controls[`row${i}col10`].patchValue(result);    
        }
        if(pkgType === this.blockStyle3048 || pkgType === this.blockStyle4860 || pkgType === this.blockStyle4890 || pkgType === this.blockStyle48120 || pkgType === this._blockstylewood){
          const laborTimeValue = 70; //Labor time for row 0
          this.form5.controls[`row${i}col8`].patchValue(laborTimeValue);
          this.qty = this.form5.controls[`row${i}col9`].setValue(1);
          const unitCost = this.form5.controls[`row${i}col7`].value;          
          const result = calculateValue(this.qty,unitCost,laborRate,laborTimeValue);
          this.form5.controls[`row${i}col10`].patchValue(result);     
        }
        else{
          const unitCost = this.form5.controls[`row${i}col7`].value;          
          const laborTime = this.form5.controls[`row${i}col8`].value;
          const qty = this.form5.controls[`row${i}col9`].value; 
          const result = calculateValue(qty,unitCost,laborRate, laborTime);
          this.form5.controls[`row${i}col10`].patchValue(result);
        }
      }      
      const unitCost = this.form5.controls[`row${i}col7`].value;  
      const laborTime = this.form5.controls[`row${i}col8`].value;
      const qty = this.form5.controls[`row${i}col9`].value;        
      const result = calculateValue(qty,unitCost,laborRate,laborTime);
      this.form5.controls[`row${i}col10`].patchValue(result);     
    }
    else{
      if (i === 6) {
        let qtyValue: any
        const pkgType = this.form5.controls['row0col1'].value;
          if(pkgType){
            qtyValue = 1
          }else{
            qtyValue = ""
          }
          this.form5.controls[`row${i}col4`].patchValue(qtyValue);
          const unitCost = this.form5.controls[`row${i}col2`].value;
          const laborTime = this.form5.controls[`row${i}col3`].value;
          const result = calculateValue(qtyValue, unitCost, laborRate, laborTime);
          this.form5.controls[`row${i}col5`].patchValue(result);
      }
      if(i === 7){
        const masterPkgType = validate(this.form5.controls['row0col1'].value);       
        if (masterPkgType !== '') {
          const unitCost = validate(this.form5.controls[`row${i}col2`].value);
          const laborTime = validate(this.form5.controls[`row${i}col3`].value);
          const qtyValue = validate(this.form5.controls[`row${i}col4`].value);
          const result = calculateValue(qtyValue, unitCost, laborRate, laborTime);
          this.form5.controls[`row${i}col5`].patchValue(result); 
          
        }
        else{  
          this.form5.controls[`row${i}col5`].patchValue(''); 
        }       
      } 
    }
  }
  const palletHeat = this.form0.controls['palletheatpallet']?.value;
  const palletFlush = this.form0.controls['palletflushpallet']?.value;
  const row0col7 = this.form5.controls['row0col7'].value
  const rowValues = Array.from({ length: 5 }, (_, i) => this.form5.controls[`row${i + 1}col9`].value);

  if ((validate(row0col7) !== '' && validate(row0col7) !== 0) && (validate(palletHeat) === '' || validate(palletFlush) === '')) {
    this.palletValidation();
  } else if (rowValues.some(value => validate(value) > 6)) {
    this.errorList = [];
    this.palletRowValidations = true;
    for (let i = 1; i <= 5; i++) {
      const control = this.form5.get(`row${i}col9`);
      control.setValidators([Validators.max(6)]);
      control.updateValueAndValidity();
    }
    this.markFormGroupTouched(this.form5);
    this.errorList.push("The value in this cell must be a whole number. If interior packaging is > 6 please reach out to your Packaging Engineer.");
  } else if (rowValues.some(value => validate(value) <= 6)) {
    this.palletRowValidations = false;
    for (let i = 1; i <= 5; i++) {
      const control = this.form5.get(`row${i}col9`);
      control.clearValidators();
      control.updateValueAndValidity();
    }
    this.close();
  } else {
    this.close();
  }

  this.getTotalCaseWeight();
  this.palletLabourCost();
  this.getTotalMaterialCost();
  this.getTotalLabourCost();
}
masterHidingCond(){
  const keyLabor = this.form0.controls['keyLaborRate'].value;
  let masterHideFlag = true;
  for (let i = 1; i <= 5; i++) {
    const rowCol7 = this.form5.controls[`row${i}col7`]?.value;
    const rowCol8 = this.form5.controls[`row${i}col8`]?.value;
    const rowCol9 = this.form5.controls[`row${i}col9`]?.value;
    if (rowCol7 > 0 && rowCol9 > 0) {
      masterHideFlag = false;
      break;
    } else if (keyLabor > 0 && rowCol8 > 0 && rowCol9 > 0) {
      masterHideFlag = false;
    }
  }
  this.masterhide = masterHideFlag;
}
setGridfive(data:any){
  // dynamic rows patch from row0 to row5
  for (let i = 0; i < 6; i++) {
    this.form5.controls[`row${i}col1`].patchValue(validate(data[i]?.packagingtype))
    this.form5.controls[`row${i}col2`].patchValue(validate(data[i]?.materialdescription))
    this.form5.controls[`row${i}col3`].patchValue(validate(data[i]?.weight))
    this.form5.controls[`row${i}col4`].patchValue(validate(data[i]?.length))
    this.form5.controls[`row${i}col5`].patchValue(validate(data[i]?.width))
    this.form5.controls[`row${i}col6`].patchValue(validate(data[i]?.height))
    this.form5.controls[`row${i}col7`].patchValue(validate(data[i]?.unitcost))
    this.form5.controls[`row${i}col8`].patchValue(validate(data[i]?.labortime))
    this.form5.controls[`row${i}col9`].patchValue(validate(data[i]?.qty))
    this.form5.controls[`row${i}col10`].patchValue(validate(data[i]?.totalcost))
  } 
  //row7
  this.form5.controls['row6col2'].patchValue(0.09) //Material unit cost for row 6
  this.form5.controls['row6col3'].patchValue(3) //Labor Time for row 6
  this.form5.controls['row6col4'].patchValue(validate(data[6]?.qty))
  this.form5.controls['row6col5'].patchValue(validate(data[6]?.totalcost))
  //row8
  this.form5.controls['row7col2'].patchValue(0.075)  //Material unit cost for row 7
  this.form5.controls['row7col3'].patchValue(3)  //Labor Time for row 7
  this.form5.controls['row7col4'].patchValue(validate(data[7]?.qty))
  this.form5.controls['row7col5'].patchValue(validate(data[7]?.totalcost))
  this.constants.setNonEditableGrid5(this.palletTable,data);
}
disableCheck5(){
  const formKeys = Object.keys(this.form5.controls) 
  for (const i of formKeys) {
    if (['row0col8', 'row0col9','row6col2','row6col3', 'row6col4', 'row6col5','row7col2',
      'row7col3', 'row7col4', 'row7col5'].includes(i)) {
      this.form5.controls[i]?.disable();
    } 
    if (i.includes("col10")) {
      this.form5.controls[i]?.disable();
    } else {
      continue;
    }
  }
}
palletDropdown(row:any){
  if(row === 0){
    return this.palletPackageType;
  }
  else{
    return null;
  }
}
palletValidation(){
  this.errorList = [];
  if (this.pallethide) {
    const palletHeat = this.form0.controls['palletheatpallet']?.value;
    const palletFlush = this.form0.controls['palletflushpallet']?.value;
    const masterPkg = this.form5.controls['row0col7'].value;   
    if ((validate(palletHeat) === '' || validate(palletFlush) === '') && validate(masterPkg) !== 0) {
      this.form5.controls['row0col7'].patchValue(''); //to empty material cost      
      this.form0.get('palletheatpallet')?.setValidators([Validators.required]);
      this.form0.get('palletheatpallet')?.updateValueAndValidity();
      this.form0.get('palletflushpallet')?.setValidators([Validators.required]);
      this.form0.get('palletflushpallet')?.updateValueAndValidity();       
      this.errorList.push("Please Confirm Heat Treat and 4-Way Flush then return to enter cost.");
      this.markFormGroupTouched(this.form0);
    }
    else {
      this.close();
      this.form3.get('palletheatpallet')?.clearValidators(['']);
      this.form3.get('palletheatpallet')?.updateValueAndValidity();
      this.form3.get('palletflushpallet')?.clearValidators(['']);
      this.form3.get('palletflushpallet')?.updateValueAndValidity();     
    }
  }
}
masterValidation(){
  this.errorList=[];
  const masterHeat = this.form0.controls['masterheatpallet']?.value;
  const masterFlush = this.form0.controls['masterflushpallet']?.value;
  const masterPkg = this.form4.controls['row1col7'].value;
  if (this.masterhide) {
    if ((validate(masterHeat) === '' || validate(masterFlush) === '') && validate(masterPkg) !== 0) {
      this.form4.controls['row1col7'].patchValue(''); //to empty material cost
      this.form0.get('masterheatpallet')?.setValidators([Validators.required]);
      this.form0.get('masterheatpallet')?.updateValueAndValidity();
      this.form0.get('masterflushpallet')?.setValidators([Validators.required]);
      this.form0.get('masterflushpallet')?.updateValueAndValidity();
      this.markFormGroupTouched(this.form0);
      this.errorList.push("Please Confirm Heat Treat and 4-Way Flush then return to enter cost.");
    }
    else {
      this.close();
      this.form3.get('masterheatpallet')?.clearValidators(['']);
      this.form3.get('masterheatpallet')?.updateValueAndValidity();
      this.form3.get('masterflushpallet')?.clearValidators(['']);
      this.form3.get('masterflushpallet')?.updateValueAndValidity();
    }
  }
}
//download excel
downloadPPF() {
  if(this.routeParams.external === '1'){
    const data = {
      form1 : {...this.serPpfDes[0],vendate : this.form0.controls['venDate']?.value},
      form2 : this.form1.getRawValue(),
      form3 : this.unitTable,
      form4 : {unitlaborcost:this.unitlaborcost, unitlabortime: this.unitlabortime, unitmaterialcost: this.unitmaterialcost,unitpkgcost: this.unitpkgcost},
      form5 : this.intermediateTable,
      form6 : {interlaborcost:this.interlaborcost, interlabortime: this.interlabortime, intermaterialcost: this.intermaterialcost,interpkgcost: this.interpkgcost},
      form7 : this.masterTable,
      form8 : {masterlaborcost:this.masterlaborcost, masterlabortime: this.masterlabortime, mastermaterialcost: this.mastermaterialcost,masterpkgcost: this.masterpkgcost, masterheatpallet : this.form0.controls['masterheatpallet']?.value, masterflushpallet : this.form0.controls['masterflushpallet']?.value},
      form9 : this.palletTable,
      form10 : {palletlaborcost:this.palletlaborcost, palletlabortime: this.palletlabortime, palletmaterialcost: this.palletmaterialcost,palletpkgcost: this.palletpkgcost, palletheatpallet :  this.form0.controls['palletheatpallet']?.value, palletflushpallet :  this.form0.controls['palletflushpallet']?.value},
      images : this.image
    }
    const docDefinition: any = getPdf(data,this.masterhide,this.pallethide,this.routeParams.partNumber,this.totalUnitPackagingCost);
    pdfMake.createPdf(docDefinition).open();
  }else{
    const body = {
      "PPFCode": this.routeParams?.ppfcode,
      "PPFVer": this.response?.PPFVer,
      "Mode": this.mode,
      "partnumber":this.partNum
    } 
    this.spinner.start();
    this.internalPpfService.ServicePartsPPFDownload(body).subscribe({
      next: (res: any) => {
        const base64 = res?.result.Data;
        const fileName = res?.Filename;
        const file = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${base64}`;
        FileSaver.saveAs(file, fileName);
        this.spinner.stop();
      },
      error: (_err: any) => {
        this.spinner.stop();
      }
    })
  }
}
//dynamic images
getImagePayload(imageNumber: number) {
  let payload: any;  
  if (this.url.includes('tracking-list/send-dept-approval') || this.url.includes('part-list') || this.url.includes('tracking-list/sI-final-dept-approval') ||
    this.url.includes(this.trackingUrl) || this.url.includes('tracking-list/tracking-history')) {
    payload = {
      "PPFCode": this.routeParams?.ppfcode,
      "ImgName": this.imageNames[imageNumber],
      "ImgNum": imageNumber + 1,
      "PPFVer": this.routeParams?.ppfver,
      "ResPPFVer": ''
    }
  } else if(this.routeParams.external === "1" && this.routeParams.viewMode === "1"){
    payload = {
      "PPFCode": this.routeParams?.ppfcode,
      "ImgName": this.imageNames[imageNumber],
      "ImgNum": imageNumber + 1,
      "PPFVer": "-1",
      "ResPPFVer": ''
    }
  }
  else if (this.url.includes('tracking-list/send-ppf') || this.url.includes('dept-approve') || this.url.includes('discripency-list') ||
    (this.url.includes(this.trackingUrl) && this.mode === 2) || (this.url.includes('update-ppf-reason') && this.mode === 2) || this.routeParams.external === "1") {
    payload = {
      "PPFCode": this.routeParams?.ppfcode,
      "ImgName": this.imageNames[imageNumber],
      "ImgNum": imageNumber + 1,
      "PPFVer": '',
      "ResPPFVer": this.response?.PPFVer
    }
  }
  return payload
}
getOnLoadImages(imageNumber: number) {
  const payload = this.getImagePayload(imageNumber) 
  this.internalPpfService.getImageOnload(payload).subscribe({
    next: (res: any) => {
      if (res ?? res.data) {
        const data = res.data;      
        if (data) {
          const image = this.createBase64Image(data);
          this.image[imageNumber] = image;
          this.zoomImage[imageNumber]=`data:image/jpg;base64,${data}`
        } else {
          this.image[imageNumber] = null;
        }
      } else {
        this.image[imageNumber] = null;
      }
    },    
  })
}
openWindow(i:any){
  const data = i;
  const w = window.open('about:blank');
  const image = new Image();
  image.src = data;
  setTimeout(function(){
    w?.document.write('<style> img{max-width:100%; height:auto;}</style>'+image.outerHTML);
  }, 0);
}
handleImageUpload(base64: any, filename: any, number: any){
  if (base64) {
    const body:any = {
      "PPFCode": this.routeParams?.ppfcode,
      "PPFVer": this.routeParams?.ppfver,
      "pType": number,
      "File": {
        "FileName": filename,
        "Data": base64.split(',')[1]
      }
    };
    if(this.routeParams.external === "1"){
      body['PPFVer'] = -1
    }
    this.spinner.start();
    this.internalPpfService.uploadImageOnload(body).subscribe({
      next: (_res: any) => {
        if (_res.status) {
          this.imageNames[number - 1] = _res?.response?.imageName;
          this.image[number - 1] = this.sanitizer.sanitize(SecurityContext.URL,base64);
        }
        this.spinner.stop();
      },
      error: (_err: any) => {
        this.spinner.stop();
      }
    });
  }
}
handleUpload(event: any, imageNumber: any) {
  const file = event.target.files[0];
  const reader: any = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => {
    if (imageNumber) {
      //get file type
      const prefix = 'data:image/';
      const base64Image: any = reader.result
      const base64Parts = base64Image?.split(';base64');
      const contentType = base64Parts[0].substring(prefix.length)
      //get file size
      const fileSizeInBytes = base64Image.length * 0.75;
      const fileSizeInKb = fileSizeInBytes / 1024;
      this.formatError[imageNumber - 1] = ''
      if (['jpeg', 'jpg', 'gif'].includes(contentType)) {
        if (fileSizeInKb < 200) {
          this.handleImageUpload(reader.result, file?.name, imageNumber);
        } else {
          this.formatError[imageNumber - 1] = 'Image size should be less than 200kb'
        }
      } else {
        this.formatError[imageNumber - 1] = 'Image should be of Jpg or Gif format'
      }
    }
  };
  reader.onerror = () => { alert('Error while uploading') }
}
handleDelete(imageNumber: number) {
  this.imageNames[imageNumber - 1] = ""
  this.image[imageNumber - 1] = ""
}
createBase64Image(data: any) {
  return this.sanitizer.sanitize(SecurityContext.URL,`data:image/jpg;base64,${data}`);
}
//save ppf
getSavePPFBody() {
  let updatePPFReasons;
  if (sessionStorage.getItem('updatePPFReasons')) {
    updatePPFReasons = JSON.parse(sessionStorage.getItem('updatePPFReasons') || '');
  }
  const body: any = {
    "screenMode":this.screenMode,
    "ppfcode": Number(this.routeParams?.ppfcode) || null,
    "ppfversion": Number(this.routeParams?.ppfver) || null,
    "date": this.date || null,
    "status": this.form0.controls['status']?.value || null,
    "vendorName": this.form0.controls['venName']?.value || null,
    "vendorCode": this.form0.controls['venCode']?.value || null,
    "hazmat": this.form0.controls['totalDangerousGoods']?.value || null,
    "requesttype": this.form0.controls['requestType']?.value || null,
    "parttype": this.form0.controls['partType']?.value || null,
    "fobcity": this.form0.controls['venFOB']?.value || null,
    "programcode": this.form0.controls['venProject']?.value || null,
    "submittername": this.form0.controls['venSubName']?.value || null,
    "submitteremail": this.form0.controls['venSubEmail']?.value || null,
    "submitterphone": this.form0.controls['venSubPhone']?.value || null,
    "laborrate": this.form0.controls['keyLaborRate']?.value || null,
    "weightofunit": this.form0.controls['keyWeight']?.value || null,
    "piecesperunit": this.form0.controls['keyUnitPackage']?.value || null,
    "unitspereachintermediatepack": this.form0.controls['keyInterPack']?.value || null,
    "packspermasterpack": this.form0.controls['keyMasterpack']?.value || null,
    "totalunitspermasterpack": this.form0.controls['keyPallet']?.value || null,
    "totalunitpackagefill": this.form0.controls['keyPackageFill']?.value ? this.form0.controls['keyPackageFill'].value.slice(0,-1) : null,    
    "totalcaseweight": this.form0.controls['keyCaseWeight']?.value || null,
    "totalunitpackagingcost": this.form0.controls['totalUnitpkgCost']?.value || null,
    "hazmatpackageapproval": this.form0.controls['totalComplianceTM']?.value || null,
    "finalpkgapproval": this.form0.controls['totalPKGEngineer']?.value || null,
    "unitspermasterpackest": this.form0.controls['totalMasterpackEst']?.value || null,
    "totalmaterialcostperunit": this.form0.controls['totalMaterial']?.value || null,
    "totallaborcostperunit": this.form0.controls['totalLabour']?.value || null,
    "vendorcomments": "",
    //unit sub grid
    "unitfloormat1": this.form0.controls['unitfloormat1']?.value || null,
    "unitfloormat2": this.form0.controls['unitfloormat2']?.value || null,
    "unitfloormat3": this.form0.controls['unitfloormat3']?.value || null,
    "unitlaborcost": this.unitlaborcost || null,
    "unitlabtime": this.unitlabortime || null,
    "unitmaterialcost": this.unitmaterialcost || null,
    "unitpkgcost": this.unitpkgcost || null,
    //inter sub grid
    "interlaborcost": this.interlaborcost || null,
    "interlabtime": this.interlabortime || null,
    "intermaterialcost": this.intermaterialcost || null,
    "interpkgcost": this.interpkgcost || null,
    //master sub grid
    "masterheatpallet": this.masterhide ? this.form0.controls['masterheatpallet']?.value : null,
    "masterflushpallet": this.masterhide ? this.form0.controls['masterflushpallet']?.value : null,
    "masterlaborcost": this.masterhide ? this.masterlaborcost : null,
    "masterlabtime": this.masterhide ? this.masterlabortime : null,
    "mastermaterialcost": this.masterhide ? this.mastermaterialcost : null,
    "masterpkgcost": this.masterhide ? this.masterpkgcost : null,
    //pallet sub grid
    "palletheatpallet": this.pallethide ? this.form0.controls['palletheatpallet']?.value : null,
    "palletflushpallet": this.pallethide ? this.form0.controls['palletflushpallet']?.value : null,
    "palletlaborcost": this.pallethide ? this.palletlaborcost : null,
    "palletlabtime": this.pallethide ? this.palletlabortime : null,
    "palletmaterialcost": this.pallethide ? this.palletmaterialcost : null,
    "palletpkgcost": this.pallethide ? this.palletpkgcost : null,
    //images
    "imgbarepart": this.imageNames[0] ? this.imageNames[0] : null,
    "imgpkgdunaroundpart": this.imageNames[1] ? this.imageNames[1] : null,
    "imgintermediatepkgpart": this.imageNames[2] ? this.imageNames[2] : null,
    "imgpalletorcaseloadimg": this.imageNames[3] ? this.imageNames[3] : null,
    "imgunitandintermediatelevel": this.imageNames[4] ? this.imageNames[4] : null,
    "imgotherphoto": this.imageNames[5] ? this.imageNames[5] : null,
    "imgadditionalphoto": this.imageNames[6] ? this.imageNames[6] : null,
    //part grid
    "part": this.payloadArray,
    //row1    
    "unitpkgtype": this.form2.controls['row0col1']?.value || null,
    "unitmaterialdescription": this.form2.controls['row0col2']?.value || null,
    "unitweight": this.form2.controls['row0col3']?.value || null,
    "unitlength": this.form2.controls['row0col4']?.value || null,
    "unitheight": this.form2.controls['row0col6']?.value || null,
    "unitwidth": this.form2.controls['row0col5']?.value || null,
    "unitunitcost": this.form2.controls['row0col7']?.value || null,
    "unitlabortime": this.form2.controls['row0col8']?.value || null,
    "unitqty": this.form2.controls['row0col9']?.value || null,
    "unittotalcost": this.form2.controls['row0col10']?.value || null,
    //row2    
    "unitintpkgapkgtype": this.form2.controls['row1col1']?.value || null,
    "unitintpkgamaterialdescription": this.form2.controls['row1col2']?.value || null,
    "unitintpkgaweight": this.form2.controls['row1col3'].value || null,
    "unitintpkgalength": this.form2.controls['row1col4']?.value || null,
    "unitintpkgaheight": this.form2.controls['row1col6']?.value || null,
    "unitintpkgawidth": this.form2.controls['row1col5']?.value || null,
    "unitintpkgaunitcost": this.form2.controls['row1col7']?.value || null,
    "unitintpkgalabortime": this.form2.controls['row1col8']?.value || null,
    "unitintpkgaqty": this.form2.controls['row1col9']?.value || null,
    "unitintpkgatotalcost": this.form2.controls['row1col10']?.value || null,
    //row3     
    "unitintpkgbpkgtype": this.form2.controls['row2col1']?.value || null,
    "unitintpkgbmaterialdescription": this.form2.controls['row2col2']?.value || null,
    "unitintpkgbweight": this.form2.controls['row2col3']?.value || null,
    "unitintpkgblength": this.form2.controls['row2col4']?.value || null,
    "unitintpkgbheight": this.form2.controls['row2col6']?.value || null,
    "unitintpkgbwidth": this.form2.controls['row2col5']?.value || null,
    "unitintpkgbunitcost": this.form2.controls['row2col7']?.value || null,
    "unitintpkgblabortime": this.form2.controls['row2col8']?.value || null,
    "unitintpkgbqty": this.form2.controls['row2col9']?.value || null,
    "unitintpkgbtotalcost": this.form2.controls['row2col10']?.value || null,
    // row4
    "unitintpkgcpkgtype": this.form2.controls['row3col1']?.value || null,
    "unitintpkgcmaterialdescription": this.form2.controls['row3col2']?.value || null,
    "unitintpkgcweight": this.form2.controls['row3col3']?.value || null,
    "unitintpkgclength": this.form2.controls['row3col4']?.value || null,
    "unitintpkgcheight": this.form2.controls['row3col6']?.value || null,
    "unitintpkgcwidth": this.form2.controls['row3col5']?.value || null,
    "unitintpkgcunitcost": this.form2.controls['row3col7']?.value || null,
    "unitintpkgclabortime": this.form2.controls['row3col8']?.value || null,
    "unitintpkgcqty": this.form2.controls['row3col9']?.value || null,
    "unitintpkgctotalcost": this.form2.controls['row3col10']?.value || null,
    //row5
    "unitintpkgdpkgtype": this.form2.controls['row4col1']?.value || null,
    "unitintpkgdmaterialdescription": this.form2.controls['row4col2']?.value || null,
    "unitintpkgdweight": this.form2.controls['row4col3']?.value || null,
    "unitintpkgdlength": this.form2.controls['row4col4']?.value || null,
    "unitintpkgdheight": this.form2.controls['row4col6']?.value || null,
    "unitintpkgdwidth": this.form2.controls['row4col5']?.value || null,
    "unitintpkgdunitcost": this.form2.controls['row4col7']?.value || null,
    "unitintpkgdlabortime": this.form2.controls['row4col8']?.value || null,
    "unitintpkgdqty": this.form2.controls['row4col9']?.value || null,
    "unitintpkgdtotalcost": this.form2.controls['row4col10']?.value || null,
    //row6
    "unitmiscpkgtype": this.form2.controls['row5col1']?.value || null,
    "unitmiscmaterialdescription": this.form2.controls['row5col2']?.value || null,
    "unitmiscweight": this.form2.controls['row5col3']?.value || null,
    "unitmisclength": this.form2.controls['row5col4']?.value || null,
    "unitmischeight": this.form2.controls['row5col6']?.value || null,
    "unitmiscwidth": this.form2.controls['row5col5']?.value || null,
    "unitmiscunitcost": this.form2.controls['row5col7']?.value || null,
    "unitmisclabortime": this.form2.controls['row5col8']?.value || null,
    "unitmiscqty": this.form2.controls['row5col9']?.value || null,
    "unitmisctotalcost": this.form2.controls['row5col10']?.value || null,
    //row7
    "unitlabelunitcost": this.form2.controls['row6col2']?.value || null,
    "unitlabellabortime": this.form2.controls['row6col3']?.value || null,
    "unitlabelqty": this.form2.controls['row6col4']?.value || null,
    "unitlabeltotalcost": this.form2.controls['row6col5']?.value || null,
    //row8
    "unitheavyunitcost": this.form2.controls['row7col2']?.value || null,
    "unitheavylabortime": this.form2.controls['row7col3']?.value || null,
    "unitheavyqty": this.form2.controls['row7col4']?.value || null,
    "unitheavytotalcost": this.form2.controls['row7col5']?.value || null,
    //row9
    "unithazmatunitcost": this.form2.controls['row8col1']?.value || null,
    "unithazmatlabortime": this.form2.controls['row8col2']?.value || null,
    "unithazmatqty": this.form2.controls['row8col3']?.value || null,
    "unithazmattotalcost": this.form2.controls['row8col4']?.value || null,
    //row10
    "unitinstallunitcost": this.form2.controls['row9col1']?.value || null,
    "unitinstalllabortime": this.form2.controls['row9col2']?.value || null,
    "unitinstallqty": this.form2.controls['row9col3']?.value || null,
    "unitinstalltotalcost": this.form2.controls['row9col4']?.value || null,
    //row11
    "unitfloormatunitcost": this.form2.controls['row10col1']?.value || null,
    "unitfloormatlabortime": this.form2.controls['row10col2']?.value || null,
    "unitfloormatqty": this.form2.controls['row10col3']?.value || null,
    "unitfloormattotalcost": this.form2.controls['row10col4']?.value || null,
    // inter row1
    "interpkgtype": this.form3.controls['row0col1']?.value || null,
    "intermaterialdescription": this.form3.controls['row0col2']?.value || null,
    "interweight": this.form3.controls['row0col3']?.value || null,
    "interlength": this.form3.controls['row0col4']?.value || null,
    "interheight": this.form3.controls['row0col6']?.value || null,
    "interwidth": this.form3.controls['row0col5']?.value || null,
    "interunitcost": this.form3.controls['row0col7']?.value || null,
    "interlabortime": this.form3.controls['row0col8']?.value || null,
    "interqty": this.form3.controls['row0col9']?.value || null,
    "intertotalcost": this.form3.controls['row0col10']?.value || null,
    //row2
    "intermaterialapkgtype": this.form3.controls['row1col1']?.value || null,
    "intermaterialamaterialdescription": this.form3.controls['row1col2']?.value || null,
    "intermaterialaweight": this.form3.controls['row1col3']?.value || null,
    "intermaterialalength": this.form3.controls['row1col4']?.value || null,
    "intermaterialaheight": this.form3.controls['row1col6']?.value || null,
    "intermaterialawidth": this.form3.controls['row1col5']?.value || null,
    "intermaterialaunitcost": this.form3.controls['row1col7']?.value || null,
    "intermaterialalabortime": this.form3.controls['row1col8']?.value || null,
    "intermaterialaqty": this.form3.controls['row1col9']?.value || null,
    "intermaterialatotalcost": this.form3.controls['row1col10']?.value || null,
    //row3
    "intermaterialbpkgtype": this.form3.controls['row2col1']?.value || null,
    "intermaterialbmaterialdescription": this.form3.controls['row2col2']?.value || null,
    "intermaterialbweight": this.form3.controls['row2col3']?.value || null,
    "intermaterialblength": this.form3.controls['row2col4']?.value || null,
    "intermaterialbheight": this.form3.controls['row2col6']?.value || null,
    "intermaterialbwidth": this.form3.controls['row2col5']?.value || null,
    "intermaterialbunitcost": this.form3.controls['row2col7']?.value || null,
    "intermaterialblabortime": this.form3.controls['row2col8']?.value || null,
    "intermaterialbqty": this.form3.controls['row2col9']?.value || null,
    "intermaterialbtotalcost": this.form3.controls['row2col10']?.value || null,
    //row4
    "intermiscpkgtype": this.form3.controls['row3col1']?.value || null,
    "intermiscmaterialdescription": this.form3.controls['row3col2']?.value || null,
    "intermiscweight": this.form3.controls['row3col3']?.value || null,
    "intermisclength": this.form3.controls['row3col4']?.value || null,
    "intermischeight": this.form3.controls['row3col6']?.value || null,
    "intermiscwidth": this.form3.controls['row3col5']?.value || null,
    "intermiscunitcost": this.form3.controls['row3col7']?.value || null,
    "intermisclabortime": this.form3.controls['row3col8']?.value || null,
    "intermiscqty": this.form3.controls['row3col9']?.value || null,
    "intermisctotalcost": this.form3.controls['row3col10']?.value || null,
    //row5
    "interlabelunitcost": this.form3.controls['row4col2']?.value || null,
    "intrerlabellabortime": this.form3.controls['row4col3']?.value || null,
    "interlabelqty": this.form3.controls['row4col4']?.value || null,
    "interlabeltotalcost": this.form3.controls['row4col5']?.value || null,
    //row1
    "returnpkgtype": this.masterhide ? this.form4.controls['row0col1']?.value : null,
    "returnmaterialdescription": this.masterhide ? this.form4.controls['row0col2']?.value : null,
    "returnweight": this.masterhide ? this.form4.controls['row0col3']?.value : null,
    "returnlength": this.masterhide ? this.form4.controls['row0col4']?.value : null,
    "returnheight": this.masterhide ? this.form4.controls['row0col6']?.value : null,
    "returnwidth": this.masterhide ? this.form4.controls['row0col5']?.value : null,
    "returnunitcost": this.masterhide ? this.form4.controls['row0col7']?.value : null,
    "returnlabortime": this.masterhide ? this.form4.controls['row0col8']?.value : null,
    "returnqty": this.masterhide ? this.form4.controls['row0col9']?.value : null,
    "returntotalcost": this.masterhide ? this.form4.controls['row0col10']?.value : null,
    //row2
    "expendpkgtype": this.masterhide ? this.form4.controls['row1col1']?.value : null,
    "expendmaterialdescription": this.masterhide ? this.form4.controls['row1col2']?.value : null,
    "expendweight": this.masterhide ? this.form4.controls['row1col3']?.value : null,
    "expendlength": this.masterhide ? this.form4.controls['row1col4']?.value : null,
    "expendheight": this.masterhide ? this.form4.controls['row1col6']?.value : null,
    "expendwidth": this.masterhide ? this.form4.controls['row1col5']?.value : null,
    "expendunitcost": this.masterhide ? this.form4.controls['row1col7']?.value : null,
    "expendlabortime": this.masterhide ? this.form4.controls['row1col8']?.value : null,
    "expendqty": this.masterhide ? this.form4.controls['row1col9']?.value : null,
    "expendtotalcost": this.masterhide ? this.form4.controls['row1col10']?.value : null,
    //row3
    "materialapkgtype": this.masterhide ? this.form4.controls['row2col1']?.value : null,
    "materialamaterialdescription": this.masterhide ? this.form4.controls['row2col2']?.value : null,
    "materialaweight": this.masterhide ? this.form4.controls['row2col3']?.value : null,
    "materialalength": this.masterhide ? this.form4.controls['row2col4']?.value : null,
    "materialaheight": this.masterhide ? this.form4.controls['row2col6']?.value : null,
    "materialawidth": this.masterhide ? this.form4.controls['row2col5']?.value : null,
    "materialaunitcost": this.masterhide ? this.form4.controls['row2col7']?.value : null,
    "materialalabortime": this.masterhide ? this.form4.controls['row2col8']?.value : null,
    "materialaqty": this.masterhide ? this.form4.controls['row2col9']?.value : null,
    "materialatotalcost": this.masterhide ? this.form4.controls['row2col10']?.value : null,
    //row4
    "materialbpkgtype": this.masterhide ? this.form4.controls['row3col1']?.value : null,
    "materialbmaterialdescription": this.masterhide ? this.form4.controls['row3col2']?.value : null,
    "materialbweight": this.masterhide ? this.form4.controls['row3col3']?.value : null,
    "materialblength": this.masterhide ? this.form4.controls['row3col4']?.value : null,
    "materialbheight": this.masterhide ? this.form4.controls['row3col6']?.value : null,
    "materialbwidth": this.masterhide ? this.form4.controls['row3col5']?.value : null,
    "materialbunitcost": this.masterhide ? this.form4.controls['row3col7']?.value : null,
    "materialblabortime": this.masterhide ? this.form4.controls['row3col8']?.value : null,
    "materialbqty": this.masterhide ? this.form4.controls['row3col9']?.value : null,
    "materialbtotalcost": this.masterhide ? this.form4.controls['row3col10']?.value : null,
    //row5
    "mastermiscpkgtype": this.masterhide ? this.form4.controls['row4col1']?.value : null,
    "mastermiscmaterialdescription": this.masterhide ? this.form4.controls['row4col2']?.value : null,
    "mastermiscweight": this.masterhide ? this.form4.controls['row4col3']?.value : null,
    "mastermisclength": this.masterhide ? this.form4.controls['row4col4']?.value : null,
    "mastermischeight": this.masterhide ? this.form4.controls['row4col6']?.value : null,
    "mastermiscwidth": this.masterhide ? this.form4.controls['row4col5']?.value : null,
    "mastermiscunitcost": this.masterhide ? this.form4.controls['row4col7']?.value : null,
    "mastermisclabortime": this.masterhide ? this.form4.controls['row4col8']?.value : null,
    "mastermiscqty": this.masterhide ? this.form4.controls['row4col9']?.value : null,
    "mastermisctotalcost": this.masterhide ? this.form4.controls['row4col10']?.value : null,
    //row6
    "mastercaseunitcost": this.masterhide ? this.form4.controls['row5col2']?.value : null,
    "mastercaselabortime": this.masterhide ? this.form4.controls['row5col3']?.value : null,
    "mastercaseqty": this.masterhide ? this.form4.controls['row5col4']?.value : null,
    "mastercasetotalcost": this.masterhide ? this.form4.controls['row5col5']?.value : null,
    // row7
    "masterheavycaseunitcost": this.masterhide ? this.form4.controls['row6col2']?.value : null,
    "masterheavycaselabortime": this.masterhide ? this.form4.controls['row6col3']?.value : null,
    "masterheavycaseqty": this.masterhide ? this.form4.controls['row6col4']?.value : null,
    "masterheavycasetotalcost": this.masterhide ? this.form4.controls['row6col5']?.value : null,
    //row1 
    "palletpkgtype": this.pallethide ? this.form5.controls['row0col1']?.value : null,
    "palletmaterialdescription": this.pallethide ? this.form5.controls['row0col2']?.value : null,
    "palletweight": this.pallethide ? this.form5.controls['row0col3']?.value : null,
    "palletlength": this.pallethide ? this.form5.controls['row0col4']?.value : null,
    "palletheight": this.pallethide ? this.form5.controls['row0col6']?.value : null,
    "palletwidth": this.pallethide ? this.form5.controls['row0col5']?.value : null,
    "palletunitcost": this.pallethide ? this.form5.controls['row0col7']?.value : null,
    "palletlabortime": this.pallethide ? this.form5.controls['row0col8']?.value : null,
    "palletqty": this.pallethide ? this.form5.controls['row0col9']?.value : null,
    "pallettotalcost": this.pallethide ? this.form5.controls['row0col10']?.value : null,
    //row2
    "palletmaterialapkgtype": this.pallethide ? this.form5.controls['row1col1']?.value : null,
    "palletmaterialamaterialdescription": this.pallethide ? this.form5.controls['row1col2']?.value : null,
    "palletmaterialaweight": this.pallethide ? this.form5.controls['row1col3']?.value : null,
    "palletmaterialalength": this.pallethide ? this.form5.controls['row1col4']?.value : null,
    "palletmaterialaheight": this.pallethide ? this.form5.controls['row1col6']?.value : null,
    "palletmaterialawidth": this.pallethide ? this.form5.controls['row1col5']?.value : null,
    "palletmaterialaunitcost": this.pallethide ? this.form5.controls['row1col7']?.value : null,
    "palletmaterialalabortime": this.pallethide ? this.form5.controls['row1col8']?.value : null,
    "palletmaterialaqty": this.pallethide ? this.form5.controls['row1col9']?.value : null,
    "palletmaterialatotalcost": this.pallethide ? this.form5.controls['row1col10']?.value : null,
    //row3
    "palletmaterialbpkgtype": this.pallethide ? this.form5.controls['row2col1']?.value : null,
    "palletmaterialbmaterialdescription": this.pallethide ? this.form5.controls['row2col2']?.value : null,
    "palletmaterialbweight": this.pallethide ? this.form5.controls['row2col3']?.value : null,
    "palletmaterialblength": this.pallethide ? this.form5.controls['row2col4']?.value : null,
    "palletmaterialbheight": this.pallethide ? this.form5.controls['row2col6']?.value : null,
    "palletmaterialbwidth": this.pallethide ? this.form5.controls['row2col5']?.value : null,
    "palletmaterialbunitcost": this.pallethide ? this.form5.controls['row2col7']?.value : null,
    "palletmaterialblabortime": this.pallethide ? this.form5.controls['row2col8']?.value : null,
    "palletmaterialbqty": this.pallethide ? this.form5.controls['row2col9']?.value : null,
    "palletmaterialbtotalcost": this.pallethide ? this.form5.controls['row2col10']?.value : null,
    //row4
    "palletmaterialcpkgtype": this.pallethide ? this.form5.controls['row3col1']?.value : null,
    "palletmaterialcmaterialdescription": this.pallethide ? this.form5.controls['row3col2']?.value : null,
    "palletmaterialcweight": this.pallethide ? this.form5.controls['row3col3']?.value : null,
    "palletmaterialclength": this.pallethide ? this.form5.controls['row3col4']?.value : null,
    "palletmaterialcheight": this.pallethide ? this.form5.controls['row3col6']?.value : null,
    "palletmaterialcwidth": this.pallethide ? this.form5.controls['row3col5']?.value : null,
    "palletmaterialcunitcost": this.pallethide ? this.form5.controls['row3col7']?.value : null,
    "palletmaterialclabortime": this.pallethide ? this.form5.controls['row3col8']?.value : null,
    "palletmaterialcqty": this.pallethide ? this.form5.controls['row3col9']?.value : null,
    "palletmaterialctotalcost": this.pallethide ? this.form5.controls['row3col10']?.value : null,
    //row5
    "palletmaterialdpkgtype": this.pallethide ? this.form5.controls['row4col1']?.value : null,
    "palletmaterialdmaterialdescription": this.pallethide ? this.form5.controls['row4col2']?.value : null,
    "palletmaterialdweight": this.pallethide ? this.form5.controls['row4col3']?.value : null,
    "palletmaterialdlength": this.pallethide ? this.form5.controls['row4col4']?.value : null,
    "palletmaterialdheight": this.pallethide ? this.form5.controls['row4col6']?.value : null,
    "palletmaterialdwidth": this.pallethide ? this.form5.controls['row4col5']?.value : null,
    "palletmaterialdunitcost": this.pallethide ? this.form5.controls['row4col7']?.value : null,
    "palletmaterialdlabortime": this.pallethide ? this.form5.controls['row4col8']?.value : null,
    "palletmaterialdqty": this.pallethide ? this.form5.controls['row4col9']?.value : null,
    "palletmaterialdtotalcost": this.pallethide ? this.form5.controls['row4col10']?.value : null,
    //row6
    "palletmiscpkgtype": this.pallethide ? this.form5.controls['row5col1']?.value : null,
    "palletmiscmaterialdescription": this.pallethide ? this.form5.controls['row5col2']?.value : null,
    "palletmiscweight": this.pallethide ? this.form5.controls['row5col3']?.value : null,
    "palletmisclength": this.pallethide ? this.form5.controls['row5col4']?.value : null,
    "palletmischeight": this.pallethide ? this.form5.controls['row5col6']?.value : null,
    "palletmiscwidth": this.pallethide ? this.form5.controls['row5col5']?.value : null,
    "palletmiscunitcost": this.pallethide ? this.form5.controls['row5col7']?.value : null,
    "palletmisclabortime": this.pallethide ? this.form5.controls['row5col8']?.value : null,
    "palletmiscqty": this.pallethide ? this.form5.controls['row5col9']?.value : null,
    "palletmisctotalcost": this.pallethide ? this.form5.controls['row5col10']?.value : null,
    //row7
    "palletcaseunitcost": this.pallethide ? this.form5.controls['row6col2']?.value : null,
    "palletcaselabortime": this.pallethide ? this.form5.controls['row6col3']?.value : null,
    "palletcasecqty": this.pallethide ? this.form5.controls['row6col4']?.value : null,
    "palletcasetotalcost": this.pallethide ? this.form5.controls['row6col5']?.value : null,
    //row8
    "palletheavycaseunitcost": this.pallethide ? this.form5.controls['row7col2']?.value : null,
    "palletheavycaselabortime": this.pallethide ? this.form5.controls['row7col3']?.value : null,
    "palletheavycasecqty": this.pallethide ? this.form5.controls['row7col4']?.value : null,
    "palletheavycasetotalcost": this.pallethide ? this.form5.controls['row7col5']?.value : null,
    // "updateMode": 99,
    "updateMode": this.url.includes('part-list') ? "99" : (this.url.includes('update-ppf-reason') ? "99": null),   
    "url_selWhat": updatePPFReasons?.what || null,
    "url_selwhy": updatePPFReasons?.why || null,
    "url_txtwhat": updatePPFReasons?.whyDesc || null,
    "url_txtwhy": updatePPFReasons?.whyDesc || null,
  }
  if(this.routeParams.external === "1"){
    body['ViewMode'] = this.routeParams.viewMode;
    body['selPartNumber'] = this.routeParams.partNumber;
  }
  return body;
}
//save ppf
savePPF(){
  this.close();
  this.url = this.breadcrumb.breadcrumbs.length > 0 && this.breadcrumb.breadcrumbs.at(-2)?.url;
  this.keyUnitPkg();
  this.KeyLaborRate();
  this.KeyPalletValidation()
  this.partLengthValidate();
  this.keyMasterpackValidation()
  const hasTrueValue = this.partLengthValidator.some(val => val === true);
  if (this.form0.controls['keyLaborRate']?.value && this.form0.controls['totalDangerousGoods']?.value && this.form0.controls['requestType']?.value  && this.form0.controls['partType']?.value &&
    !this.inter && !this.masterRowValidations && !this.palletRowValidations && !this.unitPackageData && !hasTrueValue && !this.unitPackageMat && !this.keyUnitPkgInd && !this.keyLabRateInd && !this.keyPalletInd && !this.keyMasterInd) {
    this.spinner.start();
    this.addPartOnload();
    const body = this.getSavePPFBody();    
    if(this.routeParams.external === "1"){
      this.internalPpfService.ExtServicePartsPPFSave(body).subscribe({
        next: (_res: any) => {
          if(_res.msg === "saved succesfully"){
            this.navigatePrevious();
          }
          this.spinner.stop();
        },
        error: (_err: any) => {
          this.spinner.stop();
        }
      })
    } else{
      this.internalPpfService.ServicePartsPPFSave(body).subscribe({
        next: (_res: any) => {
          if(_res.msg === "saved succesfully"){
            this.navigatePrevious();
          }
          this.spinner.stop();
        },
        error: (_err: any) => {
          this.spinner.stop();
        }
      })
    }
  } 
  else {
    this.markFormGroupTouched(this.form0);
    const requiredFields = [
      { controlName: 'keyLaborRate', message: 'Please enter Hourly Labor Rate' },
      { controlName: 'totalDangerousGoods', message: 'Please enter Haz-Mat or Dangerous Goods(DG)' },
      { controlName: 'requestType', message: 'Please enter Request Type' },
      { controlName: 'partType', message: 'Please enter Part Type' }
    ];
    for (const field of requiredFields) {
      const controlValue = this.form0.controls[field.controlName]?.value;
      if (!controlValue) {
        this.errorList.push(field.message);
      }
    }
  }
}
close(){
  this.errorList=[]
}
//ServicePartsCopyOnload this should be used during the copy implementation
selectPart() {
  const dialogData = this.dialog.open(SelectPartComponent, { width: '100%', data: { serviceBoolean: true } });
  dialogData.afterClosed().subscribe((result: any) => {
    if (result) {
      this.constPartNumber = result.serPPFPartTable[0]?.partnumber;
    }
  })
}
//request dropdown value
onRequestChange() {
  if (!this.removedArray) {
    this.removedArray = [];
  }
  const reqTy = this.form0.controls['requestType']?.value;
  const pType = this.form0.controls['partType']?.value;
  if ((this.requestPartType(reqTy,pType)) && this.hazmatPKG) {
    if (!this.arrayFlag && this.hazmatPKG) {
      this.removedArray = this.unitTable.splice(-3);
    }
    this.arrayFlag = true;   
    this.rows2 = [...Array(8).keys()];
  }
  else if ((this.requestPartType(reqTy,pType)) && !this.hazmatPKG){
      if (this.arrayFlag && !this.hazmatPKG) {
        this.unitTable.push(...this.removedArray);
        this.removedArray = this.unitTable.splice(-2);        
      }
      this.arrayFlag = true;    
      this.rows2 = [...Array(9).keys()];
  }
  else{
    this.arrayFlag = true;   
    if (this.arrayFlag) {
      this.unitTable.push(...this.removedArray);
      this.rows2 = [...Array(11).keys()];
      this.arrayFlag = false;    
    }
  }
}
requestPartType(reqTy:any,pType:any){
  return(
  (reqTy === this.UPDATED_COST || reqTy === this.NEW_PART_NUMBER_ECI || reqTy === this.PACKAGING_KAIZEN
    || reqTy === this.PPR_COUNTERMEASURE || reqTy === this.PART_RESOURCING) && (pType === this.SERVICE_PART || pType === this.CAMPAIGN_PART)
  );
}
//grid2-weight of unit pack part & packagning
 getKeyWeight() {
  let res: any;
  let maxVal: any;
  if (validate(this.form1.controls.row1col4?.value)) {
    const valuesArray: number[] = [
      this.form1.controls.row1col4?.value,
      this.form1.controls.row2col4?.value,
      this.form1.controls.row3col4?.value,
      this.form1.controls.row4col4?.value,
      this.form1.controls.row5col4?.value,
      this.form1.controls.row6col4?.value
    ];
    if (validate(valuesArray)) {
      const filteredArray = valuesArray.filter(value => value !== null && value !== undefined && !isNaN(value));
      if (filteredArray.length > 0) {
        maxVal = Math.max(...filteredArray);
      } else {
        maxVal = 0;
      }
    }
    let sumProduct = 0;
    for (let i = 0; i < this.unitTable.length; i++) {
      const col3Value = this.form2.controls[`row${i}col3`].value;
      const col9Value = this.form2.controls[`row${i}col9`].value;
      if (!isNaN(col3Value) && !isNaN(col9Value)) {
        sumProduct += col3Value * col9Value;
      }
    }
    res = maxVal + sumProduct;
    return res;
  }else{
    return 0;
  }
}
//gird2-units per each intermediate pack
getkeyInterPack() {
  if (validate(this.form0.controls['keyMasterpack']?.value) && validate(this.form0.controls['keyPallet']?.value)) {
    const res = this.form0.controls['keyPallet']?.value / this.form0.controls['keyMasterpack']?.value
    const result = Math.round(res * 10) / 10;
    this.form0.controls['keyInterPack']?.patchValue(result);
  } else {
    this.form0.controls['keyInterPack']?.patchValue(0);
  }
}
//grid2-total case weight
getTotalCaseWeight() {
  const sum1 = validate(this.form0.controls['keyWeight']?.value) * validate(this.form0.controls['keyPallet']?.value)
  let sumProduct1 = 0;
  let sumProduct2 = 0;
  let sumProduct3 = 0;
  for (let i = 0; i < this.intermediateTable.length; i++) {
    const col3Value = this.form3.controls[`row${i}col3`].value;
    const col9Value = this.form3.controls[`row${i}col9`].value;
    if (!isNaN(col3Value) && !isNaN(col9Value)) {
      sumProduct1 += col3Value * col9Value;
    }
  }
  for (let i = 0; i < this.masterTable.length; i++) {
    const col3Value = this.form4.controls[`row${i}col3`].value;
    const col9Value = this.form4.controls[`row${i}col9`].value;
    if (!isNaN(col3Value) && !isNaN(col9Value)) {
      sumProduct2 += col3Value * col9Value;
    }
  }
  for (let i = 0; i < this.palletTable.length; i++) {
    const col3Value = this.form5.controls[`row${i}col3`].value;
    const col9Value = this.form5.controls[`row${i}col9`].value;
    if (!isNaN(col3Value) && !isNaN(col9Value)) {
      sumProduct3 += col3Value * col9Value;
    }
  }
  let res:any
  if(validate(this.form0.controls['keyMasterpack'].value)!==''){
  res = (sum1 + (this.form0.controls['keyMasterpack'].value * (sumProduct1) + sumProduct2 + sumProduct3)) / 1000;
  }
  this.form0.controls['keyCaseWeight']?.patchValue(res);
}
//grid2-total unit package fill %
getPackageFill() {
  if (this.form2.controls['row0col1'].value === this.barePart || this.form2.controls['row0col1'].value === this.tubeBag || this.form2.controls['row0col1'].value === this.polyBag) {
    this.form0.controls['keyPackageFill']?.patchValue((0) + '%')
  } else {
    const row0col4 = validate(this.form2.controls['row0col4'].value)
    if (row0col4 === '') {
      this.form0.controls['keyPackageFill']?.patchValue((0) + '%')
    } else {
      let s1 = 0; let s2 = 0; let s3 = 0; let c1 = 0; let c2 = 0; let c3 = 0;      
      if (validate(this.data) !== '') {
        for (let i = 1; i < this.data.length; i++) {
          if (validate(this.form1.controls[`row${i}col5`].value)) {
            s1 += Number(this.form1.controls[`row${i}col5`].value);
            c1++;
          }
          if (validate(this.form1.controls[`row${i}col6`].value)) {
            s2 += Number(this.form1.controls[`row${i}col6`].value);
            c2++;
          }
          if (validate(this.form1.controls[`row${i}col7`].value)) {
            s3 += Number(this.form1.controls[`row${i}col7`].value)
            c3++;
          }
        }
        const avg1 = s1 / c1;
        const avg2 = s2 / c2;
        const avg3 = s3 / c3;
        const res1 = validate(avg1 * avg2 * avg3) * this.form0.controls['keyUnitPackage'].value
 
        const control = this.form2.controls['row0col6'];
        const res2 = control?.value * this.form2.controls['row0col5']?.value * this.form2.controls['row0col4']?.value;
        (res1 !== 0 && res2 !== 0) ? this.form0.controls['keyPackageFill']?.patchValue(((res1 / res2)*100).toFixed(2) + '%') : this.form0.controls['keyPackageFill']?.patchValue((0) + '%')
      }
    }
  }
}
//grid3-total labour cost per unit
getTotalLabourCost() {
  if (validate(this.form0.controls['keyPallet']?.value) && this.form0.controls['keyPallet']?.value !== 0) {
    const res: any = parseFloat(getCI(this.form0.controls['keyPallet']?.value, this.form0.controls['keyUnitPackage']?.value, this.form0.controls['keyMasterpack']?.value, this.unitlaborcost, this.interlaborcost, this.masterhide?this.masterlaborcost:0, this.pallethide?this.palletlaborcost:0)) / this.form0.controls['keyPallet']?.value;
    this.form0.controls['totalLabour']?.patchValue(res?.toFixed(4));
  }
}
//grid3-total material cost per unit
getTotalMaterialCost() {
  if (validate(this.form0.controls['keyPallet']?.value) && (this.form0.controls['keyPallet']?.value) !== 0) { 
    const res: any = parseFloat(getCI(this.form0.controls['keyPallet']?.value, this.form0.controls['keyUnitPackage']?.value, this.form0.controls['keyMasterpack']?.value, this.unitmaterialcost, this.intermaterialcost,this.masterhide? this.mastermaterialcost:0,this.pallethide?this.palletmaterialcost:0)) / this.form0.controls['keyPallet']?.value;
    this.form0.controls['totalMaterial']?.patchValue(res?.toFixed(4));
  }
}
//grid3-total unit packing cost(per piece)
getTotalPkgCost() {
  if (validate(this.form0.controls['keyPallet']?.value) && this.form0.controls['keyPallet']?.value !== 0) {
    const res: any = parseFloat(getCI(this.form0.controls['keyPallet']?.value, this.form0.controls['keyUnitPackage']?.value, this.form0.controls['keyMasterpack']?.value, this.unitpkgcost,this.interpkgcost,this.masterhide?this.masterpkgcost:0, this.pallethide?this.palletpkgcost:0)) / this.form0.controls['keyPallet']?.value;
    if (!isNaN(res) && typeof res === 'number' && validate(res)!== '') {
      this.totalUnitPackagingCost = '$' + parseFloat(res?.toFixed(4));
    } else {
      this.totalUnitPackagingCost = 'Error Review Before Sending';
    }
  } else {
    this.totalUnitPackagingCost = 'Error Review Before Sending'
  }
}
//grid3-units per masterpack estimate
getUnitPerMasterPack() {
  if (this.form4.controls['row0col1'].value==='') {
    if(this.form4.controls['row1col1'].value==='') {
      this.form0.controls['totalMasterpackEst']?.patchValue('NO ESTIMATE');
    }else{
      const value = this.getBQ65();
      const roundedValue = this.avg()!==0?Math.round(0.8 * value /this.avg()):0;      
      this.form0.controls['totalMasterpackEst']?.patchValue(roundedValue);
    }
  }else{
    const value = this.getBQ64();
    const roundedValue = this.avg()!==0?Math.round(0.8 * value /this.avg()):0;
    this.form0.controls['totalMasterpackEst']?.patchValue(roundedValue);
  }
}
getBQ65() {
  const res:any= convertAndCheck(this.form4.controls['row1col4']?.value, this.form4.controls['row1col5']?.value, this.form4.controls['row1col6']?.value)
return res*0.8;
}
getBQ64() {
  return convertCubicMillimeterToCubicMeter(this.form4.controls['row0col4'].value, this.form4.controls['row0col5'].value, this.form4.controls['row0col6'].value);
}
avg() {
  let sum = 0; let avg = 0;
  if(this.data.length>0){
  for (let i = 0; i < this.data.length; i++) {
    if(this.form4.controls[`row${i}col9`]?.value){
    sum += this.form4.controls[`row${i}col9`]?.value;
    }
  }
  avg = sum / this.data.length;
  return avg;
}else{
  return 0;
}
}
 //sub grids totals
  unitLabourCost() {
    const laborRate = validate(this.form0.controls['keyLaborRate']?.value);
    const M0 = validate(this.form2.controls['row0col7']?.value);
    const M1 = validate(this.form2.controls['row1col7']?.value);
    const M2 = validate(this.form2.controls['row2col7']?.value);
    const M3 = validate(this.form2.controls['row3col7']?.value);
    const M4 = validate(this.form2.controls['row4col7']?.value);
    const M5 = validate(this.form2.controls['row5col7']?.value);
    const M6 = validate(this.form2.controls['row6col2']?.value);
    const M7 = validate(this.form2.controls['row7col2']?.value);
    const M8 = validate(this.form2.controls['row8col1']?.value);
    const M9 = validate(this.form2.controls['row9col1']?.value);
    const M10 = validate(this.form2.controls['row10col1']?.value);
    const L0 = validate(this.form2.controls['row0col8']?.value);
    const q0 = validate(this.form2.controls['row0col9']?.value);
    const L1 = validate(this.form2.controls['row1col8']?.value);
    const q1 = validate(this.form2.controls['row1col9']?.value);
    const L2 = validate(this.form2.controls['row2col8']?.value);
    const q2 = validate(this.form2.controls['row2col9']?.value);
    const L3 = validate(this.form2.controls['row3col8']?.value);
    const q3 = validate(this.form2.controls['row3col9']?.value);
    const L4 = validate(this.form2.controls['row4col8']?.value);
    const q4 = validate(this.form2.controls['row4col9']?.value);
    const L5 = validate(this.form2.controls['row5col8']?.value);
    const q5 = validate(this.form2.controls['row5col9']?.value);
    const L6 = validate(this.form2.controls['row6col3']?.value);
    const q6 = validate(this.form2.controls['row6col4']?.value);
    const L7 = validate(this.form2.controls['row7col3']?.value);
    const q7 = validate(this.form2.controls['row7col4']?.value);
    const L8 = validate(this.form2.controls['row8col2']?.value);
    const q8 = validate(this.form2.controls['row8col3']?.value);
    const L9 = validate(this.form2.controls['row9col2']?.value);
    const q9 = validate(this.form2.controls['row9col3']?.value);
    const L10 = validate(this.form2.controls['row10col2']?.value);
    const q10 = validate(this.form2.controls['row10col3']?.value);
    this.unitlabortime = ((L0 * q0) + (L1 * q1) + (L2 * q2) + (L3 * q3) + (L4 * q4) + (L5 * q5) + (L6 * q6) + (L7 * q7) + (L8 * q8) + (L9 * q9) + (L10 * q10))
    this.unitmaterialcost = Number((M0 * q0) + (M1 * q1) + (M2 * q2) + (M3 * q3) + (M4 * q4) + (M5 * q5) + (M6 * q6) + (M7 * q7) + (M8 * q8) + (M9 * q9) + (M10 * q10))?.toFixed(4)
    this.unitlaborcost = ((this.unitlabortime * laborRate) / 3600)?.toFixed(4);
    this.unitpkgcost = (Number(this.unitlaborcost) + Number(this.unitmaterialcost))?.toFixed(4);
    this.getTotalPkgCost()
  }
  intermediateLabourCost() {
    const laborRate = validate(this.form0.controls['keyLaborRate']?.value);
    const M0 = validate(this.form3.controls['row0col7']?.value);
    const M1 = validate(this.form3.controls['row1col7']?.value);
    const M2 = validate(this.form3.controls['row2col7']?.value);
    const M3 = validate(this.form3.controls['row3col7']?.value);
    const M4 = validate(this.form3.controls['row4col2']?.value);
    const L0 = validate(this.form3.controls['row0col8']?.value);
    const q0 = validate(this.form3.controls['row0col9']?.value);
    const L1 = validate(this.form3.controls['row1col8']?.value);
    const q1 = validate(this.form3.controls['row1col9']?.value);
    const L2 = validate(this.form3.controls['row2col8']?.value);
    const q2 = validate(this.form3.controls['row2col9']?.value);
    const L3 = validate(this.form3.controls['row3col8']?.value);
    const q3 = validate(this.form3.controls['row3col9']?.value);
    const L4 = validate(this.form3.controls['row4col3']?.value);
    const q4 = validate(this.form3.controls['row4col4']?.value);
    this.interlabortime = ((L0 * q0) + (L1 * q1) + (L2 * q2) + (L3 * q3) + (L4 * q4))
    this.intermaterialcost = Number((M0 * q0) + (M1 * q1) + (M2 * q2) + (M3 * q3) + (M4 * q4))?.toFixed(4)
    this.interlaborcost = ((this.interlabortime * laborRate) / 3600)?.toFixed(4);
    this.interpkgcost = (Number(this.interlaborcost) + Number(this.intermaterialcost))?.toFixed(4);
    this.getTotalPkgCost()
  }
  masterLabourCost() {
    const laborRate = validate(this.form0.controls['keyLaborRate']?.value);
    const masterdrop1 = validate(this.form4.controls['row0col1']?.value);
    const masterdrop2 = validate(this.form4.controls['row1col1']?.value);
    let M0= 0; let L0 = 0 ;  let q0 = 0; let M1 = 0; let L1= 0; let q1 = 0;
    if(masterdrop1 !=='' && masterdrop2 === ''){
      M0 = validate(this.form4.controls['row0col7']?.value);
      L0 = validate(this.form4.controls['row0col8']?.value);
      q0 = validate(this.form4.controls['row0col9']?.value);
    }else if(masterdrop2 !== ''){
      M1 = validate(this.form4.controls['row1col7']?.value);
      L1 = validate(this.form4.controls['row1col8']?.value);
      q1 = validate(this.form4.controls['row1col9']?.value);
    }
    const M2 = validate(this.form4.controls['row2col7']?.value);
    const M3 = validate(this.form4.controls['row3col7']?.value);
    const M4 = validate(this.form4.controls['row4col7']?.value);
    const M5 = validate(this.form4.controls['row5col2']?.value);
    const M6 = validate(this.form4.controls['row6col2']?.value);
    const L2 = validate(this.form4.controls['row2col8']?.value);
    const q2 = validate(this.form4.controls['row2col9']?.value);
    const L3 = validate(this.form4.controls['row3col8']?.value);
    const q3 = validate(this.form4.controls['row3col9']?.value);
    const L4 = validate(this.form4.controls['row4col8']?.value);
    const q4 = validate(this.form4.controls['row4col9']?.value);
    const L5 = validate(this.form4.controls['row5col3']?.value);
    const q5 = validate(this.form4.controls['row5col4']?.value);
    const L6 = validate(this.form4.controls['row6col3']?.value);
    const q6 = validate(this.form4.controls['row6col4']?.value);
    this.masterlabortime = ((L0 * q0) + (L1 * q1) + (L2 * q2) + (L3 * q3) + (L4 * q4) + (L5 * q5) + (L6 * q6))
    this.mastermaterialcost = Number((M0 * q0) + (M1 * q1) + (M2 * q2) + (M3 * q3) + (M4 * q4) + (M5 * q5) + (M6 * q6))?.toFixed(4)
    this.masterlaborcost = ((this.masterlabortime * laborRate) / 3600)?.toFixed(4);
    this.masterpkgcost = (Number(this.masterlaborcost) + Number(this.mastermaterialcost))?.toFixed(4);
    this.getTotalPkgCost()
  }
  palletLabourCost() {
    const laborRate = validate(this.form0.controls['keyLaborRate']?.value);
    const M0 = validate(this.form5.controls['row0col7']?.value);
    const M1 = validate(this.form5.controls['row1col7']?.value);
    const M2 = validate(this.form5.controls['row2col7']?.value);
    const M3 = validate(this.form5.controls['row3col7']?.value);
    const M4 = validate(this.form5.controls['row4col7']?.value);
    const M5 = validate(this.form5.controls['row5col7']?.value);
    const M6 = validate(this.form5.controls['row6col2']?.value);
    const M7 = validate(this.form5.controls['row7col2']?.value);
    const L0 = validate(this.form5.controls['row0col8']?.value);
    const q0 = validate(this.form5.controls['row0col9']?.value);
    const L1 = validate(this.form5.controls['row1col8']?.value);
    const q1 = validate(this.form5.controls['row1col9']?.value);
    const L2 = validate(this.form5.controls['row2col8']?.value);
    const q2 = validate(this.form5.controls['row2col9']?.value);
    const L3 = validate(this.form5.controls['row3col8']?.value);
    const q3 = validate(this.form5.controls['row3col9']?.value);
    const L4 = validate(this.form5.controls['row4col8']?.value);
    const q4 = validate(this.form5.controls['row4col9']?.value);
    const L5 = validate(this.form5.controls['row5col8']?.value);
    const q5 = validate(this.form5.controls['row5col9']?.value);
    const L6 = validate(this.form5.controls['row6col3']?.value);
    const q6 = validate(this.form5.controls['row6col4']?.value);
    const L7 = validate(this.form5.controls['row7col3']?.value);
    const q7 = validate(this.form5.controls['row7col4']?.value);
    this.palletlabortime = ((L0 * q0) + (L1 * q1) + (L2 * q2) + (L3 * q3) + (L4 * q4) + (L5 * q5) + (L6 * q6) + (L7 * q7))
    this.palletmaterialcost = Number((M0 * q0) + (M1 * q1) + (M2 * q2) + (M3 * q3) + (M4 * q4) + (M5 * q5) + (M6 * q6) + (M7 * q7))?.toFixed(4)
    this.palletlaborcost = ((this.palletlabortime * laborRate) / 3600)?.toFixed(4);
    this.palletpkgcost = (Number(this.palletlaborcost) + Number(this.palletmaterialcost))?.toFixed(4);
    this.getTotalPkgCost()
  }
  unitHazMatLabel() {
    let maxVal: any;
    const valuesArray: number[] = [];
    for (let i = 1; i < this.data.length; i++) {
      if (this.form1.controls[`row${i}col4`].value) {
        valuesArray.push(validate(this.form1.controls[`row${i}col4`].value));
      }
    }
    if (validate(valuesArray)) {
      const filteredArray = valuesArray.filter(value => value !== null && value !== undefined && !isNaN(value));
      if (filteredArray.length > 0) {
        maxVal = Math.max(...filteredArray);
      }
    } else {
      maxVal = 0;
    }
    const valuesArray1: number[] = [];
    const valuesArray2: number[] = [];
    for (let i = 0; i < 6; i++) {
      if (validate(this.form2.controls[`row${i}col3`].value)) {
        valuesArray1.push(this.form2.controls[`row${i}col3`].value);
      }
      if (validate(this.form2.controls[`row${i}col9`].value)) {
        valuesArray2.push(this.form2.controls[`row${i}col9`].value)
      }
    }
    const sumProd = sumProduct(valuesArray1, valuesArray2);
    if ((maxVal + sumProd) > 22700) {
      this.form2.controls['row7col4'].patchValue(1)
    } else {
      this.form2.controls['row7col4'].patchValue('')
    }
  }
  masterHeavyCaseLabel() {
    const J9 = this.form0.controls['keyMasterpack']?.value;
    const J10 = this.form0.controls['keyPallet']?.value;
    let maxVal: any;
    const valuesArray: number[] = [];
    for (let i = 1; i < this.data.length; i++) {
      if (this.form1.controls[`row${i}col4`]?.value) {
        valuesArray.push(validate(this.form1.controls[`row${i}col4`]?.value));
      }
    }
    if (validate(valuesArray)) {
      const filteredArray = valuesArray.filter(value => value !== null && value !== undefined && !isNaN(value));
      if (filteredArray.length > 0) {
        maxVal = Math.max(...filteredArray);
      }
    } else {
      maxVal = 0;
    }
    const valuesArray1: number[] = [];
    const valuesArray2: number[] = [];
    for (let i = 0; i < 6; i++) {
      if (validate(this.form2.controls[`row${i}col3`]?.value)) {
        valuesArray1.push(this.form2.controls[`row${i}col3`]?.value);
      }
      if (validate(this.form2.controls[`row${i}col9`]?.value)) {
        valuesArray2.push(this.form2.controls[`row${i}col9`]?.value)
      }
    }
    const sumProd = sumProduct(valuesArray1, valuesArray2);

    const valuesArray3: number[] = [];
    const valuesArray4: number[] = [];
    for (let i = 0; i < 5; i++) {
      if (validate(this.form3.controls[`row${i}col3`]?.value)) {
        valuesArray3.push(this.form3.controls[`row${i}col3`]?.value);
      }
      if (validate(this.form3.controls[`row${i}col9`]?.value)) {
        valuesArray4.push(this.form3.controls[`row${i}col9`]?.value)
      }
    }
    const sumProd1 = sumProduct(valuesArray3, valuesArray4);

    const valuesArray5: number[] = [];
    const valuesArray6: number[] = [];
    for (let i = 0; i < 5; i++) {
      if (validate(this.form4.controls[`row${i}col3`].value)) {
        valuesArray5.push(this.form4.controls[`row${i}col3`].value);
      }
      if (validate(this.form4.controls[`row${i}col9`].value)) {
        valuesArray6.push(this.form4.controls[`row${i}col9`].value)
      }
    }
    const sumProd2 = sumProduct(valuesArray5, valuesArray6);
    const masterPkgType1 = validate(this.form4.controls['row1col1'].value);
    const Q83 = this.form5.controls['row7col4']?.value
    if (masterPkgType1 !== '') {  
      this.form4.controls['row6col4']?.patchValue('')
    } else if (Q83 === 1) {      
      this.form4.controls['row6col4']?.patchValue("")
    } else {
      // Calculate the expressions
      const expr1 = validate(sumProd2);
      const expr2 = validate(sumProd1) * J9;
      const expr3 = validate(maxVal) * J10;
      const expr4 = validate(sumProd) * J10;
      // Check the con3it3on
      if (Number(expr1 + expr2 + expr3 + expr4) > 227500) {
        this.form4.controls['row6col4']?.patchValue(1)
      } else {
        this.form4.controls['row6col4']?.patchValue("")
      }
    }

  }
  palletHeavyCaseLabel() {
    const J9 = this.form0.controls['keyMasterpack']?.value;
    const J10 = this.form0.controls['keyPallet']?.value;
    let maxVal: any;
    const valuesArray: number[] = [];
    for (let i = 1; i < this.data.length; i++) {
      if (this.form1.controls[`row${i}col4`]?.value) {
        valuesArray.push(validate(this.form1.controls[`row${i}col4`]?.value));
      }
    }
    if (validate(valuesArray)) {
      const filteredArray = valuesArray.filter(value => value !== null && value !== undefined && !isNaN(value));
      if (filteredArray.length > 0) {
        maxVal = Math.max(...filteredArray);
      }
    } else {
      maxVal = 0;
    }
    const valuesArray1: number[] = [];
    const valuesArray2: number[] = [];
    for (let i = 0; i < 6; i++) {
      if (validate(this.form2.controls[`row${i}col3`]?.value)) {
        valuesArray1.push(this.form2.controls[`row${i}col3`]?.value);
      }
      if (validate(this.form2.controls[`row${i}col9`]?.value)) {
        valuesArray2.push(this.form2.controls[`row${i}col9`]?.value)
      }
    }
    const sumProd = sumProduct(valuesArray1, valuesArray2);

    const valuesArray3: number[] = [];
    const valuesArray4: number[] = [];
    for (let i = 0; i < 5; i++) {
      if (validate(this.form3.controls[`row${i}col3`]?.value)) {
        valuesArray3.push(this.form3.controls[`row${i}col3`]?.value);
      }
      if (validate(this.form3.controls[`row${i}col9`]?.value)) {
        valuesArray4.push(this.form3.controls[`row${i}col9`]?.value)
      }
    }
    const sumProd1 = sumProduct(valuesArray3, valuesArray4);
    const valuesArray5: number[] = [];
    const valuesArray6: number[] = [];
    for (let i = 0; i < 6; i++) {
      if (validate(this.form5.controls[`row${i}col3`].value)) {
        valuesArray5.push(this.form5.controls[`row${i}col3`].value);
      }
      if (validate(this.form5.controls[`row${i}col9`].value)) {
        valuesArray6.push(this.form5.controls[`row${i}col9`].value)
      }
    }
    const sumProd2 = sumProduct(valuesArray5, valuesArray6);    
    const masterPkgType = validate(this.form5.controls['row0col1'].value);
    if (masterPkgType === '') {
      this.form5.controls['row7col4']?.patchValue('')
    } else {
      // Calculate the expressions
      const expr1 = validate(sumProd2);
      const expr2 = validate(sumProd1) * J9;
      const expr3 = validate(maxVal) * J10;
      const expr4 = validate(sumProd) * J10;
      // Check the con3it3on
      if (Number(expr1 + expr2 + expr3 + expr4) > 227500) {
        this.form5.controls['row7col4']?.patchValue(1)
      } else {
        this.form5.controls['row7col4']?.patchValue("")
      }
    }

  }
  keyUnitPkg(){    
    this.markFormGroupTouched(this.form0)
    if(this.form0.controls['keyUnitPackage'].value>20){
      this.keyUnitPkgInd=true;
      this.errorList.push("Please enter 'Pieces per unit package' < = 20")
    }else{
      this.keyUnitPkgInd = false;
    }
  }
  //NaN validation
  numValidatorForm(event: KeyboardEvent,ind:any,keyInd:any, rowStart?: any, rowEnd?: any, colStart?: any, colEnd?: any) {
    const target = event.target as HTMLInputElement;
    const enteredKey = event.key;
    const position = this.getPositionInTable(target)
    if(ind && (position.rowIndex===8 ||position.rowIndex===9 || position.rowIndex===10) ){
      colStart= position.colIndex
    }
    if ((position.rowIndex >= rowStart && position.rowIndex <= rowEnd && position.colIndex >= colStart && position.colIndex <= colEnd) || keyInd) {
      if (isNaN(Number(enteredKey)) && enteredKey !== '.' && enteredKey !== 'Backspace' && enteredKey!=='Tab') {
        event.preventDefault();
        const numericValue = parseFloat(target.value);
        target.value = isNaN(numericValue) ? '' : String(numericValue);
        return;
      }
    }
  }
  getPositionInTable(inputElement: HTMLInputElement): { rowIndex: number, colIndex: number } {
    let rowIndex = -1;
    let colIndex = -1;
    const rowElement = inputElement.closest('tr');
    if (rowElement) {
      rowIndex = Array.from(rowElement.parentElement?.children || []).indexOf(rowElement);
    }
    const cellElement = inputElement.closest('td');
    if (cellElement) {
      colIndex = Array.from(cellElement.parentElement?.children || []).indexOf(cellElement);
    }
    return { rowIndex, colIndex };
  }
  KeyPalletValidation() {   
    this.markFormGroupTouched(this.form0)
    if (this.form0.controls['keyPallet'].value > 10000) {
      this.errorList.push("Please reachout to assigned Packaging Engineer if assigned value >10000 pieces in masterpack or pallet")
      this.keyPalletInd = true;
    }
    else{
      this.keyPalletInd = false;
    }
  }
  keyPalletfun(){
  this.keyMasterPackChange();
  this.onInputChange4();
  this.KeyPalletValidation();
  }
  KeyLaborRate(){    
    const control = this.form0.get(`keyLaborRate`);
    this.markFormGroupTouched(this.form0)
    if(this.form0.controls['keyLaborRate'].value>=36){
      this.keyLabRateInd=true;
      this.errorList.push("Please review Labor Rate with assigned Packaging Engineer")
      control?.setValidators([Validators.max(35)]);
      control?.updateValueAndValidity();
    }else{
      this.keyLabRateInd = false;
       control?.clearValidators(['']);
       control?.updateValueAndValidity();
    }
  }
  keyMasterpackValidation() {    
    this.markFormGroupTouched(this.form0)
    if (this.form0.controls['keyMasterpack'].value > 200) {
      this.errorList.push("Please enter value <= 200")
      this.keyMasterInd = true;
    }
    else{
      this.keyMasterInd = false;
    }
  }
  keyMasterPackFun(){
    this.keyMasterPackChange();
    this.onInputChange3();
    this.onInputChange4();
    this.keyMasterpackValidation();
  }
}