export const environment = {
	production: false,	
//	EXTERNAL: 'https://external-service.api.dev.vpacs.cloud.toyota.com/api/', // external pg
//	INTERNAL:'https://internal-service.api.dev.vpacs.cloud.toyota.com/api/', // internal vpacs
	//MASTERCOST:'mastercost-service.api.dev.vpacs.cloud.toyota.com/api',// master cost
 // MASTERCOST:'https://mastercost-service.api.dev.vpacs.cloud.toyota.com/api/',
	//TRACKING:'https://tracking-service.api.dev.vpacs.cloud.toyota.com/api/', // tracking
  MASTERCOST:'https://xn22miwkcg.execute-api.us-west-2.amazonaws.com/dev/api/', // mastercost
  TRACKING:'https://f221numz8j.execute-api.us-west-2.amazonaws.com/dev/api/', // tracking
  INTERNAL:'https://ibqazaphra.execute-api.us-west-2.amazonaws.com/dev/api/',//new
  EXTERNAL:'https://dvgl1iidzb.execute-api.us-west-2.amazonaws.com/dev/api/',//  external 
	TENANT_ID: 'https://login.microsoftonline.com/9107b728-2166-4e5d-8d13-d1ffdf0351ef',
	CLIENT_ID: '777ab753-7eb1-4e33-8ff9-b39d644f32b3',
	REDIRECT_URL: 'https://devservice.vpacs.cloud.toyota.com',
	VPACSHOMEURL: 'https://devservice.vpacs.cloud.toyota.com',
	API_CALL: 'cIJVafXSx68BSZ5WfvF4U6DQ30uJIKmM2mgCnEL8'
};
