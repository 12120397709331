export const environment = {
	production: false,	
  MASTERCOST:'https://mastercost.api.dev.vpacs.cloud.toyota.com/api/',
  TRACKING:'https://tracking.api.dev.vpacs.cloud.toyota.com/api/', 
  INTERNAL:'https://internal.api.dev.vpacs.cloud.toyota.com/api/',
  EXTERNAL:'https://external-int.api.dev.vpacs.cloud.toyota.com/api/', 
	TENANT_ID: 'https://login.microsoftonline.com/9107b728-2166-4e5d-8d13-d1ffdf0351ef',
	CLIENT_ID: '777ab753-7eb1-4e33-8ff9-b39d644f32b3',
	REDIRECT_URL: 'https://dev.vpacs.cloud.toyota.com',
	VPACSHOMEURL: 'https://dev.vpacs.cloud.toyota.com',
	API_CALL: 'cIJVafXSx68BSZ5WfvF4U6DQ30uJIKmM2mgCnEL8'
};
