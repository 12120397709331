import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DoPkgAuthorizationService } from 'src/app/services/do-pkg-authorization.service';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { DeliveryTargetComponent } from './delivery-target/delivery-target.component';
(<any>pdfMake).vfs = pdfFonts?.pdfMake?.vfs;

@Component({
  selector: 'app-do-pkg-authorization',
  templateUrl: './do-pkg-authorization.component.html',
  styleUrls: ['./do-pkg-authorization.component.scss'],
})
export class DoPkgAuthorizationComponent implements OnInit {
  dateFormat = 'MM/dd/yyyy'
  pkgAuthorizationUrl = '/pkg-authorization'
  dopkgData: any;
  htmlData: any;
  reasonDetail: any;
  forecastDate: any;
  cancelAuth = false;
  data: any;
  serialButton = false;
  errorMessage: any;
  dateData: any;
  authData: any;
  deliveryTotal: any;
  deliveryAuth: any;
  authDownloadEnabled = false;
  deliveryTargetEnabled = false;
  showTargetAndSerialBtns = false;
  cancelButton: any;
  pacakgeReview = false;
  supplierForecast = false;
  packageReviewStatus: any;
  packageReason: any;
  packageRequestMessage: any;
  result: any;
  readinessDate: any;
  public toReadyDate: any = Date;
  authStatus: any;
  supplierForecastDate: any;
  deliverySum: any;
  deliveryHistory: any;
  okButtonDisabled: any;
  displayForecastDate = false;
  displayReadinessDate = false;
  disableForecastDate = false;
  disableReadinessDate = false;
  cancelOKFlag = false;
  cancelNGFlag = false;
  cancelOKNGDisabled = false;

  constructor(
    public dialog: MatDialog,
    private readonly router: Router,
    public route: ActivatedRoute,
    public doPkgService: DoPkgAuthorizationService,
    public datepipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.dopkgData = JSON.parse(sessionStorage.getItem('queryParamsDo') || '');
    this.cancelAuth = this.dopkgData.validFlag === 2;
    this.apiCall();
  }

  apiCall(){
    this.doPkgService.dopkgOnload(this.dopkgData).subscribe((res: any) => {
      this.authStatus = this.dopkgData.authStatus;
      this.dateData = this.datepipe.transform(res[0]?.pkgDueDate, this.dateFormat);
      this.authData = this.datepipe.transform(res[0]?.authDate, this.dateFormat);
      this.htmlData = res[0] ? res[0] : [];
      this.deliveryAuth = res[1]?.authorized[0];
      const target = res[1]?.target?.map((x:any)=> x?.target).filter(Boolean);
      this.deliverySum = target.reduce((partial:any,a:any) => partial + a, 0)
      this.okButtonDisabled = this.checkOkButton(res[0]);
      this.supplierForecast = this.authStatus === 2;
      this.supplierForecastDate = this.datepipe.transform(
        this.htmlData?.supplierForecast,
        this.dateFormat
      );

      this.toReadyDate = this.datepipe.transform(
        this.htmlData.forecastOrReadinessDate[0].TOREADYDATE,
        'yyyy-MM-dd'
      );

      this.forecastDate = this.datepipe.transform(
        this.htmlData?.forecastOrReadinessDate[0]?.FORECASTDATE,
        'yyyy-MM-dd'
      );

      this.result = this.htmlData?.packageReview?.pkgReviewSection?.Result;
      this.pacakgeReview =
        this.dopkgData.authType === 0 &&
        this.dopkgData.validFlag === 1 &&
        this.authStatus === 1
          ? true
          : false;
      this.packageReason =
        this.htmlData?.packageReview?.pkgReviewSection?.ReasonMessage;
      this.packageRequestMessage =
        this.htmlData?.packageReview?.pkgReviewSection?.RequestMessage;

      this.serialButton =
        this.dopkgData.validFlag === 1 &&
        (this.htmlData?.containerPackageType[0]?.PACKAGETYPECODE.includes(
          'R'
        ) ||
          this.htmlData?.containerPackageType[0]?.PACKAGETYPECODE.includes('B'))
          ? true
          : false;
      this.authDownloadEnabled = this.dopkgData.validFlag === 1;
      this.showTargetAndSerialBtns =
        this.authStatus === 1 &&
        this.dopkgData.validFlag === 1 &&
        this.dopkgData.supOrFab === this.dopkgData.whichRequest;
            this.displayForecastDate = this.displayForecast();
      this.displayReadinessDate = this.displayReadiness();
      this.disableForecastDate = this.disableForecast();
      this.disableReadinessDate = this.disableReadiness();
      this.checkButtons(res[0]);
    });
  }

  checkButtons(response:any){
    if(!([9,10,11].includes(response.getStatus))){
      if(this.dopkgData.validFlag && this.dopkgData.validFlag === 2){
        this.cancelOKFlag = true;
        this.cancelNGFlag = true;
      } 
    }
    if(![2,6,7,9,10,11].includes(response.getStatus)){
      if(this.dopkgData.supOrFab !== this.dopkgData.whichRequest){
        this.cancelOKNGDisabled = true;
      }
    }
  }

  disableForecast() {
    if (
      this.authStatus === 1 &&
      this.dopkgData.validFlag === 1 &&
      this.dopkgData.supOrFab !== this.dopkgData.whichRequest
    ) {
      return true;
    } else {
      return false;
    }
  }

  displayForecast() {
    if (
      this.authStatus === 1 &&
      this.dopkgData.validFlag === 1 &&
      this.dopkgData.supOrFab === this.dopkgData.whichRequest
    ) {
      return true;
    } else {
      return false;
    }
  }

  disableReadiness() {
    if (
      this.dopkgData?.status === 'Finish' ||
      (this.authStatus === 2 &&
        this.dopkgData.validFlag === 1 &&
        this.dopkgData.supOrFab !== this.dopkgData.whichRequest)
    ) {
      return true;
    } else {
      return false;
    }
  }

  displayReadiness() {
    if (
      this.authStatus === 2 &&
      this.dopkgData.validFlag === 1 &&
      this.dopkgData.supOrFab === this.dopkgData.whichRequest
    ) {
      return true;
    } else {
      return false;
    }
  }

  checkOkButton(data: any) {
    let disabled = false;
    if (![2, 6, 7, 9, 10, 11].includes(data?.getStatus)) {
      disabled = true;
    }
    if (this.dopkgData?.supOrFab !== this.dopkgData?.whichRequest) {
      disabled = true;
    }
    return disabled;
  }

  getResult(result: number): any {
    switch (result) {
      case 0:
        return 'Approve';
      case 1:
        return 'Approve with changes';
      case 2:
        return 'Reject';
      case 3:
        return 'Trial Skip';
      case 4:
        return 'Approve as Final';
      default:
        return '';
    }
  }

  goBack() {
    this.router.navigate([this.pkgAuthorizationUrl]);
  }
  cancelOk() {
    this.doPkgService.cancelOk(this.dopkgData).subscribe((_res: any) => {});
    this.router.navigate([this.pkgAuthorizationUrl]);
  }
  cancelNg() {
    this.doPkgService.cancelNg(this.dopkgData).subscribe((_res: any) => {});
    this.router.navigate([this.pkgAuthorizationUrl]);
  }
  saveClick() {
    this.deliveryHistory = this.deliveryAuth === this.deliverySum;
    if (!this.forecastDate) {
      alert('Please fill in Forecast Date');
    } else if (this.deliverySum !== this.deliveryAuth) {
      alert('Please fill in Delivery Target');
    } else {
      this.saveOK();
    }
  }

  saveOK() {
    const body = {
      authNumber: this.dopkgData?.authNumber,
      authStatus: this.dopkgData?.authStatus,
      date:
        !this.displayReadinessDate && !this.disableReadinessDate
          ? this.datepipe.transform(this.forecastDate, 'yyyy/MM/dd')
          : this.datepipe.transform(this.toReadyDate, 'yyyy/MM/dd'),
    };
    this.doPkgService.submitObject(body).subscribe({
      next: (_res: any) => {
        this.router.navigate([this.pkgAuthorizationUrl]);
      },
    });
  }

  authDownload() {
    if (sessionStorage.getItem('queryParamsDo')) {
      this.dopkgData = JSON.parse(
        sessionStorage.getItem('queryParamsDo') ?? ''
      );
    }
    this.dopkgData.authNumber && this.doPkgService.authDownload(this.dopkgData).subscribe((res: any) => {
      const reqTo:any = {
        supplierCode:'',
        supplierName:'',
        contactPerson:'',
        contactFax:''
      };
      const reqCc:any = {
        supplierCodeCC:'',
        supplierNameCC:'',
        contactPersonCC:'',
        contactFaxCC:''
      };
      if(res[0]?.REQUEST_TO && (res[0]?.REQUEST_TO === res[0]?.SUPPLIERCODE)){
        reqTo.supplierCode = res[0]?.SUPPLIERCODE;
        reqTo.supplierName = res[0]?.SUPPLIERNAME;
        reqTo.contactPerson = res[0]?.CONTACTPERSON;
        reqTo.contactFax = res[0]?.FACSIMILETELEPHONENUMBER;
        if(res[0]?.REQUEST_CC){
          reqCc.supplierCodeCC = res[0]?.FABCODE;
          reqCc.supplierNameCC = res[0]?.FABNAME;
          reqCc.contactPersonCC = res[0]?.FABCONTACTPERSON;
          reqCc.contactFaxCC = res[0]?.FABFAX;
        }else{
          reqCc.supplierCodeCC = '';
          reqCc.supplierNameCC = '';
          reqCc.contactPersonCC = '';
          reqCc.contactFaxCC = '';
        }
      }else{
        reqTo.supplierCode = res[0]?.FABCODE;
        reqTo.supplierName = res[0]?.FABNAME;
        reqTo.contactPerson = res[0]?.FABCONTACTPERSON;
        reqTo.contactFax = res[0]?.FABFAX;
        if(res[0]?.REQUEST_CC){
          reqCc.supplierCodeCC = res[0]?.SUPPLIERCODE;
          reqCc.supplierNameCC = res[0]?.SUPPLIERNAME;
          reqCc.contactPersonCC = res[0]?.CONTACTPERSON;
          reqCc.contactFaxCC = res[0]?.FACSIMILETELEPHONENUMBER;
        }else{
          reqCc.supplierCodeCC = '';
          reqCc.supplierNameCC = '';
          reqCc.contactPersonCC = '';
          reqCc.contactFaxCC = '';
        }
      }
      
      //toptable
      let AMAPPCOST:any=''
      let MGRAPPCOST:any=''
      if([0,1].includes(res[0]?.AUTHTYPE)){
        AMAPPCOST = res[0]?.PURPRODAPPCOST;
        MGRAPPCOST = res[0]?.PURPRODAPPCOST;
      }else if (res[0]?.AUTHTYPE === 5){
        AMAPPCOST = res[0]?.PURPROTOAPPCOST;
        MGRAPPCOST = res[0]?.PURPROTOAPPCOST;
      }else{
        AMAPPCOST = res[0]?.PURMAINTEAPPCOST;
        MGRAPPCOST = res[0]?.PURMAINTEAPPCOST;
      }

      AMAPPCOST = AMAPPCOST ? '<=$' + AMAPPCOST.toFixed(2) : '';
      MGRAPPCOST = MGRAPPCOST ? '>$' + MGRAPPCOST.toFixed(2) : '';
      const dd: any = {
        pageSize: {
          width: 842 + 170,
          height: 595 + 230,
        },
        pageOrientation: 'landscape',
        pageMargins: [15, 15, 15, 15],
        content: [
          { text: 'TOYOTA ', fontSize: 20, bold: true },
          {
            canvas: [
              {
                type: 'line',
                x1: 870,
                y1: 0,
                x2: 0,
                y2: 0,
                lineWidth: 2,
                lineColor: 'black',
              },
            ],
            marginBottom: 10,
          },
          { text: 'RET CONTAINER AUTH ', fontSize: 15, bold: true },
          { text: 'PC-Logistics ', fontSize: 10 },
          {
            table: {
              widths: ['30%', '*'],
              body: [
                [
                  {
                    table: {
                      widths: ['25%', '*'],
                      body: [
                        ['Auth#', res[0]?.AUTHNUMBER],
                        ['Plant', res[0]?.PLANTNAME],
                      ],
                    },
                  },
                  {
                    table: {
                      widths: ['10%', '15%', '15%', '20%', '*', '*', '*'],
                      body: [
                        ['', 'GM', 'SR ADV', 'MGR', 'CO', 'AM', 'SPEC'],
                        ['PC', '', '', res[0]?.MGRNAME, '', res[0]?.AMNAME, res[0]?.SPECIALISTNAME],
                        ['PUR', '', '', res[0]?.PURMGR,'', res[0]?.PURAM, res[0]?.PURSPEC],
                        ['Approval$', '', '', MGRAPPCOST, '', AMAPPCOST, ''],
                      ],
                    },
                  },
                ],
              ],
            },
            layout: {
              hLineWidth: function (_i: any, _node: any) {
                return 0;
              },
              vLineWidth: function (_i: any, _node: any) {
                return 0;
              },
            },
          },
          {
            text: `Authdate: ${res[0]?.AUTHDATE}`,
          },
          {
            canvas: [
              {
                type: 'line',
                text: res[0]?.ACTUALDATE,
                x1: 275,
                y1: 0,
                x2: 0,
                y2: 0,
                lineWidth: 1,
                lineColor: 'black',
              },
            ],
            marginBottom: 10,
          },

          {
            columns: [
              {
                widths: ['30%', '*'],
                table: {
                  widths: [120, 120, 100],
                  headerRows: 1,

                  body: [
                    [
                      {
                        text: 'AUTH To:',
                        style: 'tableHeader',
                        colSpan: 3,
                        alignment: 'center',
                      },
                      {},
                    ],
                    [
                      { text: 'Toyota Authorizing', alignment: 'center' },
                      {
                        colSpan: 2,
                        text: reqTo?.supplierName,
                        alignment: 'center',
                      },
                    ],
                    [
                      { text: 'NA Supplier Code', alignment: 'center' },
                      {
                        colSpan: 2,
                        text: reqTo?.supplierCode,
                        alignment: 'center',
                      },
                    ],
                    [
                      { text: 'Contact Name', alignment: 'center' },
                      { colSpan: 2, text: reqTo?.contactPerson, alignment: 'center' },
                    ],
                    [
                      { text: 'Contact FAX', alignment: 'center' },
                      { colSpan: 2, text: reqTo?.contactFax, alignment: 'center' },
                    ],
                  ],
                },
              },
              {
                table: {
                  widths: [90, 150],

                  body: [
                    [
                      'Part Number',
                      {
                        text: res[0]?.PARTNUMBER,
                        decoration: 'underline',
                        alignment: 'center',
                      },
                    ],
                    [
                      'Part Description',
                      {
                        text: res[0]?.PARTDESCRIPTION,
                        decoration: 'underline',
                        alignment: 'center',
                      },
                    ],
                    [
                      'Model#',
                      {
                        text: res[0]?.MODELCODE,
                        decoration: 'underline',
                        alignment: 'center',
                      },
                    ],
                    [
                      'Ringi# ',
                      {
                        text: res[0]?.RINGINUMBER,
                        decoration: 'underline',
                        alignment: 'center',
                      },
                    ],
                    [
                      'Container Code',
                      {
                        text: res[0]?.CONCODE,
                        decoration: 'underline',
                        alignment: 'center',
                      },
                    ],
                    [
                      'Dunnage1 Code',
                      {
                        text: res[0]?.DUN1CODE,
                        decoration: 'underline',
                        alignment: 'center',
                      },
                    ],
                    [
                      'Dunnage2 Code',
                      {
                        text: res[0]?.DUN2CODE,
                        decoration: 'underline',
                        alignment: 'center',
                      },
                    ],
                    [
                      'Pallet Code',
                      {
                        text: res[0]?.PALCODE,
                        decoration: 'underline',
                        alignment: 'center',
                      },
                    ],
                    [
                      'Top Cap Code',
                      {
                        text: res[0]?.TOPCODE,
                        decoration: 'underline',
                        alignment: 'center',
                      },
                    ],
                    [
                      'Kanban Code',
                      {
                        text: res[0]?.KANCODE,
                        decoration: 'underline',
                        alignment: 'center',
                      },
                    ],
                    [
                      'Stretch Code',
                      {
                        text: res[0]?.STRCODE,
                        decoration: 'underline',
                        alignment: 'center',
                      },
                    ],
                    [
                      'Other Pkg Code',
                      {
                        text: res[0]?.OTHCODE,
                        decoration: 'underline',
                        alignment: 'center',
                      },
                    ],
                  ],
                },
                margin: [20, 0, 0, 0],
                layout: 'noBorders',
              },
              {
                table: {
                  widths: [250],
                  body: [
                    [{ text: 'Comment1', alignment: 'center', colspan: 6 }],
                    [
                      {
                        text: res[0].COMMENT1 ?  res[0].COMMENT1 : '',
                        alignment: 'center',
                        colspan: 6,
                        margin : [10,10,10,10] 
                      },
                    ],
                    [{}],
                    [{ text: 'Comment2', alignment: 'center', colspan: 6}],
                    [
                      {
                        text: res[0].COMMENT2 ?  res[0].COMMENT2 : '',
                        alignment: 'center',
                        colspan: 6,
                        margin : [10,10,10,10] 
                      },
                    ],
                  ],
                },
                margin: [-90, 0, 0, 0],
              },
            ],
          },
          {
            style: 'tableExample',
            table: {
              widths: [120, 120, 100],
              headerRows: 1,
              body: [
                [
                  {
                    text: 'AUTH CC:',
                    style: 'tableHeader',
                    colSpan: 3,
                    alignment: 'center',
                  },
                  {},
                ],
                [
                  { text: 'w/ Copy To', alignment: 'center' },
                  {
                    colSpan: 2,
                    text: reqCc?.supplierNameCC,
                    alignment: 'center',
                  },
                ],
                [
                  { text: 'NA Supplier Code', alignment: 'center' },
                  {
                    colSpan: 2,
                    text: reqCc?.supplierCodeCC,
                    alignment: 'center',
                  },
                ],
                [
                  { text: 'Contact Name', alignment: 'center' },
                  {
                    colSpan: 2,
                    text: reqCc?.contactPersonCC,
                    alignment: 'center',
                  },
                ],
                [
                  { text: 'Contact FAX', alignment: 'center' },
                  { colSpan: 2, text: reqCc?.contactFaxCC, alignment: 'center' },
                ],
              ],
            },
            margin: [0, -100, 0, 0],
          },

          {
            style: 'tableExample',
            table: {
              widths: [
                90,
                '*',
                '*',
                '*',
                '*',
                '*',
                '*',
                '*',
                '*',
                '*',
                '*',
                '*',
                '*',
              ],
              body: [
                [
                  {
                    text: 'TMMNA Use Only',
                    style: 'tableHeader',
                    colSpan: 13,
                    alignment: 'center',
                  },
                  {},
                  {},
                  {},
                  {},
                  {},
                  {},
                  {},
                  {},
                  {},
                  {},
                  {},
                  {},
                ],
                [
                  { text: 'Container Size', alignment: 'center' },
                  { text: 'Container Type', alignment: 'center' },
                  { text: 'Qty.Of Containers Autorized', alignment: 'center' },
                  { text: 'Qty.Of Pallets Autorized', alignment: 'center' },
                  { text: 'Qty.Of Caps Autorized', alignment: 'center' },
                  { text: 'Qty.Of Kanban Holders', alignment: 'center' },
                  { text: 'Qty.Of Dunnage Autorized', alignment: 'center' },
                  { text: 'Qty.Of Dunnage2 Autorized', alignment: 'center' },
                  { text: 'Qty.Of Stretch Autorized', alignment: 'center' },
                  { text: 'Qty.Of Other Pkg Autorized', alignment: 'center' },
                  { text: 'Qty.Of Label Autorized', alignment: 'center' },
                  { text: 'Parts per Container', alignment: 'center' },
                  { text: 'Container Per Pallet', alignment: 'center' },
                ],
                [
                  `${res[0]?.CONTLENGTH}*${res[0]?.CONTWIDTH}*${res[0]?.CONTHEIGHT}`,
                  res[0]?.CONTTYPE,
                  res[0]?.CONQTY,
                  res[0]?.PALQTY,
                  res[0]?.TOPQTY,
                  res[0]?.KANQTY,
                  res[0]?.DUN1QTY,
                  res[0]?.DUN2QTY,
                  res[0]?.STRQTY,
                  res[0]?.OTHQTY,
                  res[0]?.LABELQTY,
                  res[0]?.QPC,
                  res[0]?.CPP,
                ],
              ],
            },
          },
          { text: 'Notes: ', style: 'header' },
          {
            ol: [
              ' Procure order within 5 days of receiving authorization',
              [
                'With this authorization, Toyota guarantees reimbursement of costs for the above quantities of packaging that are design compliant, reasonable, and verifiable.',
                {
                  ol: [
                    'Design compliant is defined as complying with Toyota documented specifications as recorded in the Supplier Packaging Policies & Standards Manual, the Packaging Proposal, any other Toyota directives on file under documentation within VPACS and quantities as identified on this authorization. Production tooling is reimbursed on the first production packaging order. ',
                    '. Reasonable is defined as: i) being the most competitive rate between at least two sources unless mitigating circumstances prevail which are concurred to by Toyota in advance; ii) competitive versus previously approved packaging; and/or iii) based on cost standards. ',
                    'Verifiable is defined as supportable by fabricator quotations and/or invoices upon request or, as in the case of freight, by past performance history. Toyota reserves the right to request copies of invoices as a spot audit check up to 1 year after the invoice date against a PO. ',
                  ],
                },
              ],
              'Supplier s invoice must be submitted with a copy of source s packing slip or the like proof of receipt of goods. It should be signed by supplier as having inspected product for confirmation to packaging specifications, for reimbursement. Supplier’s invoice must reference Toyota’s PO number, which will be issued upon acceptance of Supplier’s quotation in WARP.',
              'Supplier agrees that this Returnable Container Authorization is an Operational Document as defined in the Toyota Engineering and Manufacturing North America, Inc.Terms and Conditions between TEMA and Supplier (the "Terms and Conditions"), all of which are incorporated by reference as part of this Returnable Container Authorization.The Terms and Conditions are either contained in a separate document entitled "Terms and Conditions" signed by both Supplier and TEMA or have been provided to Supplier previously in a separate communication from TEMA. Any additional terms and conditions proposed by Supplier will not be part of the Terms and Conditions unles TEMA accepts them in writing signed by TEMA.',
            ],
          },
        ],
        styles: {
          header: {
            fontSize: 18,
            bold: true,
            margin: [0, 0, 0, 10],
          },
          subheader: {
            fontSize: 16,
            bold: true,
            margin: [0, 10, 0, 5],
          },
          tableExample: {
            margin: [0, 5, 0, 15],
          },
          tableHeader: {
            bold: true,
            fontSize: 13,
            color: 'black',
          },
        },
      };      
      pdfMake.createPdf(dd).download(res[0]?.AUTHNUMBER);
      //return dd;
    });
  }

  deliveryTarget() {
    const queryParams = {
      authNumber: this.dopkgData?.authNumber,
    };
    sessionStorage.setItem('queryParamsDelv', JSON.stringify(queryParams));
    const dialogref = this.dialog.open(DeliveryTargetComponent, { width: '110%', height: '90%' });
    dialogref.afterClosed().subscribe({
      next  : (res:any) =>{
        if(res && res === 'success'){
          this.apiCall();
        }
      }
    })
  }

  serial() {
    this.dopkgData = JSON.parse(sessionStorage.getItem('queryParamsDo') ?? '');
    this.doPkgService.serialPdf(this.dopkgData).subscribe((res: any) => {
      this.data = res.tabularArray;

      const dd: any = {
        pageSize: 'a4',
        pageOrientation: 'portrait',
        pageMargins: [15, 15, 15, 15],
        content: [
          {
            text: 'RACK SERIAL NUMBER NOTICE ',
            alignment: 'center',
            fontSize: 20,
            bold: true,
            margin: [0, 0, 0, 10],
          },
          { text: 'Auth Number :', style: 'tableHeader' },
          { text: res.authNumber, absolutePosition: { x: 150, y: 52 } },
          {
            canvas: [
              {
                type: 'line',
                x1: 275,
                y1: 0,
                x2: 0,
                y2: 0,
                lineWidth: 1,
                lineColor: 'black',
              },
            ],
          },
          { text: 'PO Number :', style: 'tableHeader' },
          { text: res.poNumber, absolutePosition: { x: 150, y: 66 } },
          {
            canvas: [
              {
                type: 'line',
                x1: 275,
                y1: 0,
                x2: 0,
                y2: 0,
                lineWidth: 1,
                lineColor: 'black',
              },
            ],
          },
          { text: 'Package Code :', style: 'tableHeader' },
          { text: res.packageCode, absolutePosition: { x: 150, y: 82 } },
          {
            canvas: [
              {
                type: 'line',
                x1: 275,
                y1: 0,
                x2: 0,
                y2: 0,
                lineWidth: 1,
                lineColor: 'black',
              },
            ],
            marginBottom: 20,
          },
          {
            style: 'tableExample',
            alignment: 'center',
            table: {
              headerRows: 1,
              body: [
                ['No', 'Rack Serial Number', 'Auth Number', 'PO Number'],
                ...this.data.map((item: any, index: any) => [
                  index + 1,
                  item.rackNo,
                  item.authNumber,
                  item.poNumber,
                ]),
              ],
            },
          },
        ],

        styles: {
          header: {
            fontSize: 18,
            bold: true,
            margin: [0, 0, 0, 10],
          },
          subheader: {
            fontSize: 16,
            bold: true,
            margin: [0, 10, 0, 5],
          },
          tableExample: {
            margin: [0, 5, 0, 15],
          },
          tableHeader: {
            bold: true,
            fontSize: 13,
            color: 'black',
            margin: [0, 0, 5, 0],
          },
        },
      };
      pdfMake.createPdf(dd).download('serial.pdf');
    });
  }
}
