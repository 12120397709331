import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectSpecialistComponent } from 'src/app/internal-vpacs/internal-vpacs-dialogs/tracking/select-specialist/select-specialist.component';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { formatDate } from '@angular/common';
import { ExportStateService } from 'src/app/services/export-state.service';
import { ErrorMessage } from 'src/app/internal-vpacs/interface/ErrorInterface';
import { HazmatApprovementListService } from 'src/app/internal-vpacs/services-internal/tracking/hazmat-approvement-list/hazmat-approvement-list.service';
import { HazmatCellRenderer } from 'src/app/internal-vpacs/internal-Vpacs-gridrender/tracking/hazmat-approvement/hz-cell-renderer';
import { dateComparator } from 'src/app/internal-vpacs/services-internal/tracking/tracking-list/tracking-list.service';

@Component({
  selector: 'app-hazmat-approvement-list',
  templateUrl: './hazmat-approvement-list.component.html',
  styleUrls: ['./hazmat-approvement-list.component.scss']
})
export class HazmatApprovementListComponent implements OnInit {
  radioVal: any = 0;
  gridApi: any;
  columnDefs: any[] = [];
  currentPageNo = 1;
  currentPageBoolean = false;
  totalPage = 0;
  displayRowData: any[] = [];
  currentPageForTotalPage!: number;
  isLastPageFound!: boolean;
  rowCount = 0;
  defaultColDef: {};
  gridOptions: any;
  icons: {};
  rowHeight = 50;
  headerHeight = 50;
  paginationPageSize= 50
  isAccessible = false;
  plants: any[] = [];

  plant: any;
  autoGroupColumnDef: any;

  public domLayout: any = ''
  public overlayLoadingTemplate =
    `<div class="spinner-border" role="status"></div>`;
  public overlayNoRowsTemplate =
    `
  <div>
    <img src="../../../assets/images/noun_Empty_1735753.svg" alt="NoData" />
    <p class="fw-bold fs-5">No data available</p>
    <span>Please select the above filters to get the results.</span>
  </div>
  `;
  pageNumber = 0;
  lastRowIndex = 0;
  cPage = 1;
  tPage = 0;
  rpPage = 50;
  totalRecords = 0;
  isAll = false;
  rowClassRules: any;
  bmVal = 1;
  preVal = 1;
  trialVal = 1;
  finalVal = 1;
  updatePpfVal = 1;
  latestVal = 1;
  allVal = 0;
  public searchForm !: FormGroup;
  spCode: any;
  supplier: any;
  gridColumnApi: any;
  sortModel: any[] = [];
  specialistCode: any;
  resPlant: any;
  selectedPlant: any[] = [];
  haveAccess: any;
  haveAddAccess: any;
  errorMessage !: ErrorMessage;
  errorMsg:any;
  modifyAccess: any;
  deleteAccess: any;
  loading = true;
  leaderPlant: any;
  approverCode: any;
  delay = 1;
  warn = 1;
  good = 1;
  stepNo: any;
  stepList: any[] = [];
  plantData: any;
  originalPage: any;
  loadResponse: any;
  roleCheck: any;
  apiSearch = false;
  filterData: any;
  filteredOptions: any[] = [];

  constructor(
    public readonly route: Router,
    private readonly dialog: MatDialog,
    public readonly router: ActivatedRoute,
    private readonly hzService: HazmatApprovementListService,
    private readonly spinnerService: NgxUiLoaderService,
    public formBuilder: FormBuilder,
    private readonly state: ExportStateService
  ) {
    this.defaultColDef = {
      suppressMovable: true,
      wrapText: true,
      suppressPaginationPanel: true,
      autoHeight: true,
      floatingFilterComponentParams: { suppressFilterButton: true },
      floatingFilter: true,
      filter: 'agTextColumnFilter',
      filterParams: { newRowsAction: 'keep' },
    }
    this.icons = {
      sortUnSort: '<img src="../../../assets/images/Icon awesome-sort.svg">',
    }
    this.rowClassRules = {
      'row-even': 'node.rowIndex % 2 === 0',
      'row-odd': 'node.rowIndex % 2 !== 0',
    };
  }
  ngOnInit(): void {
    this.errorMsg=''
    this.searchForm = this.formBuilder.group({
      leaderPlant: ['', Validators.required],
      plant: ['', Validators.required],
      dock: ['', Validators.required],
      modelCode: ['', Validators.required],
      supplier: ['', Validators.required],
      partNumber: ['', Validators.required],
      specialist: [''],
      currentApprover: [''],
    });
    this.plantData=this.hzService.gethazmatPlant();
    this.spinnerService.start();
    this.state.userInfoState.subscribe({
      next: (res: any) => {
        let roleStore =[]
        roleStore = res.role?.u_roles.map((word:any) => word.toLowerCase())
        this.roleCheck = roleStore?.filter((x: any)=>x==='tmna hazmat admin')
        sessionStorage.setItem('roleChecking',this.roleCheck);
        this.haveAccess = res.role?.p_trackingview;
        this.haveAddAccess = res.role?.p_trackingsave;
        this.modifyAccess = res.role?.p_trackingcorrect;
        this.deleteAccess = res.role?.p_trackingdelete;
        this.specialistCode = res.info?.specialistcode;
        this.spCode = res.info?.specialistcode;
        this.haveAccess && this.getcolumndef();
        if(res && this.haveAccess){
          if(this.plantData){
            this.filterData=this.hzService.gethazmatFilters();
            if(this.filterData){
              const allPagination = Number(sessionStorage.getItem('paginationHAL'));
              const allPaginationCurr = Number(sessionStorage.getItem('paginationHALCurr'));

              if(allPagination){
               this.rpPage = allPagination;
              }
              
              if(allPaginationCurr){
                this.cPage = allPaginationCurr;
               }
              
             this.leaderPlant = (this.filterData?.LeaderPlant?.length>1)? '(All)':this.filterData?.LeaderPlant[0];
             this.plant=(this.filterData?.PlantCodes?.length>1)? '(All)':this.filterData?.PlantCodes[0];
             this.plants = this.plantData;
             this.searchForm.get('modelCode')?.setValue(this.filterData.model);
             this.searchForm.get('supplier')?.setValue(this.filterData.SupplierCode);
             this.searchForm.get('partNumber')?.setValue(this.filterData.partnumber1);
             this.specialistCode = this.filterData.specialist; 
             this.trialVal = this.filterData.step7;
             this.finalVal = this.filterData.step9;
             this.preVal =  this.filterData.step4;
             this.updatePpfVal = this.filterData.step10;
             this.radioVal = Number(this.filterData.valid);
            }
          }
          this.loadPlants();
        }
        else if(res && !this.haveAccess){
          this.errorMessage = { errorCode: `API-${res.status}`, childErrorCode: res.status }
          this.spinnerService.stop();
        }
      },
      error: (_err: any) => {
        this.haveAccess = false;
        this.haveAddAccess = false;
        this.modifyAccess = false;
        this.spinnerService.stop();
      }
    });    
  }
  
  getcolumndef(){
    this.columnDefs = [
      {
        headerName: "leader plant", field: "leadname", sortable: true, unSortIcon: true, minWidth: 120, filter: 'agTextColumnFilter',
      },
      {
        headerName: "plant", field: "plantname", sortable: true, unSortIcon: true, minWidth: 90,
      },
      {
        headerName: "model", field: "modelcode", sortable: true, unSortIcon: true, minWidth: 90,
      },
      {
        headerName: "part number", field: "partnumber", sortable: true, unSortIcon: true, minWidth: 120,
      },
      {
        headerName: "description", field: "partdescription", sortable: true, unSortIcon: true, minWidth: 170,
      },
      {
        headerName: "supplier", field: "suppliername", sortable: true, unSortIcon: true, minWidth: 120,
      },
      {
        headerName: "specialist", field: "specialist", sortable: true, unSortIcon: true, minWidth: 120,
      },
      {
        headerName: "date sent", field: "datesent", sortable: true, unSortIcon: true, minWidth: 100, valueFormatter: this.dateFormatter,comparator: dateComparator,
      },
      {
        headerName: "pre ppf", field: "preppf", sortable: true, unSortIcon: true, minWidth: 90,
        cellRendererFramework: HazmatCellRenderer, cellStyle: { cursor: 'pointer', color: '#2688DA' },comparator:dateComparator
      },
      {
        headerName: "pkg trial", field: "pkgtrial", sortable: true, unSortIcon: true, minWidth: 90,
        cellRendererFramework: HazmatCellRenderer, cellStyle: { cursor: 'pointer', color: '#2688DA' },comparator:dateComparator
      },
      {
        headerName: "final ppf", field: "finalppf", sortable: true, unSortIcon: true, minWidth: 90,
        cellRendererFramework: HazmatCellRenderer, cellStyle: { cursor: 'pointer', color: '#2688DA' },comparator:dateComparator
      },
      {
        headerName: "update ppf", field: "updateppf", sortable: true, unSortIcon: true, minWidth: 90,
        cellRendererFramework: HazmatCellRenderer, cellStyle: { cursor: 'pointer', color: '#2688DA' },comparator:dateComparator
      }
    ]
  }
  onPreChange(event:any){
    if(event.target.checked){
      this.preVal = 1;
    }
    else{
      this.preVal = 0;
    }
  }
  onTrialChange(event:any){
    if(event.target.checked){
      this.trialVal = 1;
    }
    else{
      this.trialVal = 0;
    }
  }
  onFinalChange(event:any){
    if(event.target.checked){
      this.finalVal = 1;
    }
    else{
      this.finalVal = 0;
    }
  }
  onUpdatePpfChange(event:any){
    if(event.target.checked){
      this.updatePpfVal = 1;
    }
    else{
      this.updatePpfVal = 0;
    }
  }
  dateFormatter(params: any) {
    return params.value ? formatDate(params.value, 'MM/dd/YYYY', 'en-US') : '';
  }

  loadHzAppListDetails() {
    this.spinnerService.start();
    const plant = this.filteredOptions.filter((item: any) => ((item.plant?.toLowerCase() === this.plant?.toLowerCase()) || (item.plantcode?.toLowerCase() === this.plant?.toLowerCase())))
    this.plant = plant[0]?.plantcode;
    const leaderPlant = this.selectedPlant.filter((item: any) => ((item.plant?.toLowerCase() === this.leaderPlant?.toLowerCase()) || (item.plantcode?.toLowerCase() === this.leaderPlant?.toLowerCase())))
    this.leaderPlant = leaderPlant[0]?.plantcode;
    this.errorMsg=''
    const params: any = {
      LeaderPlant: this.leaderPlant === '(All)' ? this.resPlant : (this.leaderPlant ? [this.leaderPlant] : ''),
      PlantCodes: this.plant === '(All)' ? this.resPlant : (this.plant ? [this.plant] : []),
      model: this.searchForm.controls['modelCode']?.value ? this.searchForm.controls['modelCode']?.value : '',
      partnumber1: this.searchForm.controls['partNumber']?.value ? this.searchForm.controls['partNumber']?.value : '',
      partnumber2: '',
      specialist: this.specialistCode ? this.specialistCode : '',
      SupplierCode: this.searchForm.controls['supplier']?.value ? this.searchForm.controls['supplier']?.value : '',
      step1: this.radioVal === 1 ? 1 : 0,
      step2: this.radioVal === 1 ? 1 : 0,
      step3: this.radioVal === 1 ? 1 : 0,
      step4: this.preVal,
      step5: this.radioVal === 1 ? 1 : 0,
      step6: this.radioVal === 1 ? 1 : 0,
      step7: this.trialVal,
      step8: this.radioVal === 1 ? 1 : 0,
      step9: this.finalVal,
      step10: this.updatePpfVal,
      step11: this.updatePpfVal,
      step12: this.updatePpfVal,
      back_nodefine: 1,
      back_define: 1,
      back_appdefine: 1,
      ppf_needapp: 1,
      ppf_noaction: 1,
      sort: '',
      step03due: 1, // expand btn1
      step06due: 1, // expand btn2
      step09due: 1, // expand btn3
      stepSOP: 1, // expand btn4
      valid: this.radioVal,
      delay: 1,
      warn: 1,
      good: 1,
      TMMCCode: '', // no property  - session
      NUMMICode: '', // no property  - session
      WARN_BEFORE_DAYS: 1, // default
      page: this.cPage,
      limit: this.rpPage
    }
    this.hzService.sethazmatFilters(params)
    if (this.apiSearch) {
      this.cPage = 1;
    }
    this.gridApi?.showLoadingOverlay();
    this.hzService.loadHzAppListDetails(params)
      .subscribe({
        next: (res: any) => {
          this.loadResponse = res;
          this.displayRowData = res.data;
          this.displayRowData.forEach(val=>{
            if(val.leadname === null || val.leadname === ''){
              val.leadname ='-';
            }
          })
          this.rowCount = res.pagination.rows;
          this.totalRecords = res.pagination.TotalRecords;
          this.tPage = Math.ceil(this.totalRecords / this.rpPage);
          if (this.cPage > this.tPage) {
            this.cPage = 1;
          }
          if (this.apiSearch) {
            this.cPage = 1;
          }
          this.apiSearch = false;
          this.gridApi?.hideOverlay();
        },
        error: (err: any) => {
          this.gridApi?.hideOverlay();
          this.displayRowData = [];
          this.rowCount = 0;
          this.spinnerService.stop();
          this.errorMessage = { errorCode: `API-${err.status}`, childErrorCode: err.status }
        },
        complete: () => {
          this.spinnerService.stop();
        }
      });
  }

  loadPlants() {
    this.spinnerService.start();
    const plantObj: any = {
      SpecialistCode: this.spCode,
      Type: 1
    }
    this.hzService.getAllPlant(plantObj)
      .subscribe({
        next: (res: any) => {
          this.plants = [{ plant: 'ALL', plantcode: '(All)' }, ...res];
          this.resPlant = res.map((item: { plantcode: any; }) => item.plantcode);
          this.selectedPlant = this.plants;
          if(!this.filterData){
          this.plant = '(All)'
          }
          this.filteredOptions = this.plants
          if(this.filterData){
          this.loadHzAppListDetails();
          this.displayFn(this.plants);
          }
          this.hzService.sethazmatPlant(this.plants)
        },
        error: (err: any) => {
          this.spinnerService.stop();
          this.errorMessage = { errorCode: `API-${err.status}`, childErrorCode: err.status }
        },
        complete: () => {
          this.spinnerService.stop();
        }
      });
  }

  filterOptions() {
    this.filteredOptions = this.plants.filter(option => option.plant.toLowerCase().includes(this.plant.toLowerCase()))
  }

  displayFn(selectId: any) {
    const selectedOption = this.plants.find((option: any) =>
      option.plantcode === selectId);
    return selectedOption ? selectedOption.plant : '';
  }

  leaderFilterOptions() {
    this.selectedPlant = this.plants.filter(option => option.plant?.toLowerCase().includes(this.leaderPlant?.toLowerCase()))
  }

  leaderDisplayFn(selectId: any) {
    const selectedOption = this.plants.find((option: any) =>
      option.plantcode === selectId);
    return selectedOption ? selectedOption.plant : '';
  }
  
  cellStyleColumn() {
    return ({ 'display': 'flex', 'justify-content': 'flex-start', 'height': '40px' })
  }

  sizeToFit() { this.gridApi?.sizeColumnsToFit() }

  async changePaginationValues(values: any) {
    this.isAll = values?.rpPage === 'ALL' ? true : false;
    this.cPage = values?.cPage;
    this.tPage = values?.tPage;
    this.rpPage = !this.isAll ? values?.rpPage : this.totalRecords;
    this.spinnerService.start();
    if(this.isAll && (this.specialistCode || this.searchForm.controls['modelCode']?.value || this.searchForm.controls['supplier']?.value || this.searchForm.controls['partNumber']?.value)){
      this.displayRowData = []
     sessionStorage.setItem('paginationHAL',this.rpPage.toString());
      for (let i=0; i<Math.ceil(this.totalRecords/100); i++){
        this.cPage = i+1;
        this.tPage = 1;
        this.rpPage = 100;
        await this.onLoadAll()
      }
      this.rowCount = this.totalRecords;
      this.tPage = 1;
      this.cPage = 1;
      this.rpPage = this.totalRecords;
      this.gridApi?.paginationSetPageSize(this.totalRecords);
    }
    else  if(this.plant==='(All)' &&!(this.specialistCode || this.searchForm.controls['modelCode']?.value || this.searchForm.controls['supplier']?.value || this.searchForm.controls['partNumber']?.value))
    {
      this.spinnerService.stop();
     this.errorMsg='For `ALL` plant , Please select another field as well to search';
     return

    }
    else{
      sessionStorage.setItem('paginationHAL',this.rpPage.toString());
      sessionStorage.setItem('paginationHALCurr',this.cPage.toString());
      this.gridApi?.paginationGoToPage(this.cPage - 1);
      this.gridApi?.paginationSetPageSize(this.rpPage);
      this.loadHzAppListDetails();
    }
  }
  async onLoadAll() {
    return new Promise<void>((resolve) => {
      this.loadHzAppListDetails();
    
    resolve();
  });
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();
    this.gridColumnApi?.applyColumnState(this.sortModel)
    if (this.hzService.gethazmatGridFilter()) {
      const retrivedValue = this.hzService.gethazmatGridFilter();
      this.gridApi?.setFilterModel(retrivedValue);
      this.gridApi?.onFilterChanged();
      this.gridApi?.refreshCells();
    }
  }
  
  onFilterChanged(_params: any) {
    if (this.originalPage === undefined) {
      this.originalPage = this.cPage;
    }
    this.cPage = 1;
    this.totalRecords = this.gridApi.getDisplayedRowCount();
    this.rpPage = this.isAll ? this.totalRecords : this.rpPage;
    this.tPage = Math.ceil(this.totalRecords / this.rpPage);
    const allFilterModels = this.gridApi?.getFilterModel();
    this.hzService.sethazmatGridFilter(allFilterModels);
    const isFilterCleared = Object.keys(allFilterModels).length === 0;
    if (isFilterCleared) {
      this.cPage = this.originalPage;
      this.originalPage = undefined;
      this.totalRecords = this.loadResponse.pagination.TotalRecords;
      this.rpPage = this.isAll ? this.totalRecords : this.rpPage;
      this.tPage = Math.ceil(this.totalRecords / this.rpPage);
    }
  }
  selectSpecialist(field: any) {
    const dialogRef = this.dialog.open(SelectSpecialistComponent, {
      data: field === 'specialist' ? this.specialistCode : this.approverCode
    })
    dialogRef.afterClosed().subscribe((result: any) => {
      if (field === 'specialist') {
        this.specialistCode = result;
      }
      else if (field === 'approver') {
        this.approverCode = result;
      }  
    })
  }

  search() {
    this.apiSearch = true;
    this.displayRowData=[];
    this.totalRecords=0;
    const plantCheck = this.filteredOptions.find((item: any) => ((item.plant?.toLowerCase() === this.plant?.toLowerCase()) || (item.plantcode?.toLowerCase() === this.plant?.toLowerCase())))
    const plantCheckLeader = this.plants.find((item: any) => ((item.plant?.toLowerCase() === this.leaderPlant?.toLowerCase()) || (item.plantcode?.toLowerCase() === this.leaderPlant?.toLowerCase())))
    if ((![undefined,'undefined',null,'null'].includes(this.plant)) && (!plantCheck)) {
      this.errorMsg = "Please select valid plant name";
      return;
    }
    else if((![undefined,'undefined',null,'null',''].includes(this.leaderPlant)) && (!plantCheckLeader) ){
      this.errorMsg = "Please select valid leaderPlant name";
      return;
    }
    else {
    if(this.plant==='(All)' &&!(this.specialistCode || this.searchForm.controls['modelCode']?.value || this.searchForm.controls['supplier']?.value || this.searchForm.controls['partNumber']?.value))
     {
      this.errorMsg='For `ALL` plant , Please select another field as well to search'
     } else {
      this.errorMsg = [];
      this.loadHzAppListDetails()
     }
    }

  }

}