import { Component, OnInit } from '@angular/core';
import { TrackingService } from 'src/app/services/tracking.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { GlobalStateService } from 'src/app/services/service/global-state.service';
import { ExportStateService } from 'src/app/services/export-state.service';
import { ConstantsService } from 'src/app/services/constant.service';

@Component({
  selector: 'app-tracking-do-send-pre-pdf',
  templateUrl: './tracking-do-send-pre-pdf.component.html',
  styleUrls: ['./tracking-do-send-pre-pdf.component.scss'],
})
export class TrackingDoSendPrePdfComponent implements OnInit {
  receiveFromToyota: any;
  orderInformation: any;
  reason: any;
  readinessDate: any;
  data: any;
  public sendPrePpf: any;
  okFlag = false;
  view = 2;
  edit = 0;
  btnDisabled = false;
  cancelOKFlag = false;
  cancelNGFlag = false;
  approvalFlag = false;
  rejectFlag = false;
  holdFlag = false;
  userName: any;
  pkgReviewFlag = false;
  pkgData: any;
  hazmatComment: any;
  constructor(
    protected globalState: GlobalStateService,
    private readonly trackingService: TrackingService,
    public router: Router,
    public location: Location,
    public state: ExportStateService,
    private readonly constants: ConstantsService,
    private readonly route: ActivatedRoute,
  ) {
    this.sendPrePpf = new FormGroup({
      q1_1_yn: new FormControl(),
      q1_1_note: new FormControl(),
      q1_2_yn: new FormControl(),
      q1_2_note: new FormControl(),
      q1_3_date: new FormControl(),
      q1_4_date: new FormControl(),

      q2_1_date: new FormControl(),
      q2_2_select: new FormControl(),
      q2_3_select: new FormControl(),
      q2_4_select: new FormControl(),
      q2_4_note: new FormControl(),
      q2_5_yn: new FormControl(),
      q2_6_yn: new FormControl(),
      q2_6_part: new FormControl(),
      q2_6_model: new FormControl(),
      q2_7_yn: new FormControl(),
      comments: new FormControl('',[Validators.maxLength(250)]),
    });
  }

  ngOnInit(): void {
    if (sessionStorage.getItem('queryParamsSendPrePpf')) {
      this.data = JSON.parse(
        sessionStorage.getItem('queryParamsSendPrePpf') || ''
      );
    }
    if (this.data?.stepNumber === 3) {
      if (this.data?.statusNumber === 9 || this.data?.statusNumber === 10) {
        // n.o.p
      } else {
        this.view = 1;
      }
    }
    if (this.data?.WORKING === 6) {
      this.edit = 0;
    } else if (
      this.data?.statusNumber === 12 ||
      this.data?.statusNumber === 13
    ) {
      this.edit = 1;
    } else if (
      this.data?.statusNumber === 1 ||
      this.data?.statusNumber === 2 ||
      this.data?.statusNumber === 6
    ) {
      this.edit = 2;
    }
    this.state.userInfoState.subscribe((data: any) => {
      if (data) {
        this.userName = this.getFullName(data.info)
      }
    })
    this.sendPrePpfOnload();
  }
  getFullName(info: any): any {
    if (info.fullname) {
      return info.fullname
    } else {
      return info.firstname + info.lastname
    }
  }

  createForm(res: any,comment:any) {
    this.sendPrePpf = new FormGroup({
      q1_1_yn: new FormControl(res.Q1_1_YN),
      q1_1_note: new FormControl(res?.Q1_1_NOTE),
      q1_2_yn: new FormControl(res?.Q1_2_YN),
      q1_2_note: new FormControl(res?.Q1_2_NOTE),
      q1_3_date: new FormControl(res?.Q1_3_DATE?.split('/').join('-')),
      q1_4_date: new FormControl(res?.Q1_4_DATE?.split('/').join('-')),

      q2_1_date: new FormControl(res?.Q2_1_DATE?.split('/').join('-')),
      q2_2_select: new FormControl(res?.Q2_2_SELECT),
      q2_3_select: new FormControl(res?.Q2_3_SELECT),
      q2_4_select: new FormControl(res?.Q2_4_SELECT),
      q2_4_note: new FormControl(res?.Q2_4_NOTE),
      q2_5_yn: new FormControl(res?.Q2_5_YN),
      q2_6_yn: new FormControl(res?.Q2_6_YN),
      q2_6_part: new FormControl(res?.Q2_6_PART),
      q2_6_model: new FormControl(res?.Q2_6_MODEL),
      q2_7_yn: new FormControl(res?.Q2_7_YN),
      comments: new FormControl(comment,[Validators.maxLength(250)]),
    });
    if (res?.Q2_6_YN === '0') {
      this.sendPrePpf.get('q2_6_part').setValidators([Validators.required]);
      this.sendPrePpf.get('q2_6_part').updateValueAndValidity();
      this.sendPrePpf.get('q2_6_model').setValidators([Validators.required]);
      this.sendPrePpf.get('q2_6_model').updateValueAndValidity();
    } else {
      this.sendPrePpf.get('q2_6_part').clearValidators();
      this.sendPrePpf.get('q2_6_part').updateValueAndValidity();
      this.sendPrePpf.get('q2_6_model').clearValidators();
      this.sendPrePpf.get('q2_6_model').updateValueAndValidity();
    }
      if (res?.Q2_3_SELECT === "3" || res.Q2_3_SELECT === null) {
        this.sendPrePpf.get('q2_4_select')?.disable();
      } else {
        this.sendPrePpf.get('q2_4_select')?.enable();
      }
  }

  checkPackageReviewSection(res:any){
    let display = false;
    if([6,8,11].includes(this.data?.stepNumber)){
      display = true;
      if(res.steptype === 1){
        display = false;
      }
    }
    if(res.steptype === 4){
      display = true;
    }
    if(display){
      this.pkgReviewFlag = true;
      this.pkgData = res.packageReview
    }else{
      this.pkgReviewFlag = false;
    }
  }

  sendPrePpfOnload() {
    this.trackingService.sendPrePpf(this.data).subscribe({
      next: (res) => {
        if(res){
          this.receiveFromToyota = res;
          this.checkPackageReviewSection(res);
          this.hazmatComment = res.hazmatComment;
        }
      },
     complete: () => {
        if (this.receiveFromToyota?.data.length > 0) {
          this.createForm(this.receiveFromToyota?.data[0],(this.receiveFromToyota?.comments ?? ''));
        }
        this.disableLogic();
        this.checkButtons();
        if (sessionStorage.hasOwnProperty('ppfSaveData')) {
          const ppfPSaveData = JSON.parse(
            sessionStorage.getItem('ppfSaveData') || ''
          );
          if (ppfPSaveData.PPFCODE === this.data?.ppfNumber) {
            this.btnDisabled = false;
          }
        }
      },
    });
  }

  valueChange(event: any) {
    if(this.receiveFromToyota.data[0].Q2_4_SELECT){
      if (['2','3'].includes(event.target.value)) {
        this.sendPrePpf.get('q2_4_select')?.disable();
      } else {
        this.sendPrePpf.get('q2_4_select')?.enable();
      }
    }else{
      if (['2','3'].includes(event.target.value)) {
        this.sendPrePpf.get('q2_4_select')?.enable();
      } else {
        this.sendPrePpf.get('q2_4_select')?.disable();
        this.sendPrePpf.patchValue({q2_4_select : null});
      }
    }
    
  }

  radioChange2(event: any) {
    if (event.value === 'N') {
      this.sendPrePpf.get('q2_6_yn').updateValueAndValidity();
      this.sendPrePpf.get('q2_6_part').clearValidators();
      this.sendPrePpf.get('q2_6_part').disable();
      this.sendPrePpf.get('q2_6_part').updateValueAndValidity();
      this.sendPrePpf.get('q2_6_model').clearValidators();
      this.sendPrePpf.get('q2_6_model').disable();
      this.sendPrePpf.get('q2_6_model').updateValueAndValidity();
    } else {
      this.sendPrePpf.patchValue({ q2_6_yn: '' });
      this.sendPrePpf.get('q2_6_part').setValidators([Validators.required]);
      this.sendPrePpf.get('q2_6_part').updateValueAndValidity();
      this.sendPrePpf.get('q2_6_part').enable();
      this.sendPrePpf.get('q2_6_model').enable();
      this.sendPrePpf.get('q2_6_model').setValidators([Validators.required]);
      this.sendPrePpf.get('q2_6_model').updateValueAndValidity();
    }
  }

  radioChange(event: any) {
    if (event.value === 'N') {
      this.sendPrePpf.get('q2_6_part').setValidators([Validators.required]);
      this.sendPrePpf.get('q2_6_part').updateValueAndValidity();
      this.sendPrePpf.get('q2_6_part').enable();
      this.sendPrePpf.get('q2_6_model').enable();
      this.sendPrePpf.get('q2_6_model').setValidators([Validators.required]);
      this.sendPrePpf.get('q2_6_model').updateValueAndValidity();
    } else {
      this.sendPrePpf.get('q2_6_part').clearValidators();
      this.sendPrePpf.get('q2_6_part').disable();
      this.sendPrePpf.get('q2_6_part').updateValueAndValidity();
      this.sendPrePpf.get('q2_6_model').clearValidators();
      this.sendPrePpf.get('q2_6_model').disable();
      this.sendPrePpf.get('q2_6_model').updateValueAndValidity();
    }
  }

  disableLogic() {
    if (this.data && this.data.stepNumber) {
      if ([3, 8].includes(this.data.stepNumber)) {
        if (![6, 7, 9, 10, 11].includes(this.receiveFromToyota.getStatus)) {
          this.btnDisabled = true;
        } else {
          this.btnDisabled = false;
        }
      } else if ([6, 11, 12].includes(this.data.stepNumber)) {
        if (this.data.whichRequest !== this.data.supOrFab) {
          this.btnDisabled = true;
        }
        if (
          this.data.stepNumber === 6 &&
          [8, 9, 10, 11].includes(this.receiveFromToyota.getStatus)
        ) {
          this.btnDisabled = false;
        }
      } else {
        if ([2, 6, 7, 9, 10, 11].includes(this.receiveFromToyota.getStatus)) {
          this.btnDisabled = false;
        } else {
          this.btnDisabled = true;
        }
      }
    }
  }

  checkButtons() {

    if (this.data?.stepNumber === 3 && [12, 13].includes(this.receiveFromToyota.getStatus) || (this.receiveFromToyota.getStatus === 7 && this.receiveFromToyota.working === 6)) {
      this.holdFlag = true;
    } else if (this.data?.stepNumber === 3) {
      if (this.data?.supOrFab === 1) {
        this.sendPrePpf.disable()
        this.sendPrePpf.get('comments').enable();
      }
    }
    if (![9, 10, 11].includes(this.receiveFromToyota.getStatus)) {
      if (this.data.cancelFlag && this.data.cancelFlag === 2) {
        this.cancelOKFlag = true;
        this.cancelNGFlag = true;
      } else {
        this.okFlag = true;
      }
    } else if ([9, 10, 11].includes(this.receiveFromToyota.getStatus)) {
      this.sendPrePpf.disable();
      this.sendPrePpf.get('comments').enable();
      this.sendPrePpf.patchValue({
        comments: this.receiveFromToyota?.rejectReason.length > 0 ? this.receiveFromToyota?.rejectReason[0].REJECTREASON : ''
      })
      this.sendPrePpf.updateValueAndValidity();
      this.approvalFlag = true;
      this.rejectFlag = true;
    }
  }

  approveReject(type: number) {
    const body: any = {
      "hidPlantCode": this.data?.plantCode,
      "hidPartNumber": this.data?.partNumber,
      "hidSupplierCode": this.data?.supplierCode,
      "hidPkgStartDate": this.data?.pkgStartDate,
      "ppfCode": this.data?.ppfNumber,
      "stepNo": this.data?.stepNumber,
      "flag": type,
    }
    if (type === 2) {
      body.rejectPerson = this.userName;
      body.rejectReason = this.sendPrePpf.get('comments')?.value
    }
    this.trackingService.rejectOrApproval(body).subscribe({
      next: (_res) => {
        this.setUpdatedData(_res);
        this.router.navigate(["/tracking"]);
      },
      error: (_error) => {

      }
    })
  }

  createBody(hold: number) {
    const req = {
      hidPlantCode: this.data.plantCode,
      hidPartNumber: this.data.partNumber,
      hidSupplierCode: this.data.supplierCode,
      hidPkgStartDate: this.data.pkgStartDate,
      hidModelCode: this.data.modelCode,
      q1_1_yn: this.sendPrePpf.controls.q1_1_yn.value,
      q1_1_note: this.sendPrePpf.controls.q1_1_note.value,
      q1_2_yn: this.sendPrePpf.controls.q1_2_yn.value,
      q1_2_note: this.sendPrePpf.controls.q1_2_note.value,
      q2_2_select: this.sendPrePpf.controls.q2_2_select.value,
      q2_3_select: this.sendPrePpf.controls.q2_3_select.value,
      q2_4_select: this.sendPrePpf.controls.q2_4_select.value,
      q2_4_note: this.sendPrePpf.controls.q2_4_note.value,
      q2_5_yn: this.sendPrePpf.controls.q2_5_yn.value,
      q2_6_yn: this.sendPrePpf.controls.q2_6_yn.value,
      q2_6_part: this.sendPrePpf.controls.q2_6_part.value,
      q2_6_model: this.sendPrePpf.controls.q2_6_model.value,
      q2_7_yn: this.sendPrePpf.controls.q2_7_yn.value,
      q1_3_date: this.sendPrePpf.controls.q1_3_date.value?.split('-').join('/'),
      q1_4_date: this.sendPrePpf.controls.q1_4_date.value?.split('-').join('/'),
      q2_1_date: this.sendPrePpf.controls.q2_1_date.value?.split('-').join('/'),
      note: this.sendPrePpf.controls.comments.value,
      ppfCode: this.data?.ppfNumber,
      from: localStorage.getItem('toyotaGUID')
        ? localStorage.getItem('toyotaGUID')
        : '',
      hold: hold,
      hidUserSupCode: this.data.fabcode,
    };

    if (this.data.supOrFab === 1) {
      if (this.data.working === 8) {
        req.hidUserSupCode = this.data.dun1code;
      }
    }
    else {
      req.hidUserSupCode = this.data.supplierCode;
    }


    return req;
  }

  setUpdatedData(res: any) {
    if (res.data) {
      if(res.data === undefined || res.data.length <= 0){
        return;
      }
      const mergedData = Object.values(this.globalState.trackingRowData).reduce((acc:any,val:any) => acc.concat(val),[]);
      if (mergedData) {
        mergedData.forEach(
          (row: {
            submitFlag: boolean;
            doFlag: boolean;
            gridDate: string;
            STEP03STATUS: number;
            ISUPDATEPPF: number | null;
            STEP03DATE: string;
            PARTNUMBER: any;
            PPFCODE: any;
            WORKING: any;

          }) => {
            if (row.PARTNUMBER === res.data[0].PARTNUMBER && row.PPFCODE === res.data[0].PPFCODE) {

              const query: any = {
                ppfCode: row.PPFCODE,
                partNumber: row.PARTNUMBER,
                rec: res
              };

              sessionStorage.setItem('doPpfSaved', JSON.stringify(query));
            }
          }
        );
      }
    }
  }

  submit() {
    const req = this.createBody(1);

    const { q2_5_yn, q2_6_yn, q2_6_model, q2_6_part } = req;
    if ((q2_5_yn === 'N' && q2_6_yn === 'N') && !(q2_6_model && q2_6_part)) {
      return window.alert(
        'Require item. [6. Was the above part copied into the PPF?; Part Number]\nRequire item.[6. Was the above part copied into the PPF?;Model Code]'
      );
    }
    if (this.sendPrePpf.valid) {
      this.trackingService.updatePrePpf(req).subscribe({
        next: (res) => {
          this.setUpdatedData(res);
          this.location.back();
        },
      });
    }
  }

  holdInformation() {
    const req = this.createBody(0);

    this.trackingService.updatePrePpf(req).subscribe({
      next: (_res) => {
        this.location.back();
      },
    });
  }

  ppf() {
    let query:any = {};
    if(this.receiveFromToyota.getStatus === 9){
      query = {
        plantCode: this.data?.plantCode,
        partNumber: this.data?.partNumber,
        supplierCode: this.data?.supplierCode,
        pkgStartDate: this.data?.pkgStartDate,
        ppfNumber: this.data?.ppfNumber,
        ISUPDATEPPF: this.data?.ISUPDATEPPF,            
      };
      const plantCode = this.constants.getTypeFromPlantCode(this.data.PLANTCODE)
        if (plantCode === 'SERVICE') {
            this.router.navigate([`serviceparts-pdf/${this.data?.ppfNumber}/${0}/${this.data.partNumber}/${2}/${1}`], { relativeTo: this.route })
        } else if(plantCode === 'ASSY'){
            this.router.navigate([`accessoryParts-pdf/${this.data?.ppfNumber}/${0}/${this.data.partNumber}/${2}/${1}`], { relativeTo: this.route })
        }
        else {
            sessionStorage.setItem('PPFqueryParams', JSON.stringify(query))
            this.router.navigate(['ppf', this.data?.supplierCode, 'readOnly'], { relativeTo: this.route })
        }
    }else{
      query = {
        plantCode: this.data?.plantCode,
        partNumber: this.data?.partNumber,
        supplierCode: this.data?.supplierCode,
        pkgStartDate: this.data?.pkgStartDate,
        ppfNumber: this.data?.ppfNumber,
        ISUPDATEPPF: this.data?.ISUPDATEPPF,
        view: this.view,
        edit: this.edit,
      };
      const plantCode = this.constants.getTypeFromPlantCode(this.data.plantCode)
      if (plantCode === 'SERVICE') {
        sessionStorage.setItem('ppfPlantCode', this.data?.plantCode);
        this.router.navigate([`/tracking/serviceparts-pdf/${this.data?.ppfNumber}/${this.view}/${this.data.partNumber}/${1}/${1}`])
      } else if (plantCode === 'ASSY') {
        sessionStorage.setItem('ppfPlantCode', this.data?.plantCode);
        this.router.navigate([`/tracking/accessoryParts-pdf/${this.data?.ppfNumber}/${this.view}/${this.data.partNumber}/${1}/${1}`])
      } else {
        sessionStorage.setItem('ppfPassData', JSON.stringify(query));
        this.router.navigate(['/tracking/ppf/', this.data?.supplierCode])
      }
    }
  }

  locationBack() {
    this.location.back();
  }
}