export function convertAndCheck(length: any, width: any, height: any) {
    const cubicMeters = convertCubicMillimeterToCubicMeter(length, width, height);
    return cubicMeters === 0 ? "" : cubicMeters?.toFixed(5); // If volume is 0, return empty string, otherwise return volume with 6 decimal places
}
export function convertCubicMillimeterToCubicMeter(ln: any, wd: any, ht: any) {
    const cubicMillimeters = ln * wd * ht; // Calculate volume in cubic millimeters
    return cubicMillimeters / 1000000000; // Convert cubic millimeters to cubic meters and Return volume in cubic meters
}
export function toNumber(s:any) {
    if(isNaN(s)) {
        return '';
    } else {
        return s ;
    }
}
export function calculateValue(qty: any, unitCost: any, laborRate: any, laborTime: any) {
    if (qty === "" || isNaN(qty)) {
        return ""; // If Q54 is empty or not a number, return empty string
    } else {
        const result = ((qty * unitCost) + ((laborRate / 3600) * (laborTime * qty)));
        return isNaN(result) ? "" : result?.toFixed(4); // If result is NaN, return empty string, otherwise return the result
    }
}
export function validate(s:any){
    if(s!==null && s!==undefined && !Number.isNaN(s) && s!=="null" &&  s!=="undefined"){
        return s;
    }else{
        return '';
    }
}
export function getCI(a1: any, b1: any, c1: any, unit: any, inter: any, master: any, pallet: any) {
    const a = Number(a1);
    if (validate(a) !== '') {
        let s1 = 0;
        let s2 = 0;
        let res = 0;
        const b = Number(b1);
        const c = Number(c1);
        if (validate(b) && b !== 0) {
            if (validate(unit) !== '') {
                s1 = parseFloat(((a * Number(unit)) / b).toFixed(4));
            }
        }
        if (validate(inter) !== '') {
            s2 = parseFloat((c * Number(inter)).toFixed(4));
        }
        if (validate(master) !== '' && validate(pallet) !== '') {
            res = (s1 + s2 + Number(master) + Number(pallet));
        }
        return res.toFixed(4);
    } else {
        return '0';
    }
}

export function sumProduct(array1: any, array2: any) {
    let sum = 0;
    for (let i = 0; i < array1.length; i++) {
        sum += array1[i] * array2[i];
    }
    return sum;
}
//new template service parts constants
export const HEADERS1 = [
    {label: 'PACKAGING TYPE',width:160},
    {label: 'PACKAGING NAME',width:130},
    {label: 'MATERIAL DESCRIPTION',width:80},
    {label: 'PKG WEIGHT',width:80},
    {label: 'LENGTH(mm)',width:80},
    {label: 'WIDTH(mm)',width:80},
    {label: 'HEIGHT(mm)',width:80},
    {label: 'MATERIAL UNIT COST(USD$)',width:100},
    {label: 'LABOR TIME(sec)',width:90},
    {label: 'QUANTITY/PKG',width:90},
    {label: 'VENDOR COMMENT',width:90},
];
export const HEADERS4 = [
    { label: 'COMPONENT', colspan: 1 },
    { label: 'LENGTH (mm)', colspan: 1 },
    { label: 'WIDTH (mm)', colspan: 1 },
    { label: 'HEIGHT (mm)', colspan: 1 },
    { label: 'COMPONENT', colspan: 1 },
    { label: 'WEIGHT (kg)', colspan: 1 },
    { label: 'QUANTITIES', colspan: 2 },
];
const WHITLAM_LABEL = "Whitlam Label"
export const UNITTABLE: any[] = [
    { col0: 'UNIT PKG', col1: '', col2: '', col3: '', col4: '', col5: '', col6: '', col7: '', col8: '', col9: '', col10: '', },
    { col0: 'DUNNAGE 1', col1: '', col2: '', col3: '', col4: '', col5: '', col6: '', col7: '', col8: '', col9: '', col10: '' },
    { col0: 'DUNNAGE 2', col1: '', col2: '', col3: '', col4: '', col5: '', col6: '', col7: '', col8: '', col9: '', col10: '' },
    { col0: 'MISC.', col1: '', col2: '', col3: '', col4: '', col5: '', col6: '', col7: '', col8: '', col9: '', col10: '' },
    { col0: 'UNIT LABEL', col1: 'Whitlam Label', col2: '', col3: '', col4: '', col5: '', col6: '', col7: '', col8: '', col9: '', col10: '' },
    { col0: 'HEAVY PART LABEL(PART+PKG>22.7KG)', col1: WHITLAM_LABEL, col2: '', col3: '', col4: '', col5: '', col6: '', col7: '', col8: '', col9: '', col10: '' },
    { col0: 'HAZ-MAT LABEL(where applicable)',  col1: '' , col2: '', col3: '', col4: '', col5: '', col6: '', col7: '', col8: '', col9: '', col10: ''},
    { col0: 'Installation instruction (where applicable)', col1:'', col2: '', col3: '', col4: '', col5: '', col6: '', col7: '', col8: '', col9: '', col10: '' },
    { col0: 'FLOOR MAT SAFETY CHECK Insert',  col1: '' , col2: '', col3: '', col4: '', col5: '', col6: '', col7: '', col8: '', col9: '', col10: ''},
    
];
export function setGridUnitView(unitTable: any[], data: any, pValue:string): void {
    // dynamic rows set non-Editable from row0 to row3
    for (let i = 0; i < 4; i++) {
        unitTable[i].col1 = validate(data[i]?.packagingtype);
        unitTable[i].col2 = validate(data[i]?.materialdescription);
        unitTable[i].col3 = validate(data[i]?.weight);
        unitTable[i].col4 = validate(data[i]?.length);
        unitTable[i].col5 = validate(data[i]?.width);
        unitTable[i].col6 = validate(data[i]?.height);
        unitTable[i].col7 = validate(data[i]?.unitcost);
        unitTable[i].col8 = validate(data[i]?.labortime);
        unitTable[i].col9 = validate(data[i]?.qty);
        unitTable[i].col10 = validate(data[i]?.comment);
    }
    //from row4 to row 6
    for (let i = 4; i < 7; i++) {
        unitTable[i].col7 = validate(data[i]?.unitcost);
        unitTable[i].col8 = validate(data[i]?.labortime);
        unitTable[i].col9 = validate(data[i]?.qty);
        unitTable[i].col10 = validate(data[i]?.comment);
    } 
    if(pValue === 'Accessory Parts PDF') {
        for (let i = 7; i < 9; i++) {
            unitTable[i].col7 = validate(data[i]?.unitcost);
            unitTable[i].col8 = validate(data[i]?.labortime);
            unitTable[i].col9 = validate(data[i]?.qty);
            unitTable[i].col10 = validate(data[i]?.comment);
        }
    }
}
export const INTERMEDIATETABLE: any[] = [
    { col0: 'INTERMEDIATE PKG', col1: '', col2: '', col3: '', col4: '', col5: '', col6: '', col7: '', col8: '', col9: '', col10: '' },
    { col0: 'DUNNAGE 1', col1: '', col2: '', col3: '', col4: '', col5: '', col6: '', col7: '', col8: '', col9: '', col10: '' },
    { col0: 'DUNNAGE 2', col1: '', col2: '', col3: '', col4: '', col5: '', col6: '', col7: '', col8: '', col9: '', col10: '' },
    { col0: 'MISC.', col1: '', col2: '', col3: '', col4: '', col5: '', col6: '', col7: '', col8: '', col9: '', col10: '' },
    { col0: 'INTERMEDIATE LABEL', col1: WHITLAM_LABEL, col2: '', col3: '', col4: '', col5: '' },
];
export function setNonEditableInter(intermediateTable: any[], data: any): void {
    // dynamic rows set-nonEditable from row0 to row4
    for (let i = 0; i < 4; i++) {
        intermediateTable[i].col1 = validate(data[i]?.packagingtype);
        intermediateTable[i].col2 = validate(data[i]?.materialdescription);
        intermediateTable[i].col3 = validate(data[i]?.weight);
        intermediateTable[i].col4 = validate(data[i]?.length);
        intermediateTable[i].col5 = validate(data[i]?.width);
        intermediateTable[i].col6 = validate(data[i]?.height);
        intermediateTable[i].col7 = validate(data[i]?.unitcost);
        intermediateTable[i].col8 = validate(data[i]?.labortime);
        intermediateTable[i].col9 = validate(data[i]?.qty);
        intermediateTable[i].col10 = validate(data[i]?.comment);
    }
    //row5
    intermediateTable[4].col7 = validate(data[4]?.unitcost);
    intermediateTable[4].col8 = validate(data[4]?.labortime);
    intermediateTable[4].col9 = validate(data[4]?.qty);
    intermediateTable[4].col10 = validate(data[4]?.comment);
}
export function setNonEditableGridPalletCase(masterTable: any[], data: any): void {
    //dynamic row non-editable fetch from row0 to row4
    for (let i = 0; i < 7; i++) {
        masterTable[i].col1 = validate(data[i]?.packagingtype);
        masterTable[i].col2 = validate(data[i]?.materialdescription);
        masterTable[i].col3 = validate(data[i]?.weight);
        masterTable[i].col4 = validate(data[i]?.length);
        masterTable[i].col5 = validate(data[i]?.width);
        masterTable[i].col6 = validate(data[i]?.height);
        masterTable[i].col7 = validate(data[i]?.unitcost);
        masterTable[i].col8 = validate(data[i]?.labortime);
        masterTable[i].col9 = validate(data[i]?.qty);
        masterTable[i].col10 = validate(data[i]?.comment);
    }
    //row7  
    masterTable[7].col7 = validate(data[7]?.unitcost);
    masterTable[7].col8 = validate(data[7]?.labortime);
    masterTable[7].col9 = validate(data[7]?.qty);
    masterTable[7].col10 = validate(data[7]?.comment);
    //row7
    masterTable[8].col7 = validate(data[8]?.unitcost);
    masterTable[8].col8 = validate(data[8]?.labortime);
    masterTable[8].col9 = validate(data[8]?.qty);
    masterTable[8].col10 = validate(data[8]?.comment);
}